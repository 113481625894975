import { Component, Input } from '@angular/core';
import { AvatarService } from '@services/avatar.service';
import { BaseComponent } from '../../base.component';
import { Party } from '@models/party';
import { Logger } from '@core/logger';
import { User } from '@models/user';
import { Avatar } from '@interfaces/user';

@Component({
  selector: 'idpo-party-contact-card',
  templateUrl: './party-contact-card.component.html',
  styleUrls: ['./party-contact-card.component.scss']
})
export class PartyContactCardComponent extends BaseComponent {
  @Input()
  public user: User;
  @Input()
  public party: Party;

  constructor(protected avatarService: AvatarService, protected log: Logger) {
    super();
  }

  public get hostAvatar(): Avatar {
    return this.avatarService.getAvatar(this.party.host.avatarImageName);
  }

  public get dealerAvatar(): Avatar {
    return this.avatarService.getAvatar(this.party.dealer.avatarImageName);
  }
}
