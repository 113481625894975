import { Injectable } from '@angular/core';
import { Logger } from '../logger';
import { ApiService } from './api.service';
import { ProductService } from '@services/product.service';

@Injectable({
  providedIn: 'root'
})
export class ProductPersonalizedOffersService extends ProductService {
  protected get baseUrlSegment(): string {
    return '/products/personalized-offers';
  }

  constructor(api: ApiService, log: Logger) {
    super(api, log);
  }
}
