import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { NavigationService, UserService } from '@services';
import { Logger } from '@core/logger';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserIsHostGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(protected user: UserService, protected navi: NavigationService, protected log: Logger) {}

  protected isUserHost(): Observable<boolean> {
    return this.user.get().pipe(map(user => user.isHost));
  }

  protected isUserHostElseRedirect(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUserHost().pipe(
      take(1),
      switchMap(isHost => {
        // Check if User has host flag
        if (!isHost) {
          this.log.debug('User is not a host');
          return this.navi.dashboardUrlTree;
        }
        return of(true);
      })
    );
  }

  /**
   * NOTE: An observable on the canLoad guard needs to complete!
   * @see https://github.com/angular/angular/issues/18991
   * @see https://github.com/angular/angular/issues/27656
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.isUserHost().pipe(take(1));
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUserHostElseRedirect();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUserHostElseRedirect();
  }
}
