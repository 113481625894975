import { Component, OnInit } from '@angular/core';
import { NavigationService } from '@services/navigation.service';
import { Logger } from '@core/logger';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { UserService } from '@services/user.service';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'idpo-account-confirm',
  templateUrl: './account-confirm.component.html',
  styleUrls: ['./account-confirm.component.scss']
})
export class AccountConfirmComponent implements OnInit {
  constructor(
    protected route: ActivatedRoute,
    protected user: UserService,
    protected notify: NotificationService,
    protected navi: NavigationService,
    protected log: Logger
  ) {}

  public isLoading = true;

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap(paramMap => this.user.verify({ referenceToken: paramMap.get('referenceToken') })),
        catchError(err => {
          this.log.debug('Account confirmation failed', err);
          this.notify.error();
          return EMPTY;
        }),
        finalize(() => {
          this.isLoading = false;
        }),
        switchMap(() => this.navi.dashboard())
      )
      .subscribe();
  }
}
