import { Component } from '@angular/core';

@Component({
  selector: 'idpo-invitation-outdated',
  templateUrl: './invitation-outdated.component.html',
  styleUrls: ['./invitation-outdated.component.scss']
})
export class InvitationOutdatedComponent {
  constructor() {}
}
