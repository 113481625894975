import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { ConfigService } from '@services';

declare let $: any;

@Directive({
  selector: '[idpoCalentim]'
})
export class CalentimDirective implements AfterViewInit {
  @Output() public dateChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected elementRef: ElementRef, protected config: ConfigService) {}

  ngAfterViewInit() {
    this.init();
  }

  protected init(): void {
    // NOTE: Initialize Calentim input (copied from footer.js)
    $(this.elementRef.nativeElement)
      .calentim({
        locale: this.config.locale,
        startEmpty: true,
        calendarCount: 1,
        showHeader: false,
        showFooter: false,
        singleDate: true,
        showTimePickers: false,
        startOnMonday: true,
        showWeekNumbers: true,
        enableMonthSwitcher: true,
        monthSwitcherFormat: 'MMM',
        enableYearSwitcher: true,
        format: this.config.dateFormat,
        onafterselect: (calentim, startDate, endDate) => {
          this.dateChanged.emit(startDate);
        },
        onbeforehide: () => {
          // NOTICE: return true, to close calentim overlay
          return true;
        }
      })
      .bind(this);
  }
}
