import { Component, Inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import {
  concatMap,
  debounce,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  skipWhile,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import { combineLatest, interval, Observable, of, pipe, timer } from 'rxjs';
import { Logger } from '@core/logger';
import { FooterHiding, NavBarHiding } from '@enums';
import { BehaviourService } from '@interfaces';
import { BehaviourServiceInterfaceToken } from '@core';
import { BaseComponent } from '@components';
import {
  ConfigService,
  NavigationService,
  PartyIDService,
  PartyService,
  PartywallService,
  ScriptService,
  UserService
} from '@services';
import { Title } from '@angular/platform-browser';
import {environment} from "@environments/environment";

@Component({
  selector: 'idpo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
  title = 'idpo';
  public routerLoading$: Observable<boolean>;
  public navBarVisibility$: Observable<NavBarHiding>;
  public footerVisibility$: Observable<FooterHiding>;
  public floatingButtonVisibility$: Observable<boolean>;

  protected behaviourServices: BehaviourService<any>[];

  constructor(
    protected log: Logger,
    protected translate: TranslateService,
    protected config: ConfigService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected titleService: Title,
    protected partyID: PartyIDService,
    protected userService: UserService,
    protected partyService: PartyService,
    protected partywallService: PartywallService,
    protected navi: NavigationService,
    protected scriptService: ScriptService,
    protected ggst: Angulartics2GoogleGlobalSiteTag,
    protected angulartics: Angulartics2,
    @Inject(DOCUMENT) protected document: Document,
    @Inject(BehaviourServiceInterfaceToken) behaviourServices: BehaviourService<any>[]
  ) {
    super();

    this.behaviourServices = behaviourServices;
    this.setLocalization();
    this.setTitle();
    this.addGoogleAnalytics();
    this.addCookiebot();
    this.handleBaseDataChanges();
    this.setRouterLoading();
    this.setNavBarVisibility();
    this.setFooterVisibility();
    this.connectToPartywall();
    this.setChatButtonVisibility();
    if (environment.showOrderPeriodStartedPopup) {
      this.checkOrderPeriodStarts();
    }
  }

  protected handleBaseDataChanges() {
    this.partyID.changed().subscribe(() => {
      this.behaviourServices.forEach(service => {
        if (!(service instanceof UserService)) {
          service.unset();
        }
      });
    });

    this.userService.loggedIn().subscribe(() => {
      this.behaviourServices.forEach(service => {
        if (!(service instanceof UserService)) {
          service.unset();
        }
      });
    });

    this.userService
      .loggedOut()
      .pipe(delay(1000))
      .subscribe(() => {
        this.behaviourServices.forEach(service => {
          if (!(service instanceof PartyService)) {
            service.unset();
          }
        });
      });
  }

  protected setLocalization() {
    // Set available locales (no impact for the moment)
    this.translate.addLangs(['en_GB', 'de_DE', 'fr_FR']);
    // Set default locale
    this.translate.setDefaultLang('en_GB');
    // Set actual locale from config
    this.translate.use(this.config.locale);
    // Set document language
    this.document.documentElement.lang = this.config.language || 'en';
  }

  protected addCookiebot() {
    const cbid = this.config.cookiebotId;
    if (cbid) {
      const src = 'https://consent.cookiebot.com/uc.js';
      const options = {
        id: 'Cookiebot',
        'data-cbid': cbid,
        'data-culture': this.config.language || 'en'
        // Auto mode is not working and is blocking angular app without any visible text/plain attribute on any script
        // We have no possibility to configure cookiebot, so we need to run the manual mode
        // 'data-blockingmode': 'auto'
      };
      this.scriptService
        .load(src, false, options)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.log.debug('cookiebot activated ... ', cbid);
          },
          error: error => this.log.error(`Could not load '${src}'!`, error)
        });
    }
  }

  protected addGoogleAnalytics() {
    const trackingId = this.config.trackingId;
    if (trackingId) {
      this.angulartics.settings.gst.trackingIds = [trackingId];
      const src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingId;
      const options = {
        type: 'text/plain',
        'data-cookieconsent': 'statistics'
      };
      this.scriptService
        .load(src, false, options)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.log.debug('start tracking ' + trackingId);
            this.ggst.startTracking();
          },
          error: error => this.log.error(`Could not load '${src}'!`, error)
        });
    }
  }

  protected connectToPartywall() {
    this.addSubscription(
      this.userService
        .get(true)
        .pipe(
          switchMap(user => {
            if (null != user) {
              return this.partywallService.connect();
            }
            return of(null);
          })
        )
        .subscribe()
    );
  }

  protected setRouterLoading() {
    this.routerLoading$ = this.router.events.pipe(
      filter(
        e =>
          e instanceof NavigationStart ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      map(e => e instanceof NavigationStart),
      debounce(isLoading => timer(isLoading ? 10 : 0))
    );
  }

  protected get routeData$() {
    return this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data)
    );
  }

  protected setChatButtonVisibility() {
    this.floatingButtonVisibility$ = combineLatest(this.userService.get(true), this.routeData$).pipe(
      map(([user, data]) => {
        if (null == user) {
          return false;
        }
        return !(data.hasOwnProperty('hideFloatingButton') ? data.hideFloatingButton : false);
      }),
      distinctUntilChanged()
    );
  }

  protected setNavBarVisibility() {
    this.navBarVisibility$ = this.routeData$.pipe(
      map(data => (data.hasOwnProperty('navBarHiding') ? data.navBarHiding : NavBarHiding.Nothing)),
      distinctUntilChanged()
    );
  }

  protected setFooterVisibility() {
    this.footerVisibility$ = this.routeData$.pipe(
      map(data => (data.hasOwnProperty('footerHiding') ? data.footerHiding : FooterHiding.Nothing)),
      distinctUntilChanged()
    );
  }

  protected setTitle() {
    this.translate.get('general.title').subscribe(title => this.titleService.setTitle(title));
  }

  /**
   * Checks the read state of the order period started notification
   */
  protected checkOrderPeriodStarts() {
    const takeUntilUserRead = pipe(
      takeUntil(
        this.userService.get(true).pipe(filter(user => user && user.asGuest && user.asGuest.orderPeriodStartedRead))
      )
    );
    this.addSubscription(
      interval(10000)
        .pipe(
          takeUntilUserRead,
          switchMap(() => combineLatest(this.userService.get(true), this.partyService.get())),
          skipWhile(([user, party]) => !(user && user.asGuest && party)),
          delay(5000),
          concatMap(([user, party]) => {
            // Delay if order period is in future
            if (party.orderStartDate.isAfter()) {
              const secondsUntilParty = party.orderStartDate.diff(undefined, 'seconds');
              const delaySeconds = secondsUntilParty < 10 ? secondsUntilParty + 1 : secondsUntilParty / 2;

              return of(null).pipe(delay(delaySeconds * 1000));
            }

            // Show popup and mark as read
            return this.navi.openOrderPeriodStartedPopup().pipe(take(1));
          }),
          takeUntilUserRead
        )
        .subscribe()
    );
  }
}
