import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingPreloadingStrategy } from './app-routing-preloading-strategy';
// Guards and Resolver
import {
  AuthGuard,
  CookiesGuard,
  DealerGuard,
  DipAlreadyAcceptedGuard,
  DoNotShowPopupOnReloadGuard,
  OrderPeriodStartedAlreadyReadGuard,
  PartyGuard,
  UserIsHostGuard,
  UserPreparedForPartyGuard
} from '@guards';

import {
  CartResolverService,
  GuestsResolverService,
  HostessGiftCartResolverService,
  HostessGiftOrderResolverService,
  OrderResolverService,
  PartyResolverService,
  ProductsResolverService,
  UserResolverService,
  WishlistResolverService
} from '@resolvers';
// Shared
import { FooterHiding, NavBarHiding } from '@enums';
// Popups
import {
  DefaultComponent,
  DipComponent,
  ForbiddenErrorComponent,
  GeneralErrorComponent,
  InvitationOutdatedComponent,
  MaintenanceErrorComponent,
  NotFoundErrorComponent,
  OrderPeriodStartedComponent,
  PartyNotFoundComponent,
  ServicePlatformErrorComponent
} from '@components';

const routes: Routes = [
  {
    path: 'dealer/party/:partyId',
    data: { navBarHiding: NavBarHiding.Complete, footerHiding: FooterHiding.Complete, hideFloatingButton: true },
    canActivateChild: [DealerGuard],
    // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
    // canLoad: [DealerGuard],
    loadChildren: './dealer/dealer.module#DealerModule'
  },
  {
    path: 'error',
    data: { navBarHiding: NavBarHiding.Complete, footerHiding: FooterHiding.Complete, hideFloatingButton: true },
    children: [
      {
        path: '',
        component: GeneralErrorComponent
      },
      {
        path: 'party-not-found',
        component: PartyNotFoundComponent
      },
      {
        path: 'outdated',
        component: InvitationOutdatedComponent
      },
      {
        path: 'forbidden',
        component: ForbiddenErrorComponent
      },
      {
        path: 'maintenance',
        component: MaintenanceErrorComponent
      },
      {
        path: 'service-platform',
        component: ServicePlatformErrorComponent
      }
    ]
  },
  {
    path: ':partyId',
    resolve: {
      party: PartyResolverService
    },
    canActivateChild: [PartyGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        // Convenience route
        path: 'login',
        pathMatch: 'full',
        redirectTo: 'account/login'
      },
      // Accessible without authentication
      {
        path: 'account',
        loadChildren: './account/account.module#AccountModule'
      },
      {
        path: 'cookies',
        canActivate: [CookiesGuard],
        loadChildren: './cookies/cookies.module#CookiesModule'
      },
      {
        // Need to be authenticated
        path: 'user',
        data: { navBarHiding: NavBarHiding.Content, hideFloatingButton: true },
        resolve: {
          user: UserResolverService
        },
        canActivateChild: [AuthGuard],
        // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
        // canLoad: [AuthGuard],
        loadChildren: './user/user.module#UserModule'
      },
      {
        path: 'dashboard',
        data: { preload: true },
        resolve: {
          user: UserResolverService,
          guests: GuestsResolverService
        },
        canActivateChild: [AuthGuard, UserPreparedForPartyGuard],
        // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
        // canLoad: [AuthGuard],
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'products',
        data: { preload: true },
        resolve: {
          user: UserResolverService,
          products: ProductsResolverService,
          order: OrderResolverService
        },
        canActivateChild: [AuthGuard, UserPreparedForPartyGuard],
        // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
        // canLoad: [AuthGuard],
        loadChildren: './products/products.module#ProductsModule'
      },
      {
        path: 'ipaper',
        resolve: {
          user: UserResolverService,
          products: ProductsResolverService,
          order: OrderResolverService
        },
        canActivateChild: [AuthGuard, UserPreparedForPartyGuard],
        // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
        // canLoad: [AuthGuard],
        loadChildren: './ipaper/ipaper.module#IpaperModule'
      },
      {
        path: 'wishlist',
        resolve: {
          user: UserResolverService,
          wishlist: WishlistResolverService
        },
        canActivateChild: [AuthGuard],
        // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
        // canLoad: [AuthGuard],
        loadChildren: './wishlist/wishlist.module#WishlistModule'
      },
      {
        path: 'order',
        data: { preload: true },
        resolve: {
          user: UserResolverService,
          order: OrderResolverService,
          cart: CartResolverService
        },
        canActivateChild: [AuthGuard, UserPreparedForPartyGuard],
        // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
        // canLoad: [AuthGuard],
        loadChildren: './order/order.module#OrderModule'
      },
      {
        path: 'partywall',
        resolve: {
          user: UserResolverService,
          guests: GuestsResolverService
        },
        canActivateChild: [AuthGuard, UserPreparedForPartyGuard],
        // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
        // canLoad: [AuthGuard],
        loadChildren: './partywall/partywall.module#PartywallModule'
      },
      {
        path: 'my-party',
        resolve: {
          user: UserResolverService,
          guests: GuestsResolverService
        },
        // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
        // canLoad: [AuthGuard, UserIsHostGuard],
        canActivateChild: [AuthGuard, UserIsHostGuard, UserPreparedForPartyGuard],
        loadChildren: './my-party/my-party.module#MyPartyModule'
      }
      // NOTE Launch without hostess gift - need to be hidden until further notice (DPO-392)
      // {
      //   path: 'hostess-gift',
      //   resolve: {
      //     user: UserResolverService,
      //     order: HostessGiftOrderResolverService,
      //     cart: HostessGiftCartResolverService
      //   },
      //   // CanLoad guarded lazy loaded modules are not working as expected on refreshing app at lazy loaded page
      //   // canLoad: [AuthGuard, UserIsHostGuard],
      //   canActivateChild: [AuthGuard, UserIsHostGuard, UserPreparedForPartyGuard],
      //   loadChildren: './hostess-gift/hostess-gift.module#HostessGiftModule'
      // }
    ]
  },
  // Popup Routes
  {
    path: 'dip',
    component: DipComponent,
    canActivate: [DoNotShowPopupOnReloadGuard, DipAlreadyAcceptedGuard],
    outlet: 'popup'
  },
  {
    path: 'order-period-started',
    component: OrderPeriodStartedComponent,
    canActivate: [DoNotShowPopupOnReloadGuard, OrderPeriodStartedAlreadyReadGuard],
    outlet: 'popup'
  },
  // Fallback Error Pages
  { path: '', component: DefaultComponent, data: { navBarHiding: NavBarHiding.Content } },
  { path: '**', component: NotFoundErrorComponent, data: { navBarHiding: NavBarHiding.Content } }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: AppRoutingPreloadingStrategy
    })
  ],
  exports: [RouterModule],
  providers: [AppRoutingPreloadingStrategy]
})
export class AppRoutingModule {}
