import { OnInit } from '@angular/core';
import { filter, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { Logger } from '@core/logger';
import { DipService, NotificationService } from '@services';
import { Product } from '@models';
import {
  CartService as CartServiceInterface,
  OrderService as OrderServiceInterface,
  WishlistService as WishlistServiceInterface
} from '@interfaces';
import { BaseComponent } from '@shared/components/base.component';

export abstract class ProductBaseComponent extends BaseComponent implements OnInit {
  public abstract product$: Observable<Product>;

  public isCartSubmitting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCartAddable$: Observable<boolean>;
  public isAlreadyInCart$: Observable<boolean>;
  public isCartRemovable$: Observable<boolean>;
  public showCartSuccess: boolean;

  protected wishlistDisabled = false;
  public isWishlistSubmitting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isWishlistAddable$: Observable<boolean>;
  public isAlreadyOnWishlist$: Observable<boolean>;
  public showWishlistSuccess: boolean;

  protected constructor(
    protected cartService: CartServiceInterface,
    protected orderService: OrderServiceInterface,
    protected wishlistService: WishlistServiceInterface,
    protected log: Logger,
    protected notify: NotificationService,
    protected dipService: DipService
  ) {
    super();
  }

  ngOnInit() {
    // Cart

    // Is Already in Cart?
    this.isAlreadyInCart$ = this.product$.pipe(switchMap(product => this.cartService.hasItem(product.id)));

    // Is Addable?
    this.isCartAddable$ = combineLatest(this.orderService.isCompleted(), this.isCartSubmitting$).pipe(
      map(([isCompleted, isSubmitting]) => !isCompleted && !isSubmitting)
    );

    // Is Removable?
    this.isCartRemovable$ = combineLatest(
      this.orderService.isCompleted(),
      this.isAlreadyInCart$,
      this.isCartSubmitting$
    ).pipe(map(([isCompleted, isAlreadyInCart$, isSubmitting]) => !isCompleted && isAlreadyInCart$ && !isSubmitting));

    // Wishlist
    if (!this.wishlistDisabled) {
      // Is Already on Wishlist?
      this.isAlreadyOnWishlist$ = this.product$.pipe(switchMap(product => this.wishlistService.hasItem(product.id)));

      // Is Addable?
      this.isWishlistAddable$ = combineLatest(this.isWishlistSubmitting$).pipe(
        map(([isSubmitting]) => !isSubmitting),
        shareReplay()
      );
    }
  }

  public addToCart(product: Product, event: Event): void {
    event.stopPropagation();

    if (product.startsInWeek !== null) {
      return;
    }

    this.isCartAddable$
      .pipe(
        take(1),
        filter(isAddable => isAddable),
        this.dipService.isCartAddable(),
        tap(() => {
          this.isCartSubmitting$.next(true);
          this.showCartSuccess = false;
        }),
        switchMap(() => this.cartService.addItem(product.id)),
        finalize(() => this.isCartSubmitting$.next(false)),
        this.notify.handleApiValidationError(),
        this.notify.handleGeneralError(),
        switchMap(() => {
          this.showCartSuccess = true;
          return timer(1000);
        }),
        finalize(() => (this.showCartSuccess = false))
      )
      .subscribe();
  }

  public removeFromCart(product: Product, event: Event): void {
    event.stopPropagation();

    this.isCartRemovable$
      .pipe(
        take(1),
        filter(isRemovable => isRemovable),
        tap(() => {
          this.isCartSubmitting$.next(true);
        }),
        switchMap(() => this.cartService.removeItem(product.id)),
        switchMap(() => this.orderService.fetch()),
        finalize(() => this.isCartSubmitting$.next(false)),
        this.notify.handleApiValidationError(),
        this.notify.handleGeneralError()
      )
      .subscribe();
  }

  public addToWishlist(product: Product, event: Event): void {
    event.stopPropagation();

    if (this.wishlistDisabled) {
      return;
    }

    this.isWishlistAddable$
      .pipe(
        take(1),
        filter(isAddable => isAddable),
        tap(() => {
          this.isWishlistSubmitting$.next(true);
          this.showWishlistSuccess = false;
        }),
        switchMap(() => this.wishlistService.addItem(product.id)),
        finalize(() => this.isWishlistSubmitting$.next(false)),
        this.notify.handleApiValidationError(),
        this.notify.handleGeneralError(),
        switchMap(() => {
          this.showWishlistSuccess = true;
          return timer(1000);
        }),
        finalize(() => (this.showWishlistSuccess = false))
      )
      .subscribe();
  }
}
