import { ProductCategory } from '@interfaces/product';
import { Product } from '@models/product';
import * as Fuse from 'fuse.js';

export class Products {
  protected _all: Product[];
  protected _categories: ProductCategory[];
  protected _fuse: Fuse<Product>;

  constructor(products: Product[]) {
    this.init(products);
  }

  protected static createCategories(products: Product[]): ProductCategory[] {
    const categories: ProductCategory[] = [];
    const idReference = new Map();
    for (const product of products) {
      if (product.categoryId && !idReference.has(product.categoryId)) {
        idReference.set(product.categoryId, true);
        categories.push({
          id: product.categoryId,
          name: product.categoryName,
          imageUrl: product.categoryImageUrl,
          sort: product.categorySort
        });
      }
    }
    return categories.sort((a, b) => {
      const aSort = a.sort != null ? <number>a.sort : Number.MAX_SAFE_INTEGER;
      const bSort = b.sort != null ? <number>b.sort : Number.MAX_SAFE_INTEGER;
      return aSort - bSort;
    });
  }

  /**
   * @see https://github.com/krisk/fuse/
   * @see https://fusejs.io/
   */
  protected static searchOptions(): Fuse.FuseOptions<any> {
    return {
      caseSensitive: false,
      shouldSort: true,
      tokenize: true,
      matchAllTokens: true,
      includeScore: false,
      includeMatches: false,
      threshold: 0,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['salesCode', 'description']
    };
  }

  public static maxSearchTermLength(): number {
    return this.searchOptions().maxPatternLength;
  }

  init(products: Product[]): this {
    this._all = products;
    this._categories = Products.createCategories(products);
    this._fuse = new Fuse<Product>(products, Products.searchOptions());

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get all(): Product[] {
    return this._all;
  }

  public get personalizedOffers(): Product[] {
    return this.all.filter(product => null !== product.minGuestTurnover);
  }

  public get categories(): ProductCategory[] {
    return this._categories;
  }

  /*
  |--------------------------------
  | Search products
  |--------------------------------
  */

  /**
   * Fuzzy search list,
   *
   * @see https://github.com/krisk/fuse/
   * @see https://fusejs.io/
   */
  protected get fuse(): Fuse<Product> {
    return this._fuse;
  }

  /**
   * Start a fuzzy search on products
   * with search options from static.searchOptions
   */
  public search(searchTerm: string): Product[] {
    return this.fuse.search(searchTerm.trim());
  }

  /**
   * Filter by category Id
   */
  public filterByCategoryId(categoryId: string): Product[] {
    return this.all.filter(product => product.categoryId === categoryId);
  }

  /**
   * Filter by category name
   */
  public filterByCategoryName(categoryname: string): Product[] {
    const categoryId = Product.createCategoryId(categoryname);
    return this.filterByCategoryId(categoryId);
  }

  /**
   * Find image by sales code
   */
  public findBySalesCode(salesCode: string): Product {
    salesCode = salesCode.toUpperCase();
    return this.all.find(product => product.salesCode.toUpperCase() === salesCode);
  }

  /**
   * Find image by sales code
   */
  public findById(id: string): Product {
    id = id.toLowerCase();
    return this.all.find(product => product.id.toLowerCase() === id);
  }
}
