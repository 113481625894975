import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLogin as LoginUserParameter } from '@interfaces/user';
import { loginFormDefinition } from './login.form';
import { FormService } from '@services/form.service';
import { NavigationService } from '@services/navigation.service';
import { NotificationService } from '@services/notification.service';
import { Logger } from '@core/logger';
import { catchError, distinctUntilChanged, finalize, take, switchMap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, from } from 'rxjs';
import { UserService } from '@services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { PartyBaseComponent } from '@components/party-base.component';
import { PartyService } from '@services/party.service';

@Component({
  selector: 'idpo-login',
  host: {
    '[class.with-bg]': '!simpleForm'
  },
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends PartyBaseComponent implements OnInit {
  @Input() simpleForm: boolean  = false;
  @Input() simpleFormShowPasswordForgotLink: boolean  = false;
  @Input() titleClass: string  = '';
  @Input() onLogin: CallableFunction;
  public loginForm: FormGroup;
  public loginFormDefinition = loginFormDefinition;

  public isSubmitting$ = new BehaviorSubject<boolean>(false);
  protected redirectTo: string | null;

  constructor(
    partyService: PartyService,
    protected log: Logger,
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected formService: FormService,
    protected user: UserService,
    protected navi: NavigationService,
    protected notify: NotificationService
  ) {
    super(partyService);

    this.createForm();
  }

  protected createForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  public ngOnInit(): void {
    // Get redirectTo path if available
    this.redirectTo = this.route.snapshot.queryParamMap.get('redirectTo');
    // Disable form while submitting
    this.addSubscription(
      this.isSubmitting$
        .pipe(distinctUntilChanged())
        .subscribe(isSubmitting => this.formService.isSubmittingHandler(isSubmitting, this.loginForm))
    );
  }

  public submitLoginForm(): void {
    if (this.isSubmittable) {
      const parameter: LoginUserParameter = Object.assign({}, this.loginForm.value);

      this.isSubmitting$.next(true);
      this.user
        .login(parameter)
        .pipe(
          take(1),
          finalize(() => this.isSubmitting$.next(false)),
          this.formService.handleApiValidationError(this.loginForm),
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.notify.error({
                title: 'login.errorNotification.title',
                content: 'login.errorNotification.text',
                translate: true
              });
            } else {
              this.notify.error();
            }
            return EMPTY;
          }),
          switchMap(() => {
            if (this.onLogin !== undefined) {
              return this.onLogin();
            } else if (this.redirectTo) {
              return from(this.router.navigateByUrl(this.redirectTo));
            } else {
              return this.navi.dashboard();
            }
          })
        )
        .subscribe();
    }
  }

  public goToPasswordForgot(): void {
    this.navi
      .passwordForgot()
      .pipe(take(1))
      .subscribe();
  }

  public goToPasswordForgotSimple(): void {
    this.navi.openSidepanel("password");
  }

  public goToJoin(): void {
    this.navi
      .join()
      .pipe(take(1))
      .subscribe();
  }

  public goToAccountCreation(): void {
    this.navi
      .accountCreate()
      .pipe(take(1))
      .subscribe();
  }

  get isSubmittable(): boolean {
    return this.loginForm.valid && this.loginForm.dirty;
  }
}
