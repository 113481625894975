import moment, { Moment } from 'moment';
import { Party as PartyInterface, Host, Guest } from '@interfaces';
import { PartyState } from '@app/shared/enums/party-state.enum';
import { Dealer } from './dealer';
import { HostessGiftSelectionTypes } from '@enums/hostess-gift-selection-types.enum';
import { Distributor } from './distributor';

export class Party {
  protected _partyId: string;
  protected _state: string;
  protected _startDate: string;
  protected _endDate: string;
  protected _orderStartDate: string;
  protected _orderEndDate: string;
  protected _hostessGiftSelectionEnabled: boolean;
  protected _numberOfAppointments: number;
  protected _numberOfAppointmentsShort: number;
  protected _partyTurnover: number;
  protected _starAmount: number;
  protected _usedStarAmount: number;
  protected _usedVoucherAmount: number;
  protected _voucherAmount: number;
  protected _hostessGiftSelectionType: HostessGiftSelectionTypes;
  protected _distributor: Distributor;
  protected _host: Host;
  protected _dealer: Dealer;
  protected _guests: Guest[] | null;
  protected _hostname: string | null;

  constructor(partyData: PartyInterface) {
    this.init(partyData);
  }

  init(partyData: PartyInterface): this {
    this._partyId = partyData.partyId;
    this._state = partyData.state;
    this._startDate = partyData.startDate;
    this._endDate = partyData.endDate;
    this._orderStartDate = partyData.orderStartDate;
    this._orderEndDate = partyData.orderEndDate;
    this._hostessGiftSelectionEnabled = partyData.hostessGiftSelectionEnabled;
    this._numberOfAppointments = partyData.numberOfAppointments;
    this._numberOfAppointmentsShort = partyData.numberOfAppointmentsShort;
    this._partyTurnover = partyData.partyTurnover;
    this._starAmount = partyData.starAmount;
    this._usedStarAmount = partyData.usedStarAmount;
    this._usedVoucherAmount = partyData.usedVoucherAmount;
    this._voucherAmount = partyData.voucherAmount;
    this._hostessGiftSelectionType = partyData.hostessGiftSelectionType as HostessGiftSelectionTypes;
    this._distributor = new Distributor(partyData.distributor);
    this._host = partyData.host;
    this._dealer = new Dealer(partyData.dealer);
    this._guests = partyData.guests;
    this._hostname = partyData.hostname;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get partyId(): string {
    return this._partyId;
  }

  public get state(): PartyState {
    return PartyState[this._state];
  }

  public get startDate(): Moment {
    return moment(this._startDate);
  }

  public get endDate(): Moment {
    return moment(this._endDate);
  }

  public get orderStartDate(): Moment {
    return moment(this._orderStartDate);
  }

  public get orderEndDate(): Moment {
    return moment(this._orderEndDate);
  }

  public get hostessGiftSelectionEnabled(): boolean {
    return this._hostessGiftSelectionEnabled;
  }

  public get numberOfAppointments(): number {
    return this._numberOfAppointments;
  }

  public get numberOfAppointmentsShort(): number {
    return this._numberOfAppointmentsShort;
  }

  public get partyTurnover(): number {
    return this._partyTurnover;
  }

  public get starAmount(): number {
    return this._starAmount;
  }

  public get usedStarAmount(): number {
    return this._usedStarAmount;
  }

  public get usedVoucherAmount(): number {
    return this._usedVoucherAmount;
  }

  public get voucherAmount(): number {
    return this._voucherAmount;
  }

  public get hostessGiftSelectionType(): HostessGiftSelectionTypes {
    return this._hostessGiftSelectionType;
  }

  public get host(): Host {
    return this._host;
  }

  public get distributor(): Distributor {
    return this._distributor;
  }

  public get dealer(): Dealer {
    return this._dealer;
  }

  public get guests(): Guest[] {
    return this._guests || [];
  }

  public set guests(guests: Guest[]) {
    this._guests = guests;
  }

  public get hostname(): string {
    return this._hostname || '';
  }

  /*
  |--------------------------------
  | Computed properties
  |--------------------------------
  */

  public get started(): boolean {
    return this.startDate.isBefore(moment());
  }

  public isOrderPeriodValid(reference: Moment = null) {
    reference = reference || moment();

    return !this.hostessGiftSelectionEnabled && reference.isBetween(this.orderStartDate, this.orderEndDate);
  }
}
