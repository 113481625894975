import { Distributor as DistributorInterface } from '@interfaces/distributor';
import { Addressable } from '@interfaces/addressable';

export class Distributor implements Addressable {
  protected _firstName?: string;
  protected _lastName?: string;
  protected _description: string;
  protected _companyName: string;
  protected _street: string;
  protected _streetNo: string;
  protected _zip: string;
  protected _city: string;
  protected _countryCode: string;
  protected _phone: string;
  protected _fax: string;
  protected _email: string;
  protected _taxNo: string;
  protected _commercialRegisterNo: string;
  protected _companyCapital: number;

  constructor(distributorData: DistributorInterface) {
    this.init(distributorData);
  }

  init(distributorData: DistributorInterface): this {
    this._firstName = distributorData.firstName;
    this._lastName = distributorData.lastName;
    this._description = distributorData.description;
    this._companyName = distributorData.companyName;
    this._street = distributorData.street;
    this._streetNo = distributorData.streetNo;
    this._zip = distributorData.zip;
    this._city = distributorData.city;
    this._countryCode = distributorData.countryCode;
    this._phone = distributorData.phone;
    this._fax = distributorData.fax;
    this._email = distributorData.email;
    this._taxNo = distributorData.taxNo;
    this._commercialRegisterNo = distributorData.commercialRegisterNo;
    this._companyCapital = distributorData.companyCapital;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get firstName(): string {
    return this._firstName;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public get description(): string {
    return this._description;
  }

  public get companyName(): string {
    return this._companyName;
  }

  public get street(): string {
    return this._street;
  }

  public get streetNo(): string {
    return this._streetNo;
  }

  public get zip(): string {
    return this._zip;
  }

  public get city(): string {
    return this._city;
  }

  public get countryCode(): string {
    return this._countryCode;
  }

  public get phone(): string {
    return this._phone;
  }

  public get fax(): string {
    return this._fax;
  }

  public get email(): string {
    return this._email;
  }

  public get taxNo(): string {
    return this._taxNo;
  }

  public get commercialRegisterNo(): string {
    return this._commercialRegisterNo;
  }

  public get companyCapital(): number {
    return this._companyCapital;
  }
}
