import { Component, ViewChild, ElementRef, Input, Inject, HostListener, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';

const PopupState = {
  show: 'show',
  hide: 'hide'
};

@Component({
  selector: 'idpo-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [
    trigger('popupAnimation', [
      state(
        PopupState.show,
        style({
          opacity: 1,
          transform: 'translateY(0)'
        })
      ),
      state(
        PopupState.hide,
        style({
          opacity: 0,
          transform: 'translateY(-10%)'
        })
      ),
      transition('show => hide', animate('300ms ease-in')),
      transition('hide => show', animate('300ms ease-out'))
    ])
  ]
})
export class PopupComponent {
  @ViewChild('content') public content: ElementRef<HTMLElement>;

  @Input() public addClass = '';
  @Input() public closable = true;
  @Input() public showCloseBtn = false;
  @Input() public clickOutside = true;
  @Input() public noPadding = false;
  @Input()
  public set show(show: boolean) {
    this.popupState = show ? PopupState.show : PopupState.hide;

    // Set timeout to enable hide functionality (prevents double-click show-hide issues)
    this.hideEnabled = false;
    if (show) {
      setTimeout(() => {
        this.hideEnabled = true;
      }, 500);
    }
  }

  @Output() public showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get show(): boolean {
    return this.popupState === PopupState.show ? true : false;
  }

  public popupState = PopupState.hide;
  private hideEnabled = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public hide(): void {
    this.show = false;
    this.showChange.emit(this.show);
  }

  @HostListener('document:click', ['$event'])
  public clickHandler(event: any): void {
    if (this.closable && this.clickOutside && this.hideEnabled && this.clickedOutside(event.target)) {
      this.hide();
    }
  }

  private clickedOutside(element: HTMLElement): boolean {
    return this.elementExists(element) && !this.content.nativeElement.contains(element);
  }

  private elementExists(element: HTMLElement): boolean {
    return this.document.body.contains(element);
  }
}
