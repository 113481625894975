import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Logger } from '../logger';
import { Cart } from '@models/cart';
import { HostessGiftCartService } from '@services';

@Injectable({
  providedIn: 'root'
})
export class HostessGiftCartResolverService implements Resolve<Cart> {
  constructor(protected hostessGiftCartService: HostessGiftCartService, protected log: Logger) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Cart> {
    return this.hostessGiftCartService.get().pipe(take(1));
  }
}
