import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-ts';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Logger } from '@core/logger';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DealerAuthStorageService {
  /**
   * Key to store token data.
   */
  protected readonly accessTokenStorageKey: string = '6d89e2cde0f338fbdd4130ce5c';

  /**
   * Storage to use.
   */
  protected storage: Storage;

  /**
   * JWT helper service
   */
  protected jwtHelper: JwtHelperService;
  /**
   * AuthStorageService constructor.
   */
  constructor(protected logger: Logger) {
    this.storage = localStorage;
    this.jwtHelper = new JwtHelperService();
  }

  /**
   * The encryption key.
   */
  protected get encryptionKey(): string {
    return environment.dealer.oAuth2.encryptionKey;
  }

  /**
   * Encrypt the value to store.
   */
  protected encrypt(value: string): string {
    const encryptionKey = this.encryptionKey;
    if (encryptionKey) {
      return AES.encrypt(value, encryptionKey).toString();
    } else {
      this.logger.error('No encryption key set');
      return '';
    }
  }

  /**
   * Decrypt the stored value.
   */
  protected decrypt(value: string): string {
    const encryptionKey = this.encryptionKey;
    if (encryptionKey) {
      return AES.decrypt(value, encryptionKey).toString(enc.Utf8);
    } else {
      this.logger.error('No encryption key set');
      return '';
    }
  }

  /**
   * Store a value.
   */
  protected store(key: string, value: string) {
    this.storage.setItem(key, this.encrypt(value));
  }

  /**
   * Fetch stored value.
   */
  protected fetch(key: string, defaultValue: string) {
    const value: string = this.storage.getItem(key);
    return value !== null ? this.decrypt(value) : defaultValue;
  }

  /**
   * Store a token.
   */
  public storeAccessToken(token: string) {
    this.store(this.accessTokenStorageKey, token);
  }

  /**
   * Fetch the token from storage.
   */
  public fetchAccessToken(): string {
    return this.fetch(this.accessTokenStorageKey, null);
  }

  /**
   * Remove stored token.
   */
  public removeToken() {
    this.storage.removeItem(this.accessTokenStorageKey);
  }

  /**
   * Clear complete storage.
   */
  public clear() {
    this.removeToken();
  }

  /**
   * Get a access token form storage or null if none available.
   */
  public get accessToken(): string {
    return this.fetchAccessToken();
  }

  /**
   * Get a non expired access token form storage or null if none available.
   */
  public get validAccessToken(): string {
    const accessToken = this.accessToken;
    return accessToken && !this.jwtHelper.isTokenExpired(accessToken) ? accessToken : null;
  }

  /**
   * Get a non expired decoded access token form storage or null if none available.
   */
  public get validAccessTokenDecoded(): string | null {
    const accessToken = this.validAccessToken;
    return accessToken ? this.jwtHelper.decodeToken(accessToken) : null;
  }
}
