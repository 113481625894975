import { Pipe, PipeTransform } from '@angular/core';
import * as urlRegex from 'url-regex';

@Pipe({
  name: 'url2link'
})
export class Url2linkPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }

    return <string>value.replace(urlRegex(), '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
  }
}
