import { Injectable } from '@angular/core';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Logger } from '../logger';
import { ApiService } from '@services/api.service';
import { Promotion as PromotionInterface } from '@interfaces/promotion';
import { Promotion } from '@models/promotion';
import { BehaviourService } from '@interfaces/behaviour-service';

@Injectable({
  providedIn: 'root'
})
export class PromotionService implements BehaviourService<Promotion[]> {
  protected promotions$: ReplaySubject<Promotion[]>;

  /**
   * Pipe for creating model from interface and set next promotions
   */
  protected setPromotions = pipe(
    map<PromotionInterface[], Promotion[]>(promotions => promotions.map(promotion => new Promotion(promotion))),
    // Not set error to replay subject
    tap(promotions => this.promotions$.next(promotions))
  );

  constructor(protected api: ApiService, protected log: Logger) {}

  /**
   * Unset actual promotions to force new fetch
   */
  public unset() {
    if (this.promotions$) {
      this.promotions$.complete();
    }
    this.promotions$ = null;
  }

  /**
   * Fetch available promotions from API.
   */
  public fetch(): Observable<Promotion[]> {
    if (!this.promotions$) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.promotions$ = new ReplaySubject<Promotion[]>(1);
    }

    return this.api.get<PromotionInterface[]>('/promotion').pipe(this.setPromotions);
  }

  /**
   * Get available promotions
   */
  public get(): Observable<Promotion[]> {
    if (!this.promotions$) {
      this.fetch().subscribe();
    }
    return this.promotions$;
  }
}
