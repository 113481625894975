import { JoinFormElement } from './join.form.interface';

// tslint:disable:max-line-length
export const joinFormDefinition: JoinFormElement[] = [
  {
    id: 'firstName',
    placeholder: 'forms.firstName',
    type: 'text',
    showIcon: {
      eye: false
    },
    errors: {
      required: 'forms.errors.firstNameRequired',
    }
  },
  {
    id: 'lastName',
    placeholder: 'forms.lastName',
    type: 'text',
    showIcon: {
      eye: false
    },
    errors: {
      required: 'forms.errors.lastNameRequired',
    }
  }
];
