import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[idpoTogglePassword]'
})
export class TogglePasswordViewDirective implements AfterViewInit {
  @Input('idpoTogglePassword') options: any;
  private _show = false;

  constructor(private element: ElementRef) {}

  public ngAfterViewInit(): void {
    if (!this.options.apply) {
      return;
    }

    this.setup();
  }

  private toggle(div: HTMLElement) {
    this._show = !this._show;
    if (this._show) {
      this.element.nativeElement.setAttribute('type', 'text');
      div.classList.add('active');
    } else {
      this.element.nativeElement.setAttribute('type', 'password');
      div.classList.remove('active');
    }
  }

  private setup(): void {
    const parent = this.element.nativeElement.parentNode;
    const div = document.createElement('div');
    div.classList.add('inline-icon', 'icon--eye');
    div.addEventListener('click', event => {
      this.toggle(div);
    });
    parent.appendChild(div);
  }
}
