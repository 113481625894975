import { Injectable } from '@angular/core';
import { BehaviourService } from '@interfaces/behaviour-service';
import { map, tap } from 'rxjs/operators';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { Logger } from '@core/logger';
import { ApiService } from '@services/api.service';
import { Catalog as CatalogInterface } from '@interfaces/catalog';
import { Catalog } from '@models/catalog';

@Injectable({
  providedIn: 'root'
})
export class CatalogService implements BehaviourService<Catalog[]> {
  protected catalogs$: ReplaySubject<Catalog[]>;

  /**
   * Pipe for creating model from interface and set next catalogs
   */
  protected setCatalogs = pipe(
    map<CatalogInterface[], Catalog[]>(catalogs => catalogs.map(catalog => new Catalog(catalog))),
    // Not set error to replay subject
    tap(catalogs => this.catalogs$.next(catalogs))
  );

  constructor(protected api: ApiService, protected log: Logger) {}

  /**
   * Unset actual catalogs to force new fetch
   */
  public unset() {
    if (this.catalogs$) {
      this.catalogs$.complete();
    }
    this.catalogs$ = null;
  }

  /**
   * Fetch available catalogs from API.
   */
  public fetch(): Observable<Catalog[]> {
    if (!this.catalogs$) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.catalogs$ = new ReplaySubject<Catalog[]>(1);
    }

    return this.api.get<CatalogInterface[]>('/catalog').pipe(this.setCatalogs);
  }

  /**
   * Get available catalogs
   */
  public get(): Observable<Catalog[]> {
    if (!this.catalogs$) {
      this.fetch().subscribe();
    }
    return this.catalogs$;
  }
}
