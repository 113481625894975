import { Dealer as DealerInterface } from '@interfaces/dealer';
import { Salutation } from '@app/shared/enums/salutation.enum';
import { Addressable } from '@interfaces/addressable';

export class Dealer implements Addressable {
  protected _salutation: string;
  protected _firstName: string;
  protected _lastName: string;
  protected _zip: string;
  protected _city: string;
  protected _street: string;
  protected _streetNo: string;
  protected _phone: string;
  protected _email: string;
  protected _timezone: string;
  protected _countryCode: string;

  constructor(dealerData: DealerInterface) {
    this.init(dealerData);
  }

  init(dealerData: DealerInterface): this {
    this._salutation = dealerData.salutation;
    this._firstName = dealerData.firstName;
    this._lastName = dealerData.lastName;
    this._zip = dealerData.zip;
    this._city = dealerData.city;
    this._street = dealerData.street;
    this._streetNo = dealerData.streetNo;
    this._phone = dealerData.phone;
    this._email = dealerData.email;
    this._timezone = dealerData.timezone;
    this._countryCode = dealerData.countryCode;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get salutation(): string {
    return this._salutation;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public get zip(): string {
    return this._zip;
  }

  public get city(): string {
    return this._city;
  }

  public get street(): string {
    return this._street;
  }

  public get streetNo(): string {
    return this._streetNo;
  }

  public get phone(): string {
    return this._phone;
  }

  public get email(): string {
    return this._email;
  }

  public get timezone(): string {
    return this._timezone;
  }

  public get countryCode(): string {
    return this._countryCode;
  }

  /*
|--------------------------------
| Calculated properties
|--------------------------------
*/

  get avatarImageName(): string {
    const dealerSalutation = Salutation[this.salutation];

    if (dealerSalutation === Salutation.Mr) {
      return 'avatar_male_01.svg';
    } else if (dealerSalutation === Salutation.Ms) {
      return 'avatar_female_01.svg';
    } else {
      return 'avatar_blank_female.svg';
    }
  }

  /**
   * Alias for avatarImageName
   */
  get avatar(): string {
    return this.avatarImageName;
  }
}
