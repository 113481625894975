import { FeeTypes, PaymentOptions } from '@enums';

export interface Order {
  orderType: string;
  shipment: string;
  payment: string;
  committed: boolean;
  handlesPayment: boolean;
  needsPayment: boolean;
  payed: boolean;
  completed: boolean;
  confirmedDip: boolean;
  fees: { [TKey in FeeTypes]: number };
  feeAmount: number;
  orderAmount: number;
  totalAmount: number;
  currency: string;
}

export interface LydiaPaymentData {
  url: string;
}

export interface SumUpPaymentData {
  checkoutId: string;
}

export function isLydiaPaymentResponse(response: PaymentData): response is LydiaPaymentData {
  return (<LydiaPaymentData>response).url !== undefined;
}

export function isSumUpPaymentResponse(response: PaymentData): response is SumUpPaymentData {
  return (<SumUpPaymentData>response).checkoutId !== undefined;
}

export type PaymentData = LydiaPaymentData | SumUpPaymentData | null;

export interface PaymentParameter {
  method: PaymentOptions;
  data: PaymentData;
}

export enum PaymentResponseSate {
  Success,
  Cancel,
  Error
}

export interface PaymentResponse {
  method: PaymentOptions;
  state: PaymentResponseSate;
  message?: string;
  data?: any;
}
