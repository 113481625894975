import { Cart as CartInterface, CartItem as CartItemInterface } from '@interfaces/cart';
import { CartItem } from '@models/cart-item';

export class Cart {
  protected _items: CartItem[];

  constructor(cartData: CartInterface) {
    this.init(cartData);
  }

  init(cartData: CartInterface): this {
    this._items = cartData.map((cartItem: CartItemInterface) => new CartItem(cartItem));

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get items(): CartItem[] {
    return this._items;
  }

  public get availableItems(): CartItem[] {
    return this.items.filter(item => item.isAvailable);
  }

  /*
  |--------------------------------
  | Calculated properties
  |--------------------------------
  */

  /**
   * States if the cart contains an item with given product id
   */
  public hasItem(productId: string): boolean {
    return this.items.findIndex(item => item.productId === productId) > -1;
  }

  /**
   * Get the cart item with given product id.
   * If item is not in cart, it returns null.
   */
  public getItem(productId: string): CartItem {
    return this.items.find(item => item.productId === productId) || null;
  }

  /**
   * States if the cart is empty due to available items
   */
  public get empty(): boolean {
    return this.count < 1;
  }

  /**
   * Get the total cost of the cart
   */
  public get total(): number {
    return this.availableItems.reduce<number>(
      (total: number, cartItem: CartItem) => total + cartItem.amount * cartItem.product.retailPrice,
      0
    );
  }

  /**
   * Get the total cost of the cart
   */
  public get totalStars(): number {
    return this.availableItems.reduce<number>(
      (total: number, cartItem: CartItem) => total + cartItem.amount * cartItem.product.starAmount,
      0
    );
  }

  /**
   * Get the number of available items in the cart
   */
  public get count(): number {
    return this.availableItems.reduce<number>((count: number, cartItem: CartItem) => count + cartItem.amount, 0);
  }
}
