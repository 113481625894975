import { PartyUserBaseComponent } from '@components/party-user-base.component';
import { Observable } from 'rxjs';
import { PartyService } from '@services/party.service';
import { UserService } from '@services/user.service';
import { Wishlist } from '@models/wishlist';
import { WishlistService } from '@app/core/services/wishlist.service';

export abstract class PartyUserWishlistBaseComponent extends PartyUserBaseComponent {
  public wishlist$: Observable<Wishlist>;

  protected constructor(
    partyService: PartyService,
    userService: UserService,
    protected wishlistService: WishlistService
  ) {
    super(partyService, userService);

    this.wishlist$ = this.wishlistService.get();
  }
}
