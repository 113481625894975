import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { httpInterceptorProviders } from '@app/http/interceptors';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [httpInterceptorProviders]
})
/**
 * this module will add interceptors to http client
 */
export class HttpModule {}
