import { Component, ViewChild, Input } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PartywallService } from '@services';

@Component({
  selector: 'idpo-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent {
  @Input() public hideFloatingButton = false;
  @ViewChild('floatingBtn') public floatingBtn;
  protected fBClassList;

  public unreadMessages$: Observable<number>;

  constructor(protected navi: NavigationService, protected partywallService: PartywallService) {
    // At `cartService.count()` invoke a GET of cart  will started if not fetched for the first time
    // We do not want get a possible 401 even if cart item is not visible
    // So we wrap it in a switch map to invoke `cartService.count()` just on subscription
    this.unreadMessages$ = of(null).pipe(switchMap(() => partywallService.unread()));
  }

  public goToPartyWall(): void {
    this.navi.partyWall().subscribe();
  }
}
