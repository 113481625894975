import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightMention'
})
export class HighlightMentionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return value;
    // NOTE: regex sketch https://regex101.com/r/cJoSdt/1
    const regex = /[^ ]?\[\~((\w|\d|[^\W|\D])+)\][^ ]?/gm;

    return value.replace(regex, '<span class="highlight-mention">@$1</span>');
  }
}
