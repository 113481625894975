import { PasswordResetForm } from './password-reset.form.interface';

// tslint:disable:max-line-length
const formData: PasswordResetForm[] = [
  {
    id: 'password',
    placeholder: 'forms.password',
    type: 'password',
    showIcon: {
      eye: true
    },
    errors: {
      required: 'forms.errors.passwordRequired'
    }
  },
  {
    id: 'password_confirmation',
    placeholder: 'forms.confirmPassword',
    type: 'password',
    showIcon: {
      eye: true
    },
    errors: {
      required: 'forms.errors.confirmPasswordRequired',
      passwordMustMatch: 'forms.errors.passwordMustMatch'
    }
  }
];

export default formData;
