import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { take, map, switchMap, tap } from 'rxjs/operators';
import { Logger } from '@app/core/logger';
import { PartyIDService } from '@services/party-id.service';
import { WINDOW } from '@services/window.service';

@Injectable()
export class NavigationService {
  public showSidepanel: boolean;
  public sidepanelPage: string;

  constructor(
    // Publish angular router to avoid injecting two services if both are used
    public router: Router,
    @Inject(WINDOW) protected window: Window,
    protected log: Logger,
    protected partyID: PartyIDService,
    protected location: Location
  ) {}

  /**
   * Wrapper of routers navigate method
   *
   * @param commands Router Navigation commands
   * @param extras Router Navigation extras
   */
  protected navigate(commands: any[], extras?: NavigationExtras): Observable<boolean> {
    return from(this.router.navigate(commands, extras)).pipe(
      tap(
        success => {
          if (!success) {
            this.log.info('Navigation not successful', commands);
          }
        },
        err => {
          this.log.error('Navigation error', commands, err);
        }
      ),
      take(1)
    );
  }

  /**
   * Wrapper of routers navigate method that applies partyID as first command
   *
   * @param commands Router Navigation commands
   * @param extras Router Navigation extras
   */
  protected partyNavigate(commands: any[], extras?: NavigationExtras): Observable<boolean> {
    return this.partyCommands(commands).pipe(switchMap(partyCommands => this.navigate(partyCommands, extras)));
  }

  protected partyCommands(commands: any[]): Observable<any[]> {
    return this.partyID.get().pipe(
      map(partyID => {
        commands.unshift(partyID);
        return commands;
      })
    );
  }

  protected partyUrlTree(commands: any[], extras?: NavigationExtras): Observable<UrlTree> {
    return this.partyCommands(commands).pipe(map(partyCommands => this.router.createUrlTree(partyCommands, extras)));
  }

  /*
   |--------------------------------
   | Login
   |--------------------------------
   */

  protected get loginCommands() {
    return ['account', 'login'];
  }

  protected get joinCommands() {
    return ['account', 'join'];
  }

  public get loginUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.loginCommands);
  }

  public login(partyID: string = null, redirectTo: string = null): Observable<boolean> {
    const extras: NavigationExtras = redirectTo ? { queryParams: { redirectTo } } : {};
    if (partyID !== null) {
      return this.navigate([partyID].concat(this.loginCommands), extras);
    }

    return this.partyNavigate(this.loginCommands, extras);
  }

  public join(partyID: string = null, redirectTo: string = null): Observable<boolean> {
    const extras: NavigationExtras = redirectTo ? { queryParams: { redirectTo } } : {};
    if (partyID !== null) {
      return this.navigate([partyID].concat(this.joinCommands), extras);
    }

    return this.partyNavigate(this.joinCommands, extras);
  }

  /*
   |--------------------------------
   | Account Create
   |--------------------------------
   */

  public accountCreate(): Observable<boolean> {
    return this.partyNavigate(['account', 'create']);
  }

  /*
   |--------------------------------
   | Account Confirm
   |--------------------------------
   */

  public accountConfirm(referenceToken: string): Observable<boolean> {
    return this.partyNavigate(['account', 'confirm', referenceToken]);
  }

  /*
   |--------------------------------
   | Account Unconfirmed
   |--------------------------------
   */

  protected get accountUnconfirmedCommands() {
    return ['account', 'unconfirmed'];
  }

  public get accountUnconfirmedUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.accountUnconfirmedCommands);
  }

  public accountUnconfirmed(): Observable<boolean> {
    return this.partyNavigate(this.accountUnconfirmedCommands);
  }

  /*
   |--------------------------------
   | Account Deleted
   |--------------------------------
   */

  public accountDeleted(): Observable<boolean> {
    return this.partyNavigate(['account', 'deleted']);
  }

  /*
   |--------------------------------
   | Password Forgot
   |--------------------------------
   */

  public passwordForgot(): Observable<boolean> {
    return this.partyNavigate(['account', 'password', 'forgot']);
  }

  /*
   |--------------------------------
   | Password Reset
   |--------------------------------
   */

  public passwordReset(hash: string): Observable<boolean> {
    return this.partyNavigate(['account', 'password', 'reset', hash]);
  }

  /*
   |--------------------------------
   | Thank you
   |--------------------------------
   */

  public thankYouPasswordForgot(): Observable<boolean> {
    return this.partyNavigate(['account', 'thank-you', 'mailsent']);
  }

  public thankYouPasswordReset(): Observable<boolean> {
    return this.partyNavigate(['account', 'thank-you', 'password']);
  }

  /*
   |--------------------------------
   | User TaC Confirm
   |--------------------------------
   */

  protected get userConfirmTaCCommands() {
    return ['user', 'tac'];
  }

  public get userConfirmTaCUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.userConfirmTaCCommands);
  }

  public userConfirmTaC(): Observable<boolean> {
    return this.partyNavigate(this.userConfirmTaCCommands);
  }

  /*
   |--------------------------------
   | User TaC Declined
   |--------------------------------
   */

  public userDeclinedTaC(): Observable<boolean> {
    return this.partyNavigate(['user', 'tac', 'declined']);
  }

  /*
   |--------------------------------
   | User Join
   |--------------------------------
   */

  protected get userJoinPartyCommands() {
    return ['user', 'join'];
  }

  public get userJoinPartyUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.userJoinPartyCommands);
  }

  public userJoinParty(): Observable<boolean> {
    return this.partyNavigate(this.userJoinPartyCommands);
  }

  /*
   |--------------------------------
   | User Profile
   |--------------------------------
   */

  protected get profileCommands() {
    return ['user', 'profile'];
  }

  public userProfile(redirectTo: string = null): Observable<boolean> {
    const extras: NavigationExtras = redirectTo ? { queryParams: { redirectTo } } : {};

    return this.partyNavigate(this.profileCommands, extras);
  }

  /*
   |--------------------------------
   | User Profile Orders
   |--------------------------------
   */

  protected get profileOrdersCommands() {
    return ['user', 'profile', 'orders'];
  }

  public userProfileOrders(redirectTo: string = null): Observable<boolean> {
    const extras: NavigationExtras = redirectTo ? { queryParams: { redirectTo } } : {};

    return this.partyNavigate(this.profileOrdersCommands, extras);
  }

  /*
   |--------------------------------
   | Dashboard
   |--------------------------------
   */

  protected get dashboardCommands() {
    return ['dashboard'];
  }

  public get dashboardUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.dashboardCommands);
  }

  public dashboard(): Observable<boolean> {
    return this.partyNavigate(this.dashboardCommands);
  }
  public invitationOutdated(): Observable<boolean> {
    return this.partyNavigate(['invitation-outdated']);
  }

  /*
   |--------------------------------
   | Products
   |--------------------------------
   */

  /* We do not implement the category per path, this is an extra feature */
  // protected productsCommands(category?: string) {
  //   const command = ['products'];
  //
  //   if (category) {
  //     command.push('category', category);
  //   }
  //
  //   return command;
  // }
  //
  // public products(category?: string): Observable<boolean> {
  //   return this.partyNavigate(this.productsCommands(category));
  // }

  protected get productsCommand() {
    return ['products'];
  }

  public get productsUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.productsCommand);
  }

  public products(): Observable<boolean> {
    return this.partyNavigate(this.productsCommand);
  }

  public productsWithCategory(categoryId: string): Observable<boolean> {
    return this.partyNavigate(this.productsCommand, { queryParams: { categoryId: categoryId } });
  }

  public productSearch(search: string): Observable<boolean> {
    return this.partyNavigate(this.productsCommand, { queryParams: { search } });
  }

  protected productDetailsCommand(productId: string) {
    return this.productsCommand.concat(['details', productId]);
  }

  public productDetail(productId: string): Observable<boolean> {
    return this.partyNavigate(this.productDetailsCommand(productId));
  }

  protected get ipaperCatalogCommand() {
    return ['ipaper', 'catalog'];
  }

  public ipaperCatalog(): Observable<boolean> {
    return this.partyNavigate(this.ipaperCatalogCommand);
  }

  protected get ipaperMonthlyOffersCommand() {
    return ['ipaper', 'monthly-offers'];
  }

  public ipaperMonthlyOffers(): Observable<boolean> {
    return this.partyNavigate(this.ipaperMonthlyOffersCommand);
  }

  /*
   |--------------------------------
   | Order Cart
   |--------------------------------
   */

  protected get orderCartCommand() {
    return ['order', 'cart'];
  }

  public get orderCartUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.orderCartCommand);
  }

  public orderCart(): Observable<boolean> {
    return this.partyNavigate(this.orderCartCommand);
  }

  /*
   |--------------------------------
   | Order Offer
   |--------------------------------
   */

  protected get orderOfferCommand() {
    return ['order', 'offer'];
  }

  protected get orderLoginCommand() {
    return ['order', 'login'];
  }

  public get orderOfferUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.orderOfferCommand);
  }

  public orderOffer(): Observable<boolean> {
    return this.partyNavigate(this.orderOfferCommand);
  }

  public orderLogin(): Observable<boolean> {
    return this.partyNavigate(this.orderLoginCommand);
  }

  /*
   |--------------------------------
   | Order Shipment
   |--------------------------------
   */

  protected get orderShipmentCommand() {
    return ['order', 'shipment'];
  }

  public get orderShipmentUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.orderShipmentCommand);
  }

  public orderShipment(): Observable<boolean> {
    return this.partyNavigate(this.orderShipmentCommand);
  }

  /*
   |--------------------------------
   | Order Payment
   |--------------------------------
   */

  protected get orderPaymentCommand() {
    return ['order', 'payment'];
  }

  public get orderPaymentUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.orderPaymentCommand);
  }

  public orderPayment(tacChecked: boolean = false): Observable<boolean> {
    return this.partyNavigate(this.orderPaymentCommand, { queryParams: { tacChecked: tacChecked ? 1 : 0 }});
  }

  /*
   |--------------------------------
   | Order Summary
   |--------------------------------
   */

  protected get orderSummaryCommand() {
    return ['order', 'summary'];
  }

  public get orderSummaryUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.orderSummaryCommand);
  }

  public orderSummary(paymentFailed: boolean = false): Observable<boolean> {
    const queryParams = {};
    if (paymentFailed) {
      queryParams['paymentFailed'] = true;
    }
    return this.partyNavigate(this.orderSummaryCommand, { queryParams: queryParams });
  }

  /*
   |--------------------------------
   | Order Payment Handling
   |--------------------------------
   */

  protected get orderPaymentHandlingCommand() {
    return ['order', 'payment-handling'];
  }

  public get orderPaymentHandlingUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.orderPaymentHandlingCommand);
  }

  public orderPaymentHandling(): Observable<boolean> {
    return this.partyNavigate(this.orderPaymentHandlingCommand);
  }

  /*
   |--------------------------------
   | Order Completed
   |--------------------------------
   */

  protected get orderCompletedCommand() {
    return ['order', 'completed'];
  }

  public get orderCompletedUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.orderCompletedCommand);
  }

  public orderCompleted(): Observable<boolean> {
    return this.partyNavigate(this.orderCompletedCommand);
  }

  /*
   |--------------------------------
   | My Party
   |--------------------------------
   */

  protected get myPartyCommand() {
    return ['my-party'];
  }

  public myParty(): Observable<boolean> {
    return this.partyNavigate(this.myPartyCommand);
  }

  /*
  |--------------------------------
  | Hostess Gift
  |--------------------------------
  */

  protected get hostessGiftCommand() {
    return ['hostess-gift'];
  }

  protected get hostessGiftCartCommand() {
    return ['hostess-gift', 'cart'];
  }

  public hostessGift(): Observable<boolean> {
    return this.partyNavigate(this.hostessGiftCommand);
  }

  public get hostessGiftUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.hostessGiftCommand);
  }

  public hostessGiftCart(): Observable<boolean> {
    return this.partyNavigate(this.hostessGiftCartCommand);
  }

  public get hostessGiftCartUrlTree(): Observable<UrlTree> {
    return this.partyUrlTree(this.hostessGiftCartCommand);
  }

  /*
   |--------------------------------
   | Party Wall
   |--------------------------------
   */

  public partyWall(): Observable<boolean> {
    return this.partyNavigate(['partywall']);
  }

  /*
   |--------------------------------
   | Wishlist
   |--------------------------------
   */

  public wishlist(): Observable<boolean> {
    return this.partyNavigate(['wishlist']);
  }

  /*
   |--------------------------------
   | Cookie Settings
   |--------------------------------
   */

  public cookies(): Observable<boolean> {
    return this.partyNavigate(['cookies']);
  }

  /*
   |--------------------------------
   | Errors
   |--------------------------------
   */

  public generalError(): Observable<boolean> {
    return this.navigate(['error']);
  }

  public forbiddenError(): Observable<boolean> {
    return this.navigate(['error', 'forbidden']);
  }

  public maintenanceError(): Observable<boolean> {
    return this.navigate(['error', 'maintenance']);
  }

  protected get partyNotFoundCommand() {
    return ['error', 'party-not-found'];
  }

  public partyNotFound(): Observable<boolean> {
    return this.navigate(this.partyNotFoundCommand);
  }

  public get partyNotFoundUrlTree(): Observable<UrlTree> {
    return of(this.router.createUrlTree(this.partyNotFoundCommand));
  }

  public servicePlatformError(): Observable<boolean> {
    return this.navigate(['error', 'service-platform']);
  }
  /*
   |--------------------------------
   | Party / Invitation Outdated
   |--------------------------------
   */

  protected get partyOutdatedCommand() {
    return ['error', 'outdated'];
  }

  public get partyOutdatedUrlTree(): Observable<UrlTree> {
    return of(this.router.createUrlTree(this.partyOutdatedCommand));
  }

  public partyOutdated(): Observable<boolean> {
    return this.navigate(this.partyOutdatedCommand);
  }

  /*
  |--------------------------------
  | Open Popup
  |--------------------------------
  */

  public openDipPopup(): Observable<boolean> {
    return this.navigate([{ outlets: { popup: ['dip'] } }]);
  }

  public openOrderPeriodStartedPopup(): Observable<boolean> {
    return this.navigate([{ outlets: { popup: ['order-period-started'] } }]);
  }

  public closePopup(): Observable<boolean> {
    return this.navigate([{ outlets: { popup: null } }]);
  }

  /*
   |--------------------------------
   | Location History
   |--------------------------------
   */

  public back(): void {
    return this.location.back();
  }

  public updateLocation(urlTree): void {
    this.location.replaceState(urlTree);
  }

  /*
  |--------------------------------
  | Open external URL
  |--------------------------------
  */

  public external(url: string, newContext: boolean = true): Window {
    return this.window.open(url, newContext ? '_blank' : '_self');
  }

  public get windowLocation() {
    return this.window.location;
  }

  /*
   |--------------------------------
   | Dealer Partywall
   |--------------------------------
   */

  /**
   * Wrapper of routers navigate method that applies partyID as first command
   *
   * @param commands Router Navigation commands
   * @param extras Router Navigation extras
   * @param partyID optional partyId to use
   */
  protected dealerPartyNavigate(
    commands: any[],
    extras?: NavigationExtras,
    partyID: string = null
  ): Observable<boolean> {
    return this.dealerPartyCommands(commands, partyID).pipe(
      switchMap(dealerCommands => this.navigate(dealerCommands, extras))
    );
  }

  protected dealerPartyCommands(commands: any[], partyID: string = null): Observable<any[]> {
    const partyID$ = partyID ? of(partyID) : this.partyID.get();
    return partyID$.pipe(
      map(partyId => {
        commands.unshift('dealer', 'party', partyId);
        return commands;
      })
    );
  }

  protected dealerPartyUrlTree(
    commands: any[],
    extras?: NavigationExtras,
    partyID: string = null
  ): Observable<UrlTree> {
    return this.dealerPartyCommands(commands, partyID).pipe(
      map(dealerCommands => this.router.createUrlTree(dealerCommands, extras))
    );
  }

  public dealerUnauthenticatedError() {
    return this.dealerPartyNavigate(['unauthenticated']);
  }

  public dealerPartywall() {
    return this.dealerPartyNavigate(['partywall']);
  }

  /**
   * Sidepanel
   */
  public openSidepanel(page = "login") {
    this.setSidepanelPage(page);
    this.showSidepanel = true;
  }

  public closeSidepanel() {
    this.showSidepanel = false;
  }

  public setSidepanelPage(page: string) {
    this.sidepanelPage = page;
  }

  public sidepanelStatus(): boolean {
    return this.showSidepanel || false;
  }

  public getSidepanelPage(): string {
    return this.sidepanelPage || 'login';
  }
}
