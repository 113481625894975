import { Component, Input } from '@angular/core';

@Component({
  selector: 'idpo-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input()
  public size: 'xl' | 'l' | 'm' | 's' | 'xs' = 'l';
  @Input()
  public position: 'left' | 'center' | 'right' | 'auto' = 'auto';
  @Input()
  public shadow = true;
  @Input()
  public bgWhite = false;

  constructor() {}
}
