import { Wishlist as WishlistInterface } from '@interfaces/wishlist';
import { WishlistItem } from '@models/wishlist-item';

export class Wishlist {
  protected _items: Map<string, WishlistItem>;

  constructor(wishlistData: WishlistInterface) {
    this.init(wishlistData);
  }

  init(wishlistData: WishlistInterface): this {
    this._items = new Map<string, WishlistItem>();
    this.add(...wishlistData.map(wishlistItemInterface => new WishlistItem(wishlistItemInterface)));

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get items(): WishlistItem[] {
    return Array.from(this._items.values());
  }

  public item(id): WishlistItem {
    return this._items.get(id);
  }

  /*
  |--------------------------------
  | Setters
  |--------------------------------
  */

  /**
   * Adds wishlistItem to the items if not exists
   */
  public add(...wishlistItems: WishlistItem[]): this {
    wishlistItems.forEach(
      wishlistItem => !this._items.has(wishlistItem.id) && this._items.set(wishlistItem.id, wishlistItem)
    );
    return this;
  }

  /**
   * Updates wishlistItems of the items if already exists
   */
  public update(...wishlistItems: WishlistItem[]): this {
    wishlistItems.forEach(
      wishlistItem => this._items.has(wishlistItem.id) && this._items.set(wishlistItem.id, wishlistItem)
    );
    return this;
  }

  /**
   * Upserts wishlistItems of the items
   */
  public upsert(...wishlistItems: WishlistItem[]): this {
    wishlistItems.forEach(wishlistItem => this._items.set(wishlistItem.id, wishlistItem));
    return this;
  }

  /**
   * Deletes wishlistItem of the items
   */
  public delete(...ids: string[]): this {
    ids.forEach(id => this._items.delete(id));
    return this;
  }

  /*
  |--------------------------------
  | Calculated properties
  |--------------------------------
  */

  /**
   * States if the cart contains an item with given product id
   */
  public hasItem(productId: string): boolean {
    return this.items.findIndex(wishlistItem => wishlistItem.product.id === productId) > -1;
  }

  /**
   * States if the wishlist is empty
   */
  public get empty(): boolean {
    return this.count < 1;
  }

  /**
   * Get the number of items in the wishlist
   */
  public get count(): number {
    return this._items.size;
  }
}
