import { Injectable } from '@angular/core';
import { OrderTimelineStep } from '@interfaces';
import { OrderSteps } from '@enums';
import { Logger } from '@core/logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderService } from './order.service';
import {UserService} from "@services/user.service";

const Timeline: OrderTimelineStep[] = [
  { id: OrderSteps.Cart, icon: '1', label: 'order.timeline.cart' },
  { id: OrderSteps.Offer, icon: '2', label: 'order.timeline.offer' },
  { id: OrderSteps.Login, icon: '3', label: 'general.button.login' },
  { id: OrderSteps.Shipment, icon: '4', label: 'order.timeline.shipment' },
  { id: OrderSteps.Payment, icon: '5', label: 'order.timeline.payment' },
  { id: OrderSteps.Summary, icon: '6', label: 'order.timeline.summary' },
  { id: OrderSteps.PaymentHandling, icon: '7', label: 'order.timeline.paymentHandling', hidden: true }
];
@Injectable({
  providedIn: 'root'
})
export class OrderTimelineService {
  constructor(
      protected orderService: OrderService,
      protected userService: UserService,
      protected log: Logger
  ) {}

  steps(): Observable<OrderTimelineStep[]> {
    return this.orderService.handlesPayment().pipe(
      map(handlesPayment =>
        Timeline.map(step => {
          if (step.id === OrderSteps.PaymentHandling) {
            step.hidden = !handlesPayment;
          }
          return step;
        })
      )
    );
  }
}
