import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, pipe, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { Logger } from '@core/logger';
import { OrderService } from './order.service';
import { ApiService } from './api.service';
import { PaymentOptions } from '@enums';
import {
  LydiaPaymentData,
  PaymentParameter,
  PaymentResponse,
  SumUpPaymentData,
  PaymentService as PaymentServiceInterface
} from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class PaymentService implements PaymentServiceInterface {
  protected paymentParameter$: ReplaySubject<PaymentParameter>;
  protected paymentResponse$: ReplaySubject<PaymentResponse>;

  /**
   * Pipe for creating model from interface and set next order
   */
  protected setPaymentParameter = pipe(
    // Not set error to replay subject
    tap<PaymentParameter>(paymentParameter => this.paymentParameter$.next(paymentParameter))
  );

  constructor(protected api: ApiService, protected orderService: OrderService, protected log: Logger) {
    this.reset();
  }

  fetch(id: any): Observable<PaymentParameter> {
    return this.paymentParameter();
  }

  get(id: any): Observable<PaymentParameter> {
    return this.paymentParameter();
  }

  unset(): void {
    this.reset();
  }

  protected reset(): void {
    this.resetParameter();
    this.resetResponse();
  }

  private resetResponse() {
    if (this.paymentResponse$) {
      this.paymentResponse$.complete();
    }

    this.paymentResponse$ = new ReplaySubject<PaymentResponse>(1);
  }

  private resetParameter() {
    if (this.paymentParameter$) {
      this.paymentParameter$.complete();
    }
    this.paymentParameter$ = new ReplaySubject<PaymentParameter>(1);
    this.paymentParameter$.next(null);
  }

  /**
   * Init payment
   */
  public pay(): Observable<PaymentParameter> {
    return this.orderService.get().pipe(
      take(1),
      switchMap(order => {
        // Order needs payment ?
        if (order.needsPayment) {
          this.resetResponse();
          // Call the appropriate payment endpoint
          switch (order.payment) {
            case PaymentOptions.Lydia:
              return this.payViaLydia().pipe(this.setPaymentParameter);
            case PaymentOptions.SumUp:
              return this.payViaSumUp().pipe(this.setPaymentParameter);
          }
        }

        return of(null);
      })
    );
  }

  /**
   * Init Payment via Lydia
   *
   * @return The payment url observable
   */
  public payViaLydia(): Observable<PaymentParameter> {
    return this.api
      .post<LydiaPaymentData>('/order/payment/lydia', {})
      .pipe(map(data => <PaymentParameter>{ method: PaymentOptions.Lydia, data: data }));
  }

  /**
   * Init Payment via SumUp
   *
   * @return The payment checkoutId observable
   */
  public payViaSumUp(): Observable<PaymentParameter> {
    return this.api
      .post<SumUpPaymentData>('/order/payment/sumup', {})
      .pipe(map(data => <PaymentParameter>{ method: PaymentOptions.SumUp, data: data }));
  }

  /**
   * Cancels SumUp payment
   */
  public cancelSumUp(paymentResponse: PaymentResponse) {
    this.api
      .delete<SumUpPaymentData>('/order/payment/sumup')
      .subscribe(() => this.setPaymentResponse(paymentResponse));
  }

  /**
   * Get the latest payment parameter if
   */
  public paymentParameter(): Observable<PaymentParameter | null> {
    return combineLatest(this.paymentParameter$, this.orderService.get()).pipe(
      map(([paymentParameter, order]) =>
        paymentParameter && paymentParameter.method === order.payment ? paymentParameter : null
      ),
      distinctUntilChanged()
    );
  }

  /**
   * Set a payment response
   */
  setPaymentResponse(paymentResponse: PaymentResponse) {
    this.paymentResponse$.next(paymentResponse);
  }

  /**
   * Get the latest payment response
   */
  paymentResponse(): Observable<PaymentResponse> {
    return this.paymentResponse$.pipe(distinctUntilChanged());
  }
}
