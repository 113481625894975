import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, switchMap, take, windowTime } from 'rxjs/operators';

import { Logger } from '@app/core/logger';
import { NotificationService } from '@services/notification.service';
import { NavigationService } from '@services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { DPOErrorTypes } from '@enums/dpo-error-types.enum';
import { GlobalErrorService } from '@services/global-error.service';
import { environment } from '@environments/environment';
import { EnvironmentTypes } from '@environments/environment-types.enum';
import { AlreadyNotifiedError } from '@models/already-notified-error.model';

interface GlobalErrorNotifierData {
  title: string;
  text: string;
  error: any;
}
@Injectable()
export class GlobalErrorHandlerInterceptor implements HttpInterceptor {
  protected errorsNotifications$: Subject<GlobalErrorNotifierData>;

  /**
   * GlobalErrorHandlerInterceptor Interceptor constructor
   */
  constructor(
    protected log: Logger,
    protected navi: NavigationService,
    protected notify: NotificationService,
    protected trans: TranslateService,
    protected globalErrors: GlobalErrorService
  ) {
    this.errorsNotifications$ = new Subject<GlobalErrorNotifierData>();
    this.errorsNotifications$
      .pipe(
        windowTime(2000),
        concatMap(obs => obs.pipe(distinctUntilChanged()))
      )
      .subscribe(data => {
        this.notify.error({ title: data.title, content: data.text });
      });
  }

  protected shouldHandleError(request: HttpRequest<any>): boolean {
    const fileURLRegEx = new RegExp(/\/[^\/]*\.(jpg|jpeg|png|svg)$/);
    return !fileURLRegEx.test(request.url);
  }

  /**
   * Intercept requests for error handling.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip requests of images or static files
    if (!this.shouldHandleError(request)) {
      return next.handle(request);
    }
    // Send request with error handler
    return next.handle(request).pipe(
      catchError((error: any) => {
        this.globalErrors.set(error);
        // if not handled, rethrow
        return this.handleError(error);
      })
    );
  }

  protected handleError(response: any): Observable<never> {
    if (response instanceof HttpErrorResponse) {
      if (response.error.dpo) {
        return this.handleDPOErrors(response, response.error.dpo);
      }

      return this.handleHttpErrorViaStatus(response);
    }
    return throwError(response);
  }

  protected handleDPOErrors(response: HttpErrorResponse, dpo: DPOErrorTypes): Observable<never> {
    const data = response.error;
    switch (dpo) {
      case DPOErrorTypes.Misconfigured:
        this.log.error('Misconfiguration:', data);
        return this.notifyMisconfigurationError(response);
      case DPOErrorTypes.PartyNotFound:
        this.navi.partyNotFound().subscribe();
        return EMPTY;
      case DPOErrorTypes.PartyOutdated:
        this.navi.partyOutdated().subscribe();
        return EMPTY;
      case DPOErrorTypes.SPEntityNotFound:
        this.log.error('SP 404:', data);
        return this.notifySPNotFoundError(response, data.message);
      case DPOErrorTypes.SPGeneral:
        this.log.error('SP Error:', data);
        if (environment.type <= EnvironmentTypes.STAGING) {
          return this.notifySPError(data);
        } else {
          this.navi.servicePlatformError().subscribe();
          return EMPTY;
        }
      case DPOErrorTypes.LydiaAPI:
        this.log.error('Lydia API Error:', data);
        return this.notifyError('Lydia', data.message, data);
      case DPOErrorTypes.SumUpAPI:
        this.log.error('SumUp API Error:', data);
        return this.notifyError('SumUp', data.message, data);
      case DPOErrorTypes.FailedItemSaleRules:
        this.log.info('Failed Item Sale Rule:', data);
        return this.handleFailedItemSaleRules(data);
      case DPOErrorTypes.OrderPeriodNotActive:
        this.log.info('Order Period is not active: ', data);
        return this.handleOrderPeriodNotAcitve(data);
      case DPOErrorTypes.SPConflict:
        this.log.error('SP Error:', data);
        return this.notifyError('general.error.userAlreadyExists.title','general.error.userAlreadyExists.text', data);
      case DPOErrorTypes.StockLevelTooLow:
        this.log.info('Stock level of one or more items is too low: ', data);
        return this.handleStockTooLow(data);
    }
    return throwError(response);
  }

  protected handleHttpErrorViaStatus(response: HttpErrorResponse): Observable<never> {
    switch (response.status) {
      case 403:
        this.navi.forbiddenError().subscribe();
        return EMPTY;
      case 404:
        return this.notifyNotFoundError(response);
      case 0:
      case 408:
        return this.notifyConnectionError(response);
      case 500:
      case 501:
      case 502:
      case 504:
      case 505:
      case 511:
        return this.notifyServerError(response);
      case 503:
        return this.navi.maintenanceError().pipe(
          catchError(() => EMPTY),
          switchMap(() => EMPTY)
        );
    }

    return throwError(response);
  }

  protected handleFailedItemSaleRules(data) {
    const resultsForProducts = data.data;
    Object.keys(resultsForProducts).forEach(id => {
      const resultsForProduct: { product: { salesCode: string; description: string }; results: string[] } =
        resultsForProducts[id];
      const product = resultsForProduct.product;
      this.trans.get('forms.errors.productValidationError').subscribe(message => {
        let results = '<span class="mb-3">' + message + '</span>';
        if (resultsForProduct.results.length) {
          results += '<ul><li>';
          results += resultsForProduct.results.join('</li><li>');
          results += '</ul></li>';
          this.notify.info({
            title: `${product.salesCode} - ${product.description}`,
            content: results,
            translate: false
          });
        }
      })
    });
    return throwError(new AlreadyNotifiedError(data));
  }

  protected handleOrderPeriodNotAcitve(data) {
    this.notify.info({
      title: 'orderPeriod.notActive.title',
      content: 'orderPeriod.notActive.text',
      translate: true
    });
    return throwError(new AlreadyNotifiedError(data));
  }

  protected handleStockTooLow(error: any) {
    let resultList = '<br><ul>';
    error.data.forEach(item => {
      resultList += `<li>${item.sales_code}: ${item.description}</li>`;
    })
    resultList += '</ul>';
    const title = 'general.error.stockLevelTooLow.title';
    const text = 'general.error.stockLevelTooLow.text';
    this.trans
        .get([title, text])
        .pipe(take(1))
        .subscribe(trans => this.notify.info({ title: trans[title], content: trans[text] + resultList, translate: false }));
    return throwError(new AlreadyNotifiedError(error));
  }

  protected notifyError(title: string, text: string, error: any) {
    this.trans
      .get([title, text])
      .pipe(take(1))
      .subscribe(trans => this.errorsNotifications$.next({ title: trans[title], text: trans[text], error }));
    return throwError(new AlreadyNotifiedError(error));
  }

  protected notifyNotFoundError(error: any) {
    return this.notifyError('general.error.notFound.title', 'general.error.notFound.text', error);
  }

  protected notifySPNotFoundError(error: any, message: string = null) {
    return this.notifyError(
      'general.error.servicePlatformNotFound.title',
      message || 'general.error.servicePlatformNotFound.text',
      error
    );
  }

  protected notifySPError(error: any) {
    return this.notifyError('general.error.servicePlatform.title', 'general.error.servicePlatform.text', error);
  }

  protected notifyConnectionError(error: any) {
    return this.notifyError('general.error.connection.title', 'general.error.connection.text', error);
  }

  protected notifyMisconfigurationError(error: any) {
    return this.notifyError('general.error.misconfiguration.title', 'general.error.misconfiguration.text', error);
  }

  protected notifyServerError(error: any) {
    return this.notifyError('general.error.server.title', 'general.error.server.text', error);
  }
}
