import { WishlistItem as WishlistItemInterface, WishlistProduct } from '@interfaces/wishlist';

export class WishlistItem {
  protected _id: string;
  protected _product: WishlistProduct;

  constructor(wishlistItemData: WishlistItemInterface) {
    this.init(wishlistItemData);
  }

  init(wishlistItemData: WishlistItemInterface): this {
    this._id = wishlistItemData.id;
    this._product = wishlistItemData.product;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get id(): string {
    return this._id;
  }

  public get product(): WishlistProduct {
    return this._product;
  }

  /*
  |--------------------------------
  | Calculated properties
  |--------------------------------
  */

  /**
   * States if the wishlist item is available
   */
  public get isAvailable(): boolean {
    return !!this.product;
  }
}
