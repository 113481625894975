import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@services';
import { map, take } from 'rxjs/operators';
import { Logger } from '@core/logger';

@Injectable({
  providedIn: 'root'
})
export class OrderPeriodStartedAlreadyReadGuard implements CanActivate {
  constructor(protected log: Logger, protected userService: UserService) {}

  protected userNotReadOrderPeriodStartedNotification(): Observable<boolean> {
    return this.userService.get(true).pipe(
      take(1),
      map(user => {
        const notReadNotification = !(user && user.asGuest && user.asGuest.orderPeriodStartedRead);
        if (!notReadNotification) {
          this.log.debug('OrderPeriodStarted already read!');
        }
        return notReadNotification;
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userNotReadOrderPeriodStartedNotification();
  }
}
