import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'email2link'
})
export class Email2linkPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return value;

    // NOTE: regex sketch: https://regex101.com/r/z38WGz/3
    const regex = /(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{1,2}\w))/g;

    return value.replace(regex, '<a href="mailto:$1">$1</a>');
  }
}
