import { Component, OnInit } from '@angular/core';
import { DipService } from '@app/core/services/dip.service';
import { NotificationService } from '@app/core/services/notification.service';
import { BaseComponent } from '@shared/components/base.component';
import { NavigationService } from '@app/core/services/navigation.service';
import { Logger } from '@core/logger';

@Component({
  selector: 'idpo-dip',
  templateUrl: './dip.component.html',
  styleUrls: ['./dip.component.scss']
})
export class DipComponent extends BaseComponent implements OnInit {
  public showHelp = false;
  public showQuestion = false;

  constructor(
    protected dipService: DipService,
    protected log: Logger,
    protected notify: NotificationService,
    protected navi: NavigationService
  ) {
    super();
  }

  ngOnInit() {}

  public openHelp(): void {
    this.showHelp = true;
  }

  public confirm(): void {
    this.dipService.confirm();
  }

  public return(): void {
    this.showQuestion = true;
  }

  public decline(): void {
    this.dipService.decline();
  }

  public back(): void {
    this.showQuestion = false;
  }
}
