import {EventEmitter, Injectable} from '@angular/core';
import { Logger } from '@app/core/logger';
import { BehaviorSubject, Observable, pipe, ReplaySubject, Subject } from 'rxjs';
import { take, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import {
  PasswordForgot,
  PasswordReset,
  User as UserInterface,
  UserCreate, UserGuestLogin,
  UserLogin,
  UserProfile,
  UserVerifyAccount
} from '@interfaces/user';
import { User } from '@models/user';
import { BehaviourService } from '@interfaces/behaviour-service';
import {ConfigService} from "@services/config.service";

@Injectable({
  providedIn: 'root'
})
export class UserService implements BehaviourService<User> {
  public userChanged$: EventEmitter<User>;
  protected user$: ReplaySubject<User>;
  protected local$ = new BehaviorSubject<User>(null);
  protected loggedIn$: Subject<User> = new Subject<User>();
  protected loggedOut$: Subject<User> = new Subject<User>();

  /**
   * Pipe for creating model from interface and set next user
   */
  protected setUser = pipe(
    map<UserInterface, User>((userInterface: UserInterface) => new User(userInterface)),
    // Not set error to replay subject
    tap(user => {
      this.user$.next(user);
      this.local$.next(user);
      this.userChanged$.emit(user);
    })
  );

  constructor(
      protected api: ApiService,
      protected log: Logger,
      protected config: ConfigService
  ) {
    this.userChanged$ = new EventEmitter();
  }

  /**
   * Unset actual user to force new fetch
   */
  public unset() {
    if (this.user$) {
      this.user$.complete();
    }
    this.user$ = null;
    this.local$.next(null);
  }

  /**
   * Fetch the current user from API.
   */
  public fetch(): Observable<User> {
    if (!this.user$) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.user$ = new ReplaySubject<User>(1);
    }

    return this.api.get<UserInterface>('/user').pipe(this.setUser);
  }

  /**
   * Get the current user.
   *
   * @param local If true the local user observable is returned, no API fetch will be initialized
   *
   * @returns Gets the user info.
   */
  public get(local: boolean = false): Observable<User> {
    if (local) {
      return this.local$;
    }
    if (!this.user$) {
      this.fetch()
        .pipe(take(1))
        .subscribe();
    }
    return this.user$;
  }

  /**
   * Get the user at login event
   */
  public loggedIn(): Observable<User> {
    return this.loggedIn$;
  }

  /**
   * Get the user at logout event
   */
  public loggedOut(): Observable<User> {
    return this.loggedOut$;
  }

  /**
   * Create a new user account.
   *
   * @param userCreateParameter User parameter for creation/registration.
   */
  public create(userCreateParameter: UserCreate): Observable<User> {
    if (this.config.country == 'DE') {
      return this.api.post('/user', userCreateParameter).pipe(
        map(() => this.unset()),
        switchMap(() => this.fetch()),
        tap(user => this.loggedIn$.next(user))
      );
    } else {
      return this.api.post('/user', userCreateParameter);
    }
  }

  /**
   * Update user data and fetch updated user.
   *
   * @param userProfileParameter User data for profile update.
   *
   * @return The updated user stream
   */
  public update(userProfileParameter: UserProfile): Observable<User> {
    return this.api.put<void>('/user', userProfileParameter).pipe(switchMap(() => this.fetch()));
  }

  /**
   * Delete user account.
   */
  public delete(): Observable<void> {
    return this.api.delete<void>('/user').pipe(
      switchMap(() => this.get()),
      tap(user => this.loggedOut$.next(user)),
      map(() => this.unset())
    );
  }

  /**
   * Login user
   */
  public login(userData: UserLogin): Observable<User> {
    return this.api.post<void>('/user/login', userData).pipe(
      map(() => this.unset()),
      switchMap(() => this.fetch()),
      tap(user => this.loggedIn$.next(user))
    );
  }

  /**
   * Login guest user
   */
  public guestLogin(userData: UserGuestLogin): Observable<User> {
    return this.api.post<void>('/user/login/guest', userData).pipe(
      map(() => this.unset()),
      switchMap(() => this.fetch()),
      tap(user => this.loggedIn$.next(user))
    );
  }

  /**
   * Verify user account and fetch verified and logged in user.
   *
   * @return The logged in user of the verified account stream
   */
  public verify(userVerifyData: UserVerifyAccount): Observable<User> {
    return this.api.put<void>('/user/verify', userVerifyData).pipe(switchMap(() => this.fetch()));
  }

  /**
   * Logout user
   */
  public logout(): Observable<void> {
    return this.api.post<void>('/user/logout', {}).pipe(
      switchMap(() => this.get()),
      tap(user => this.loggedOut$.next(user)),
      map(() => this.unset())
    );
  }

  /**
   * Confirm terms and conditions and fetch user.
   *
   * @return The user of the TaC confirmed account
   */
  public confirmTermsAndConditions(): Observable<User> {
    return this.api.put<void>('/user/tac/confirm', {}).pipe(switchMap(() => this.fetch()));
  }

  /**
   * Join the actual party and fetch user
   *
   * @return The user that joined the party
   */
  public join(): Observable<User> {
    return this.api.post('/join', {}).pipe(switchMap(() => this.fetch()));
  }

  /**
   * Request a password reset process
   */
  public forgotPassword(passwordForgotData: PasswordForgot): Observable<void> {
    return this.api.post<void>('/user/password/forgot', passwordForgotData).pipe();
  }

  /**
   * Reset password
   */
  public resetPassword(passwordSetData: PasswordReset): Observable<void> {
    return this.api.put<void>('/user/password/reset', passwordSetData);
  }

  /**
   * Set the order period started notification as read
   *
   * @return The user with notification read
   */
  public readOrderPeriodStarted(): Observable<User> {
    return this.api.put<void>('/user/as-guest/read-order-period-started', {}).pipe(switchMap(() => this.fetch()));
  }
}
