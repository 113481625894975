import { Component, ViewChild, ElementRef, Input, Inject, HostListener, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const DropdownState = {
  show: 'show',
  hide: 'hide'
};

@Component({
  selector: 'idpo-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @ViewChild('content') public content: ElementRef<HTMLElement>;

  @Input() public addClass = '';
  @Input() public clickOutside = true;
  @Input() public buttonText = '';
  @Input() public buttonIcon = '';
  @Input()
  public set show(show: boolean) {
    this.DropdownState = show ? DropdownState.show : DropdownState.hide;

    // Set timeout to enable hide functionality (prevents double-click show-hide issues)
    this.hideEnabled = false;
    if (show) {
      setTimeout(() => {
        this.hideEnabled = true;
      }, 500);
    }
  }

  @Output() public showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get show(): boolean {
    return this.DropdownState === DropdownState.show;
  }

  public DropdownState = DropdownState.hide;
  private hideEnabled = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public hide(): void {
    this.show = false;
    this.showChange.emit(this.show);
  }

  @HostListener('document:click', ['$event'])
  public clickHandler(event: any): void {
    if (this.hideEnabled) {
      this.hide();
    }
  }

  private clickedOutside(element: HTMLElement): boolean {
    return this.elementExists(element) && !this.content.nativeElement.contains(element);
  }

  private elementExists(element: HTMLElement): boolean {
    return this.document.body.contains(element);
  }
}
