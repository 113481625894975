import { Component, Input } from '@angular/core';
import { Party } from '@app/shared/models/party';

@Component({
  selector: 'idpo-party-details',
  templateUrl: './party-details.component.html',
  styleUrls: ['./party-details.component.scss']
})
export class PartyDetailsComponent {
  @Input()
  public party: Party;

  constructor() {}
}
