import { Injectable } from '@angular/core';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Logger } from '../logger';
import { ApiService } from './api.service';
import { Product, Products } from '@models';
import { Product as ProductInterface, ProductCategory, ProductService as ProductServiceInterface } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProductService implements ProductServiceInterface {
  protected products$: ReplaySubject<Products>;

  /**
   * Pipe for creating model from interface and set next products
   */
  protected setProducts = pipe(
    map(products => new Products(Object.keys(products).map(productKey => new Product(products[productKey])))),
    // Not set error to replay subject
    tap(products => this.products$.next(products))
  );

  constructor(protected api: ApiService, protected log: Logger) {}

  /**
   * Unset actual products to force new fetch
   */
  public unset() {
    if (this.products$) {
      this.products$.complete();
    }
    this.products$ = null;
  }

  protected get baseUrlSegment(): string {
    return '/products';
  }
  /**
   * Fetch available products from API.
   */
  public fetch(): Observable<Products> {
    if (!this.products$) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.products$ = new ReplaySubject<Products>(1);
    }

    return this.api.get<ProductInterface[]>(this.baseUrlSegment).pipe(this.setProducts);
  }

  /**
   * Get available products
   */
  public get(): Observable<Products> {
    if (!this.products$) {
      this.fetch().subscribe();
    }
    return this.products$;
  }

  /**
   * Get available products
   */
  public getByOrder(orderId): Observable<Product[]> {
    return this.api.get<Product[]>(this.baseUrlSegment + '/order/' + orderId);
  }

  /**
   * Get available categories
   */
  public getCategories(): Observable<ProductCategory[]> {
    return this.products$.pipe(map(products => products.categories));
  }

  /**
   * Search products with a given search term.
   *
   * Searched keys of products are 'salesCode' and 'description'.
   * See Products.searchOptions().
   *
   */
  public search(searchTerm: string): Observable<Product[]> {
    return this.products$.pipe(map(products => products.search(searchTerm)));
  }

  public filterByCategoryId(categoryId: string): Observable<Product[]> {
    return this.products$.pipe(map(products => products.filterByCategoryName(categoryId)));
  }

  public filterByCategoryName(categoryName: string): Observable<Product[]> {
    return this.products$.pipe(map(products => products.filterByCategoryName(categoryName)));
  }

  public findBySalesCode(salesCode: string): Observable<Product> {
    return this.products$.pipe(map(products => products.findBySalesCode(salesCode)));
  }

  public findById(id: string): Observable<Product> {
    return this.products$.pipe(map(products => products.findById(id)));
  }
}
