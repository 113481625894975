import { Component, Input, OnInit } from '@angular/core';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

export interface RemainingCountdown {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
@Component({
  selector: 'idpo-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {
  public remaining$: Observable<RemainingCountdown>;
  public deadline$ = new BehaviorSubject<Moment>(moment());

  @Input() public set deadline(deadline: Moment) {
    if (!deadline.isSame(this.deadline$.value)) {
      this.deadline$.next(deadline);
    }
  }

  constructor() {}

  public ngOnInit(): void {
    this.remaining$ = combineLatest(this.deadline$.pipe(distinctUntilChanged()), timer(0, 10000)).pipe(
      map(([deadline, tick]) => {
        const now = moment();
        const duration = moment.duration(deadline.diff(now));

        const remaining: RemainingCountdown = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        };

        Object.keys(remaining).forEach((key: keyof RemainingCountdown) => {
          remaining[key] = duration.get(key);
          duration.subtract(remaining[key], key);
        });

        return remaining;
      }),
      shareReplay()
    );
  }
}
