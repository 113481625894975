import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, take, map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { isApiValidationError as isApiValidationErrorResponse } from '@interfaces/api-validation-error.response';
import { ApiValidationError } from '@models/api-validation-error.model';
import { PartyIDService } from '@services/party-id.service';
import * as UrlAssembler from 'url-assembler';
import { Logger } from '@core/logger';

export type ApiURL = UrlAssembler;
export type ApiURLParameter =
  | ((url: UrlAssembler) => UrlAssembler)
  | UrlAssembler
  | string[]
  | string
  | null
  | undefined;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private get partyId$(): Observable<string> {
    return this.partyID.get();
  }

  /**
   * API Base URL
   */
  public get baseURL(): ApiURL {
    return new UrlAssembler(this.trimTrailingSlashes(environment.api.baseUrl));
  }

  protected constructor(public http: HttpClient, protected partyID: PartyIDService, protected log: Logger) {}

  /**
   * Trims trailing slashes from string.
   */
  protected trimTrailingSlashes(str: string): string {
    return str.trim().replace(/\/+$/, '');
  }

  protected useApiURLParameter(baseURL: ApiURL, urlParameter: ApiURLParameter): ApiURL {
    // Is string?
    if (typeof urlParameter === 'string') {
      return baseURL.segment(urlParameter);
    }

    // Is callback
    if (urlParameter instanceof Function) {
      return urlParameter(baseURL);
    }

    // Is string array
    if (urlParameter instanceof Array) {
      for (const segment of urlParameter) {
        baseURL.segment(segment);
      }
      return baseURL;
    }

    // Is empty | null | undefined?
    if (!urlParameter) {
      // nothing to do
      return baseURL;
    }

    // Is UrlAssembler
    if (urlParameter instanceof UrlAssembler) {
      return urlParameter;
    }

    this.log.warn('Unexpected API URL Parameter', urlParameter, baseURL);
  }

  public partyURL(route: ApiURLParameter = null): Observable<string> {
    return this.partyId$.pipe(
      distinctUntilChanged(),
      map(partyId => this.baseURL.segment('/partys/:partyId').param({ partyId })),
      map(partyBaseURL => this.useApiURLParameter(partyBaseURL, route)),
      map(partyURL => partyURL.toString())
    );
  }

  public commonURL(route: ApiURLParameter = null): Observable<string> {
    return of(this.baseURL.segment('/common')).pipe(
      map(commonBaseURL => this.useApiURLParameter(commonBaseURL, route)),
      map(commonURL => commonURL.toString())
    );
  }

  public parseApiValidationError(err: any, parameter: any) {
    if (err instanceof HttpErrorResponse && isApiValidationErrorResponse(err.error)) {
      if (err.status === 422) {
        return throwError(new ApiValidationError(err.error, parameter));
      }
    }
    return throwError(err);
  }

  /*
  |----------------------------------------------------------------
  | Party Scope
  |----------------------------------------------------------------
  |
  | Convenience http methods for party API scope.
  */

  public get<T>(apiURLParameter: ApiURLParameter = null): Observable<T> {
    return this.partyURL(apiURLParameter).pipe(
      switchMap(url => this.http.get<T>(url)),
      take(1)
    );
  }

  public post<T>(apiURLParameter: ApiURLParameter = null, body: any | null): Observable<T> {
    return this.partyURL(apiURLParameter).pipe(
      switchMap(url => this.http.post<T>(url, body).pipe(catchError(err => this.parseApiValidationError(err, body)))),
      take(1)
    );
  }

  public put<T>(apiURLParameter: ApiURLParameter = null, body: any | null): Observable<T> {
    return this.partyURL(apiURLParameter).pipe(
      switchMap(url => this.http.put<T>(url, body).pipe(catchError(err => this.parseApiValidationError(err, body)))),
      take(1)
    );
  }

  public delete<T>(apiURLParameter: ApiURLParameter = null): Observable<T> {
    return this.partyURL(apiURLParameter).pipe(
      switchMap(url => this.http.delete<T>(url)),
      take(1)
    );
  }

  /*
  |----------------------------------------------------------------
  | Common Scope
  |----------------------------------------------------------------
  |
  | Convenience http methods for common API scope.
  */

  public commonGet<T>(apiURLParameter: ApiURLParameter = null): Observable<T> {
    return this.commonURL(apiURLParameter).pipe(
      switchMap(url => this.http.get<T>(url)),
      take(1)
    );
  }
}
