export enum DPOErrorTypes {
  Misconfigured = 'MISCONFIGURED',
  PartyNotFound = 'PARTY_NOT_FOUND',
  PartyOutdated = 'PARTY_OUTDATED',
  SPGeneral = 'SP_GENERAL',
  SPConflict = 'SP_CONFLICT',
  SPEntityNotFound = 'SP_ENTITY_NOT_FOUND',
  LydiaAPI = 'LYDIA_API',
  SumUpAPI = 'SUMUP_API',
  FailedItemSaleRules = 'FAILED_ITEM_SALE_RULES',
  OrderPeriodNotActive = 'ORDER_PERIOD_NOT_ACTIVE',
  ProductDetailsNotFound = 'PRODUCT_DETAILS_NOT_FOUND',
  StockLevelTooLow = 'STOCK_TOO_LOW'
}
