import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, Output, ViewChild } from '@angular/core';

const ModalState = {
  show: 'show',
  hide: 'hide'
};

@Component({
  selector: 'idpo-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('modalAnimation', [
      state(
        ModalState.show,
        style({
          opacity: 1,
          visibility: 'visible',
          transform: 'scale(1)'
        })
      ),
      state(
        ModalState.hide,
        style({
          opacity: 0,
          visibility: 'hidden',
          transform: 'scale(1.1)'
        })
      ),
      transition('show => hide', animate('100ms ease-in')),
      transition('hide => show', animate('100ms ease-out'))
    ])
  ]
})
export class ModalComponent {
  @ViewChild('content') public content: ElementRef<HTMLElement>;

  @Input() public closable = true;
  @Input() public clickOutside = true;
  @Input() public noPadding = false;
  @Input() public setWidth = '';
  @Input()
  public set show(show: boolean) {
    this.modalState = show ? ModalState.show : ModalState.hide;

    // Set timeout to enable hide functionality (prevents double-click show-hide issues)
    this.hideEnabled = false;
    if (show) {
      setTimeout(() => {
        this.hideEnabled = true;
      }, 500);
    }
  }

  @Output() public showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get show(): boolean {
    return this.modalState === ModalState.show ? true : false;
  }

  public modalState = ModalState.hide;

  private hideEnabled = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public hide(): void {
    this.show = false;
    this.showChange.emit(this.show);
  }

  @HostListener('document:click', ['$event'])
  public clickHandler(event: any): void {
    if (this.closable && this.clickOutside && this.hideEnabled && this.clickedOutside(event.target)) {
      this.hide();
    }
  }

  private clickedOutside(element: HTMLElement): boolean {
    return this.elementExists(element) && !this.content.nativeElement.contains(element);
  }

  private elementExists(element: HTMLElement): boolean {
    return this.document.body.contains(element);
  }
}
