import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@components/base.component';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'idpo-thank-you-password',
  templateUrl: './thank-you-password.component.html',
  styleUrls: ['./thank-you-password.component.scss']
})
export class ThankYouPasswordComponent extends BaseComponent implements OnInit {
  public message = '';

  constructor(
    protected route: ActivatedRoute,
    protected translate: TranslateService,
    protected navi: NavigationService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this.translate.get('thankYou.passwordReset').subscribe(message => {
        this.message = message;
      })
    );
  }

  public goBack(): void {
    this.navi.back();
  }

  public backToLogin(): void {
    this.navi.login().subscribe();
  }
}
