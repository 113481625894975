import { Directive, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { VideoService } from '@app/core/services/video.service';
import { EmbeddedVideo } from '../interfaces';

@Directive({
  selector: '[idpoAttachVideo]'
})
export class AttachVideoDirective implements AfterViewInit {
  protected links: EmbeddedVideo[] = [];

  constructor(private element: ElementRef, private renderer: Renderer2, protected video: VideoService) {}

  public ngAfterViewInit(): void {
    this.links = this.getLinks();

    if (this.links.length > 0) {
      this.attachVideos();
    }
  }

  private attachVideos(): void {
    const wrapperdiv = document.createElement('div');
    wrapperdiv.classList.add('message-videos');
    const separator = document.createElement('hr');
    separator.classList.add('message-video-separator');
    const parent = this.element.nativeElement.parentNode;

    this.links.map((video, idx) => {
      const videodiv = document.createElement('div');
      videodiv.classList.add('embedded-video');
      videodiv.innerHTML = this.video.embeddedVideoHtml(video, {}, idx);
      this.renderer.appendChild(wrapperdiv, videodiv);
    });

    this.renderer.appendChild(parent, separator);
    this.renderer.appendChild(parent, wrapperdiv);
  }

  private getLinks(): EmbeddedVideo[] {
    return this.video.extractEmbeddedVideos(this.element.nativeElement.innerHTML);
  }
}
