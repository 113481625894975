import { Promotion as PromotionInterface } from '@interfaces/promotion';
import { CarouselItem } from '@interfaces/carousel';
import { environment } from '@environments/environment';

export class Promotion implements CarouselItem {
  protected _text: string;
  protected _image: string;
  protected _linkText: string;
  protected _link: string;

  constructor(productData: PromotionInterface) {
    this.init(productData);
  }

  init(productData: PromotionInterface): this {
    this._text = productData.text;
    this._link = productData.link;
    this._linkText = productData.linkText;
    this._image = productData.image;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get text(): string {
    return this._text;
  }

  public get image(): string {
    return environment.api.baseUrl + '/' + this._image;
  }

  public get linkText(): string {
    return this._linkText;
  }

  public get link(): string {
    return this._link;
  }
}
