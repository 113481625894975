import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

export abstract class BaseComponent implements OnDestroy {
  protected subscriptions: Subscription[];

  protected addSubscription(...subscription: Subscription[]) {
    this.subscriptions.push(...subscription);
  }

  protected unsubscribe() {
    this.subscriptions.forEach(sub => {
      if (sub && typeof sub.unsubscribe === 'function') {
        sub.unsubscribe();
      }
    });
  }

  protected constructor() {
    this.subscriptions = [];
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
