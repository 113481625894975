import { Catalog as CatalogInterface } from '@interfaces/catalog';
import { CarouselItem } from '@interfaces/carousel';
import { environment } from '@environments/environment';
import { IPaperTypes } from '@enums';

export class Catalog implements CarouselItem {
  protected _type: IPaperTypes;
  protected _text: string;
  protected _image: string;
  protected _link: string;

  constructor(catalogData: CatalogInterface) {
    this.init(catalogData);
  }

  init(catalogData: CatalogInterface): this {
    this._type = catalogData.type;
    this._text = catalogData.text;
    this._image = catalogData.image;
    this._link = catalogData.link;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get type(): IPaperTypes {
    return this._type;
  }

  public get text(): string {
    return this._text;
  }

  public get image(): string {
    return environment.api.baseUrl + '/' + this._image;
  }

  public get link(): string {
    return this._link;
  }
}
