import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';

import { AccountRoutingModule } from './account-routing.module';
import { AccountConfirmComponent } from './confirm';
import { AccountDeletedComponent } from './deleted';
import { AccountUnconfirmedComponent } from './unconfirmed';
import { AccountCreateComponent } from './create';
import { LoginComponent } from './login';
import { JoinComponent } from './join';
import { PasswordForgotComponent, PasswordResetComponent } from './password';
import { ThankYouMailComponent } from './thank-you-mail';
import { ThankYouPasswordComponent } from './thank-you-password';

@NgModule({
  declarations: [
    AccountConfirmComponent,
    AccountDeletedComponent,
    AccountUnconfirmedComponent,
    AccountCreateComponent,
    LoginComponent,
    JoinComponent,
    PasswordForgotComponent,
    PasswordResetComponent,
    ThankYouMailComponent,
    ThankYouPasswordComponent
  ],
  exports: [
    LoginComponent,
    PasswordForgotComponent,
    AccountCreateComponent
  ],
  imports: [SharedModule, AccountRoutingModule]
})
export class AccountModule {}
