import { Injectable } from '@angular/core';
import { Avatar } from '@interfaces/user';
import { GlobalsService } from '@services/globals.service';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  private avatars: Avatar[] = [
    { id: 'avatar_female_01.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_female_01.svg` },
    { id: 'avatar_female_02.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_female_02.svg` },
    { id: 'avatar_female_03.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_female_03.svg` },
    { id: 'avatar_female_04.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_female_04.svg` },
    { id: 'avatar_female_05.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_female_05.svg` },
    { id: 'avatar_female_06.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_female_06.svg` },
    { id: 'avatar_male_01.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_male_01.svg` },
    { id: 'avatar_male_02.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_male_02.svg` },
    { id: 'avatar_male_03.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_male_03.svg` },
    { id: 'avatar_blank_female.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_blank_female.svg` },
    { id: 'avatar_blank_male.svg', img: `${this.globals.CdnBaseUrl}/img/avatar_blank_male.svg` },
    { id: 'icon_colorful_activity.svg', img: `${this.globals.CdnBaseUrl}/img/icon_colorful_activity.svg` },
  ];

  constructor(protected globals: GlobalsService) {}

  public getAll(): Avatar[] {
    return this.avatars;
  }

  public getAvatar(id): Avatar {
    return this.avatars.find(avatar => avatar.id === id);
  }
}
