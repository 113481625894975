import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as UrlAssembler from 'url-assembler';
import { Logger } from '@core/logger';
import { environment } from '@environments/environment';
import { ApiService, ApiURL } from '@services/api.service';
import { PartyIDService } from '@services/party-id.service';

@Injectable({
  providedIn: 'root'
})
export class DealerApiService extends ApiService {
  /**
   * API Base URL
   */
  public get baseURL(): ApiURL {
    return new UrlAssembler(this.trimTrailingSlashes(environment.api.baseUrl)).segment('/tw/v1/chat');
  }

  protected constructor(http: HttpClient, partyID: PartyIDService, log: Logger) {
    super(http, partyID, log);
  }
}
