import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { environment } from 'src/environments/environment';

@Directive({
  selector: '[idpoImage]'
})
export class ImageDirective implements AfterViewInit, OnChanges {
  @Input() public idpoImage: string;

  private initialized = false;

  constructor(private renderer: Renderer2, private view: ElementRef) {}

  public ngAfterViewInit(): void {
    this.setImage();
  }

  public ngOnChanges(): void {
    this.setImage();
  }

  private setImage(): void {
    if (!this.initialized && this.view.nativeElement && this.idpoImage) {
      this.initialized = true;

      this.renderer.setAttribute(this.view.nativeElement, 'src', `${environment.assets.imagePath}${this.idpoImage}`);
    }
  }
}
