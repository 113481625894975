import { Component, Input } from '@angular/core';
import { AvatarService } from '@services/avatar.service';
import { HeaderContent } from '@interfaces/header';
import { Avatar } from '@interfaces/user';
import { User } from '@models/user';
import { Party } from '@models/party';

@Component({
  selector: 'idpo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() public content: HeaderContent;
  @Input() public user: User;
  @Input() public party: Party;
  @Input() public translate = true;
  @Input() public addClass = '';

  constructor(protected avatarService: AvatarService) {}

  public get showTitle() {
    return this.content.header && this.content.header !== '';
  }

  public get showSubTitle() {
    return this.content.subTitle && this.content.subTitle !== '';
  }

  public get showDealer() {
    return this.party && this.party.dealer;
  }

  public get titleTranslateParams() {
    if (!this.user) {
      return null;
    }

    return {
      name: this.user.firstName
    };
  }

  public get subTitleTranslateParams() {
    if (!this.party) {
      return null;
    }

    return {
      host: this.party.host.firstName
    };
  }

  public get dealerTranslateParams() {
    if (!this.party) {
      return null;
    }

    return {
      dealer: this.party.dealer.firstName
    };
  }

  public get dealerAvatar(): Avatar {
    return this.avatarService.getAvatar(this.party.dealer.avatar);
  }

  public get getRole(): string {
    if (!this.user) {
      return null;
    }

    return this.user.role;
  }
}
