import { PartyUserBaseComponent } from '@components/party-user-base.component';
import { Observable } from 'rxjs';
import { PartyService } from '@services/party.service';
import { UserService } from '@services/user.service';
import { ProductService } from '@services/product.service';
import { Products } from '@models/products';

export abstract class PartyUserProductsBaseComponent extends PartyUserBaseComponent {
  public get products$(): Observable<Products> {
    return this.productsService.get();
  }

  protected constructor(
    partyService: PartyService,
    userService: UserService,
    protected productsService: ProductService
  ) {
    super(partyService, userService);
  }
}
