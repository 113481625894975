import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'idpo-add-remove-button',
  templateUrl: './add-remove-button.component.html',
  styleUrls: ['./add-remove-button.component.scss']
})
export class AddRemoveButtonComponent {
  @Input() public isAddable: boolean;
  @Input() public isRemovable: boolean;
  @Input() public isSubmitting: boolean;

  @Input() public i18n: {
    addOffer: string;
    offerAdded: string;
    removeOffer: string;
  };

  @Output() public adding = new EventEmitter<Event>();
  @Output() public removing = new EventEmitter<Event>();

  constructor() {}

  public add(event) {
    this.adding.emit(event);
  }

  public remove(event) {
    this.removing.emit(event);
  }
}
