import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PartyService } from '@services/party.service';
import { Logger } from '@core/logger';
import { Party } from '@models/party';

@Injectable({
  providedIn: 'root'
})
export class PartyResolverService implements Resolve<Party> {
  constructor(protected party: PartyService, protected log: Logger) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Party> {
    return this.party.get().pipe(take(1));
  }
}
