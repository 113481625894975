import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Logger } from '@app/core/logger';
import { take, switchMap } from 'rxjs/operators';
import { NavigationService } from '@services/navigation.service';
import { OrderService } from '@services/order.service';

@Injectable({
  providedIn: 'root'
})
export class OrderPaymentGuard implements CanActivate {
  constructor(protected log: Logger, protected navi: NavigationService, protected orderService: OrderService) {}

  public hasShipment(): Observable<boolean | UrlTree> {
    return this.orderService.shipment().pipe(
      take(1),
      switchMap(shipment => {
        if (!shipment) {
          // Check if shipment is set
          this.log.info('Shipment is no set');
          // Go to cart
          return this.navi.orderShipmentUrlTree;
        }
        return of(true);
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasShipment();
  }
}
