import { CartItem as CartItemInterface } from '@interfaces/cart';
import { Product } from '@models/product';

export class CartItem {
  protected _productId: string;
  protected _product: Product | null;
  protected _amount: number;

  constructor(cartItemData: CartItemInterface) {
    this.init(cartItemData);
  }

  init(cartItemData: CartItemInterface): this {
    this._productId = cartItemData.productId;
    this._amount = cartItemData.amount;
    this._product = cartItemData.product ? new Product(cartItemData.product) : null;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get productId(): string {
    return this._productId;
  }

  public get amount(): number {
    return this._amount;
  }

  public get product(): Product {
    return this._product;
  }

  /*
  |--------------------------------
  | Calculated properties
  |--------------------------------
  */

  /**
   * States if the cart item is available
   */
  public get isAvailable(): boolean {
    return !!this.product;
  }
}
