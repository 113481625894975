import { Product as ProductInterface } from '@interfaces/product';
import { Md5 } from 'ts-md5';
import { ProductSaleRule } from '@models/product-sale-rule';
import { ProductDetails } from '@models/product-details';

export class Product {
  protected _id: string;
  protected _salesCode: string;
  protected _itemNo: string;
  protected _sku: string;
  protected _usi: string;
  protected _itemFamilyId: number;
  protected _description: string;
  protected _retailPrice: number;
  protected _suggestedRetailPrice: number;
  protected _starAmount: number;
  protected _currency: string;
  protected _imageUrl: string;
  protected _overlayImageUrl: string;
  protected _categoryId: string;
  protected _categoryName: string;
  protected _categoryImageUrl: string;
  protected _categorySort: number;
  protected _isPromo: boolean;
  protected _startsInWeek: string;
  protected _isHostOnly: boolean;
  protected _minGuestTurnover: number;
  protected _saleRule: ProductSaleRule;
  protected _details: ProductDetails;
  protected _labels: string;
  protected _hasLowStock: boolean;

  constructor(productData: ProductInterface) {
    this.init(productData);
  }

  public static createCategoryId(categoryName: string | null) {
    return categoryName ? (<string>Md5.hashStr(categoryName)).slice(0, 8) : null;
  }

  init(productData: ProductInterface): this {
    this._id = productData.id;
    this._salesCode = productData.salesCode;
    this._itemNo = productData.itemNo;
    this._sku = productData.sku;
    this._usi = productData.itemFamilyId + productData.itemNo;
    this._itemFamilyId = productData.itemFamilyId;
    this._description = productData.description;
    this._retailPrice = productData.retailPrice;
    this._suggestedRetailPrice = productData.suggestedRetailPrice;
    this._starAmount = productData.starAmount;
    this._currency = productData.currency;
    this._imageUrl = productData.imageUrl;
    this._overlayImageUrl = productData.overlayImageUrl;
    this._categoryId = Product.createCategoryId(productData.categoryName);
    this._categoryName = productData.categoryName;
    this._categoryImageUrl = productData.categoryImageUrl;
    this._categorySort = productData.categorySort;
    this._isPromo = productData.isPromo != null ? productData.isPromo : null;
    this._startsInWeek = productData.startsInWeek != null ? productData.startsInWeek : null;
    this._isHostOnly = productData.isHostOnly != null ? productData.isHostOnly : null;
    this._minGuestTurnover = productData.minGuestTurnover != null ? productData.minGuestTurnover : null;
    this._saleRule = productData.saleRule ? new ProductSaleRule(productData.saleRule) : null;
    this._labels = productData.labels;
    this._hasLowStock = productData.hasLowStock;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get id(): string {
    return this._id;
  }

  public get salesCode(): string {
    return this._salesCode;
  }

  public get itemNo(): string {
    return this._itemNo;
  }

  public get sku(): string {
    return this._sku;
  }

  public get usi(): string {
    return this._usi;
  }

  public get itemFamilyId(): number {
    return this._itemFamilyId;
  }

  public get description(): string {
    return this._description;
  }

  public get retailPrice(): number {
    return this._retailPrice;
  }

  public get suggestedRetailPrice(): number {
    return this._suggestedRetailPrice;
  }

  /**
   * NOTE: This property is only set for hostess gift products
   */
  public get starAmount(): number {
    return this._starAmount;
  }

  public get currency(): string {
    return this._currency;
  }

  public get imageUrl(): string {
    return this._imageUrl;
  }

  public get overlayImageUrl(): string {
    return this._overlayImageUrl;
  }

  public get categoryId(): string {
    return this._categoryId;
  }

  public get categoryName(): string {
    return this._categoryName;
  }

  public get categoryImageUrl(): string {
    return this._categoryImageUrl;
  }

  public get categorySort(): number {
    return this._categorySort;
  }

  /**
   * NOTE: This property is not set for CartItem.Products
   */
  public get isPromo(): boolean | null {
    return this._isPromo;
  }

  /**
   * NOTE: This property is not set for CartItem.Products
   */
  public get startsInWeek(): string | null {
    return this._startsInWeek;
  }

  /**
   * NOTE: This property is not set for CartItem.Products
   */
  public get isHostOnly(): boolean | null {
    return this._isHostOnly;
  }

  /**
   * NOTE: This property is not set for CartItem.Products
   */
  public get minGuestTurnover(): number | null {
    return this._minGuestTurnover;
  }

  public get saleRule(): ProductSaleRule | null {
    return this._saleRule;
  }

  public get details(): ProductDetails {
    return this._details;
  }

  public set details(details: ProductDetails) {
    this._details = details;
  }

  public get labels(): string {
    return this._labels;
  }

  public get hasLowStock(): boolean {
    return this._hasLowStock;
  }
}
