import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/user.service';
import { PasswordForgot as PasswordRecoveryParameter } from '@interfaces/user';
import formData from '@app/account/password/forgot/password-forgot.form';
import { FormService } from '@services/form.service';
import { NavigationService } from '@services/navigation.service';
import { Logger } from '@core/logger';
import { distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { BaseComponent } from '@components/base.component';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'idpo-password-forgot',
  host: {
    '[class.with-bg]': '!simpleForm'
  },
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent extends BaseComponent implements OnInit {
  @Input() simpleForm: boolean = false;
  @Input() onPasswordForgot: CallableFunction;
  public passwordForgotForm: FormGroup;
  public passwordForgotFormDefinition = formData;
  public isSubmitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected fb: FormBuilder,
    protected userService: UserService,
    protected formService: FormService,
    protected notify: NotificationService,
    protected navi: NavigationService,
    protected translate: TranslateService,
    protected log: Logger
  ) {
    super();

    this.createPasswordForgotForm();
  }

  private createPasswordForgotForm() {
    this.passwordForgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public ngOnInit() {
    this.addSubscription(
      // Disable form while submitting
      this.isSubmitting$
        .pipe(distinctUntilChanged())
        .subscribe(isSubmitting => this.formService.isSubmittingHandler(isSubmitting, this.passwordForgotForm))
    );
  }

  public submitPasswordForgotForm(): void {
    if (this.isSubmittable) {
      const parameter: PasswordRecoveryParameter = Object.assign({}, this.passwordForgotForm.value);
      this.isSubmitting$.next(true);
      this.userService
        .forgotPassword(parameter)
        .pipe(
          finalize(() => this.isSubmitting$.next(false)),
          this.formService.handleApiValidationError(this.passwordForgotForm),
          this.notify.handleGeneralError(),
          switchMap(() => {
            this.notify.success({ title: this.translate.instant('thankYou.header'), content: this.translate.instant('thankYou.passwordForgot') });
            if (this.navi.sidepanelStatus()) {
              this.navi.closeSidepanel();
            } else {
              this.navi.login();
            }
              return null;
          })
        )
        .subscribe();
    }
  }

  get isSubmittable(): boolean {
    return this.passwordForgotForm.valid && this.passwordForgotForm.dirty;
  }

  public goBack(): void {
    this.navi.back();
  }
}
