import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorService {
  public readonly errors$: ReplaySubject<any>;

  constructor() {
    this.errors$ = new ReplaySubject<any>(5);
  }

  public set(error: any) {
    this.errors$.next(error);
  }

  public get(): Observable<any> {
    return this.errors$;
  }
}
