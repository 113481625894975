import { Directive, Input, HostListener, Output, EventEmitter } from '@angular/core';

export interface CopyToClipboardResult {
  result: string;
  error?: Object;
}
@Directive({
  selector: '[idpoCopyToClipboard]'
})
export class CopyToClipboardDirective {
  @Input('idpoCopyToClipboard') text: string;
  @Output() notify: EventEmitter<CopyToClipboardResult> = new EventEmitter<CopyToClipboardResult>();

  constructor() {}

  // The HostListener will listen to click events and run the below function, the HostListener supports other standard events such as mouseenter, mouseleave etc.
  @HostListener('click') copyText() {
    // Create a dummy textarea with the text to be copied in the DOM
    const textArea = document.createElement('textarea');

    // Hide the textarea from actually showing
    textArea.style.position = 'abosute';
    textArea.style.opacity = '0';

    // Set the texarea's content to the defined attribute
    textArea.value = this.text;
    document.body.appendChild(textArea);

    // Falback solution for iOS
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      // save current contentEditable/readOnly status
      const editable = textArea.contentEditable;
      const readOnly = textArea.readOnly;

      // convert to editable with readonly to stop iOS keyboard opening
      textArea.contentEditable = 'true';
      textArea.readOnly = true;

      // create a selectable range
      const range = document.createRange();
      range.selectNodeContents(textArea);

      // select the range
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);

      // restore contentEditable/readOnly to original state
      textArea.contentEditable = editable;
      textArea.readOnly = readOnly;
    } else {
      textArea.select();
    }

    try {
      // Most modern browsers support execCommand('copy'|'cut'|'paste'), if it doesn't it should throw an error
      const successful = document.execCommand('copy');

      if (successful) {
        this.notify.emit({ result: 'success' });
      } else {
        this.notify.emit({ result: 'fail' });
      }
    } catch (err) {
      this.notify.emit({ result: 'fail', error: err });
    }

    // Finally remove the textarea from the DOM again
    document.body.removeChild(textArea);
  }
}
