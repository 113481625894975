import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Logger } from '@core/logger';
import { OrderService } from '@services/order.service';
import { Order } from '@models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderResolverService implements Resolve<Order> {
  constructor(protected orderService: OrderService, protected log: Logger) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Order> {
    return this.orderService.get().pipe(take(1));
  }
}
