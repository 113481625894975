import { Injectable } from '@angular/core';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { Guest } from '@interfaces/guest';
import { BehaviourService } from '@interfaces/behaviour-service';

@Injectable({
  providedIn: 'root'
})
export class GuestsService implements BehaviourService<Guest[]> {
  protected guests$: ReplaySubject<Guest[]>;

  /**
   * Pipe for creating model from interface and set next guest
   */
  protected setGuests = pipe(
    // Not set error to replay subject
    tap<Guest[]>(guests => this.guests$.next(guests))
  );

  constructor(protected api: ApiService) {}

  /**
   * Unset actual guests to force new fetch
   */
  public unset() {
    if (this.guests$) {
      this.guests$.complete();
    }
    this.guests$ = null;
  }

  /**
   * Fetch fresh party guests
   */
  public fetch(): Observable<Guest[]> {
    if (!this.guests$) {
      // use ReplaySubject to simulate BehaviorSubject without initial value
      this.guests$ = new ReplaySubject<Guest[]>(1);
    }

    return this.api.get<Guest[]>('/guests').pipe(this.setGuests);
  }

  /**
   * Get guest list of party
   */
  public get(): Observable<Guest[]> {
    if (!this.guests$) {
      this.fetch()
        .pipe(take(1))
        .subscribe();
    }
    return this.guests$;
  }
}
