import { Injectable } from '@angular/core';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Logger } from '@core/logger';
import * as UrlAssembler from 'url-assembler';
import { DealerApiService } from '@services/dealer-api.service';
import { PartywallService } from '@services/partywall.service';
import { Party } from '@models';
import { Party as PartyInterface } from '@interfaces';

/**
 * Get the latest chat messages
 */

@Injectable({
  providedIn: 'root'
})
export class DealerPartywallService extends PartywallService {
  protected party$: ReplaySubject<Party>;

  constructor(api: DealerApiService, log: Logger) {
    super(api, log);
  }

  /**
   * Pipe for creating model from interface and set next chat
   */
  protected setParty = pipe(
    map<PartyInterface, Party>(partyInterface => new Party(partyInterface)),
    // Not set error to replay subject
    tap<Party>(party => this.party$.next(party))
  );

  protected get baseUrlSegment(): string {
    return '';
  }

  /**
   * Fetch new chat messages
   */
  public fetchParty(): Observable<Party> {
    if (!this.party$) {
      // use ReplaySubject to simulate BehaviorSubject without initial value
      this.party$ = new ReplaySubject<Party>(1);
    }

    const queryParams: { time?: string; isReading?: any } = {};

    if (this.updatedAt) {
      queryParams.time = this.updatedAt;
    }

    if (this.isReading) {
      queryParams.isReading = '';
    }

    return this.api.get<PartyInterface>((url: UrlAssembler) => url.segment(this.baseUrlSegment)).pipe(this.setParty);
  }

  public getParty(): Observable<Party> {
    if (!this.party$) {
      this.fetchParty()
        .pipe(take(1))
        .subscribe();
    }
    return this.party$;
  }
}
