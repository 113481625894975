import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserGuestLogin as JoinUserParameter } from '@interfaces/user';
import { joinFormDefinition } from './join.form';
import { FormService } from '@services/form.service';
import { NavigationService } from '@services/navigation.service';
import { NotificationService } from '@services/notification.service';
import { Logger } from '@core/logger';
import {catchError, distinctUntilChanged, finalize, take, switchMap, map} from 'rxjs/operators';
import { BehaviorSubject, EMPTY, from } from 'rxjs';
import { UserService } from '@services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { PartyBaseComponent } from '@components/party-base.component';
import { PartyService } from '@services/party.service';

@Component({
  selector: 'idpo-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent extends PartyBaseComponent implements OnInit {
  public joinForm: FormGroup;
  public joinFormDefinition = joinFormDefinition;

  public isSubmitting$ = new BehaviorSubject<boolean>(false);
  protected redirectTo: string | null;

  constructor(
    partyService: PartyService,
    protected log: Logger,
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected formService: FormService,
    protected user: UserService,
    protected navi: NavigationService,
    protected notify: NotificationService
  ) {
    super(partyService);

    this.createForm();
  }

  protected createForm() {
    this.joinForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]]
    });
  }

  public ngOnInit(): void {
    if (this.user.get().pipe(map(user => user)) !== undefined) {
      this.navi.dashboard();
    } else {
      // Get redirectTo path if available
      this.redirectTo = this.route.snapshot.queryParamMap.get('redirectTo');
      // Disable form while submitting
      this.addSubscription(
          this.isSubmitting$
              .pipe(distinctUntilChanged())
              .subscribe(isSubmitting => this.formService.isSubmittingHandler(isSubmitting, this.joinForm))
      );
    }
  }

  public submitJoinForm(): void {
    if (this.isSubmittable) {
      const parameter: JoinUserParameter = Object.assign({}, this.joinForm.value);

      this.isSubmitting$.next(true);
      this.user
        .guestLogin(parameter)
        .pipe(
          take(1),
          finalize(() => this.isSubmitting$.next(false)),
          this.formService.handleApiValidationError(this.joinForm),
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.notify.error({
                title: 'join.errorNotification.title',
                content: 'join.errorNotification.text',
                translate: true
              });
            } else {
              this.notify.error();
            }
            return EMPTY;
          }),
          switchMap(() => (this.redirectTo ? from(this.router.navigateByUrl(this.redirectTo)) : this.navi.dashboard()))
        )
        .subscribe();
    }
  }

  public goToLogin(): void {
    this.navi
      .login()
      .pipe(take(1))
      .subscribe();
  }

  public goToAccountCreation(): void {
    this.navi
      .accountCreate()
      .pipe(take(1))
      .subscribe();
  }

  get isSubmittable(): boolean {
    return this.joinForm.valid && this.joinForm.dirty;
  }
}
