import { AbstractControl, ValidatorFn } from '@angular/forms';

export function MustMatch(matchingControlName: string, errorName: string = null): ValidatorFn {
  return (control: AbstractControl) => {
    if (control && control.parent) {
      const matchingControl = control.parent.get(matchingControlName);
      if (matchingControl) {
        if (matchingControl.value !== control.value) {
          errorName = errorName || `${matchingControlName}MustMatch`;
          return { [errorName]: true };
        }
      }
    }
    return null;
  };
}
