import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'idpo-box-layout',
  templateUrl: './box-layout.component.html',
  styleUrls: ['./box-layout.component.scss']
})
export class BoxLayoutComponent {
  @Input() public centerBox = true;
  @Input() public setBackground = false;
  @Input() public addClassToWrapper = '';
  @Input() public addClassToContainer = '';

  constructor() {}
}
