/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthUserInterceptor } from '@app/http/interceptors/auth.user.interceptor';
import { AuthDealerInterceptor } from '@app/http/interceptors/auth.dealer.interceptor';
import { OptionsInterceptor } from 'src/app/http/interceptors/options.interceptor';
import { LoggerInterceptor } from 'src/app/http/interceptors/logger.interceptor';
import { GlobalErrorHandlerInterceptor } from '@app/http/interceptors/global-error-handler.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: OptionsInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: LoggerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: GlobalErrorHandlerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthUserInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthDealerInterceptor, multi: true }
];
