import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Logger } from '@core/logger';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { HostessGiftOrder } from '@models';
import { BehaviourService, HostessGiftOrder as HostessGiftOrderInterface } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class HostessGiftOrderService implements BehaviourService<HostessGiftOrder> {
  constructor(protected api: ApiService, protected log: Logger) {}

  protected order$: ReplaySubject<HostessGiftOrder>;

  /**
   * Pipe for creating model from interface and set next order
   */
  protected setHostessGiftOrder = pipe(
    map<HostessGiftOrderInterface, HostessGiftOrder>(orderInterface => new HostessGiftOrder(orderInterface)),
    // Not set error to replay subject
    tap<HostessGiftOrder>(order => this.order$.next(order))
  );

  /**
   * Pipe for creating model from interface and set next order
   */
  protected setHostessGiftOrderAndGetActual = pipe(
    this.setHostessGiftOrder,
    switchMap(() => this.get()),
    take(1)
  );

  /**
   * Unset actual order to force new fetch
   */
  public unset() {
    if (this.order$) {
      this.order$.complete();
    }
    this.order$ = null;
  }

  /**
   * Fetch the current order from API.
   */
  public fetch(): Observable<HostessGiftOrder> {
    if (!this.order$) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.order$ = new ReplaySubject<HostessGiftOrder>(1);
    }

    return this.api.get<HostessGiftOrderInterface>('/hostess-gift/order').pipe(this.setHostessGiftOrder);
  }

  /**
   * Get the current order.
   *
   * @returns Gets the order info.
   */
  public get(): Observable<HostessGiftOrder> {
    if (!this.order$) {
      this.fetch()
        .pipe(take(1))
        .subscribe();
    }
    return this.order$;
  }

  /**
   * Commits the order
   *
   * @return The actual order state observable
   */
  public commit(): Observable<HostessGiftOrder> {
    return this.api
      .put<HostessGiftOrderInterface>('/hostess-gift/order/commit', {})
      .pipe(this.setHostessGiftOrderAndGetActual);
  }

  /**
   * States if order has been committed
   *
   * @return States if order has been committed
   */
  public isCommitted(): Observable<boolean> {
    return this.get().pipe(map(order => order.committed));
  }
}
