import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { Logger } from '@core/logger';
import { AvatarService, NotificationService } from '@services';
import { ChatRole } from '@enums';
import { Avatar, ChatMessage, MentionGuest } from '@interfaces';
import { PartywallBaseComponent } from '../partywall-base.component';

@Component({
  selector: 'idpo-partywall-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent extends PartywallBaseComponent implements OnInit {
  @Input()
  public mentionItems: MentionGuest[] = [];

  /**
   * The chat message to show
   */
  @Input()
  public message: ChatMessage;

  /**
   * States the message component is for displaying only.
   * If false, editor could be opened.
   */
  @Input()
  public readOnly = false;

  /**
   * The orientation of the message bubble
   */
  @Input()
  public orientation: 'left' | 'right' = 'left';

  /**
   * States if message is actually submitting (deleting).
   */
  @HostBinding('class.edit-mode')
  public isEditing: boolean;

  /**
   * States if message is actually submitting (deleting).
   */
  public isSubmitting: boolean;

  /**
   * States if message deletion confirmation is shown
   */
  public showDeleteConfirmation: boolean;

  public ChatRole = ChatRole;

  constructor(protected log: Logger, protected notify: NotificationService, protected avatarService: AvatarService) {
    super();
  }

  public get createdAt(): string {
    return moment(this.message.createdAt).toISOString();
  }

  ngOnInit() {}

  /**
   * Get thea avatar from name
   */
  public getChatAvatar(avatar: string): Avatar {
    return this.avatarService.getAvatar(avatar || 'avatar_blank_female.svg');
  }

  /**
   * Open editor if allowed
   */
  public openEditMode(): void {
    if (!this.readOnly) {
      this.isEditing = true;
    }
  }
  /**
   * Close editor
   */
  public closeEditMode(): void {
    this.isEditing = false;
  }

  /**
   * Delete message if allowed
   */
  protected deleteMessage(): void {
    if (!this.readOnly) {
      if (!this.isSubmitting) {
        this.isSubmitting = true;
        this.partywallService
          .delete(this.message.id)
          .pipe(
            finalize(() => (this.isSubmitting = false)),
            catchError(error => {
              this.log.debug('Remove chat message failed', error);
              this.notify.error();
              return EMPTY;
            })
          )
          .subscribe();
      }
    }
  }

  public deleteWithConfirmation(confirmed: boolean) {
    this.showDeleteConfirmation = false;
    if (confirmed) {
      this.deleteMessage();
    }
  }
}
