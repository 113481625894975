import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Logger } from '@core/logger';
import { WishlistService } from '@services/wishlist.service';
import { Wishlist } from '@models/wishlist';

@Injectable({
  providedIn: 'root'
})
export class WishlistResolverService implements Resolve<Wishlist> {
  constructor(protected wishlistService: WishlistService, protected log: Logger) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Wishlist> {
    return this.wishlistService.get().pipe(take(1));
  }
}
