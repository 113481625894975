import { Component } from '@angular/core';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'idpo-account-deleted',
  templateUrl: './account-deleted.component.html',
  styleUrls: ['./account-deleted.component.scss']
})
export class AccountDeletedComponent {
  public showHelp = false;

  constructor(protected navi: NavigationService) {}

  public openHelp(): void {
    this.showHelp = true;
  }

  public goBack(): void {
    this.navi.back();
  }
}
