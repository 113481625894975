import { Component, Input, OnInit } from '@angular/core';
import { ApiService, ConfigService, PartyService, NavigationService } from '@services';
import { ConfigLinks, FooterContent, isAddressableEmpty } from '@interfaces';
import { PartyBaseComponent } from '@components';
import { FooterHiding } from '@enums';

@Component({
  selector: 'idpo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends PartyBaseComponent implements OnInit {
  public FooterHiding = FooterHiding;
  private injectDistributorUID = {
    terms_of_use: '/terms-of-use',
    privacy_statement: '/privacy-statement',
    sales_terms: '/sales-terms'
  };

  @Input()
  public visibility: FooterHiding = FooterHiding.Complete;

  public isAddressEmpty: (Addressable) => boolean = isAddressableEmpty;
  public addressFormat: string[];
  public configLinks: ConfigLinks;
  public copyrightParams = { year: new Date().getFullYear() };
  public footer: FooterContent = {
    social: [
      { id: 'facebook', class: 'fab fa-facebook-f' },
      { id: 'youtube', class: 'fab fa-youtube' },
      { id: 'instagram', class: 'fab fa-instagram' },
      { id: 'twitter', class: 'fab fa-twitter' }
    ],
    links: [
      { id: 'tupperware', label: 'footer.tupperware' },
      { id: 'newsletter', label: 'footer.newsletter' },
      { id: 'imprint', label: 'footer.imprint' },
      { id: 'terms_of_use', label: 'footer.terms_of_use' },
      { id: 'sales_terms', label: 'footer.sales_terms' },
      { id: 'dip', label: 'footer.dip' },
      { id: 'privacy_statement', label: 'footer.privacy_statement' },
      { id: 'cookies_settings', label: 'footer.cookies_settings' }
    ],
    distributor: {
      row1: [
        { id: 'description', label: 'footer.distributor.dealership', class: 'bold' },
        { id: 'companyName', label: 'footer.distributor.companyName' },
        { id: 'companyCapital', label: 'footer.distributor.companyCapital', type: 'currency' }
      ],
      row2: [
        { id: 'address', label: 'footer.distributor.address' },
        { id: 'phone', label: 'footer.distributor.phone' },
        { id: 'fax', label: 'footer.distributor.fax' },
        { id: 'email', label: 'footer.distributor.email' }
      ],
      row3: [
        { id: 'taxNo', label: 'footer.distributor.intraCommunityVAT' },
        { id: 'commercialRegisterNo', label: 'footer.distributor.tradeRegisterNumber' }
      ]
    }
  };

  constructor(
    partyService: PartyService,
    protected config: ConfigService,
    protected api: ApiService,
    protected nav: NavigationService
  ) {
    super(partyService);
    this.configLinks = this.config.instant.plain.links;
  }

  ngOnInit(): void {
    this.addressFormat = [this.config.instant.addressFormat.join(', ')];
  }

  public configContains(key: string): boolean {
    return key in this.configLinks && this.configLinks[key];
  }

  public openFooterLink(key: string): void {
    if (key === 'cookies_settings' && this.config.cookiebotId != null) {
      this.nav.cookies().subscribe();
    } else if (Object.keys(this.injectDistributorUID).includes(key)) {
      this.api.partyURL(this.injectDistributorUID[key]).subscribe(url => window.open(url));
    } else {
      window.open(this.getUrl(key));
    }
  }

  public getUrl(key: string): string {
    return this.configLinks[key];
  }

  public get distributorRows() {
    return Object.keys(this.footer.distributor);
  }

  public get currencyType() {
    return this.config.instant.currency;
  }
}
