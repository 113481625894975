import { ChatMessage } from '@interfaces/chat';

export class Chat {
  protected _history: Map<string, ChatMessage>;

  constructor(messages: ChatMessage[]) {
    this.init(messages);
  }

  init(messages: ChatMessage[] = []): this {
    this._history = new Map<string, ChatMessage>();
    this.add(...messages);
    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get history(): Map<string, ChatMessage> {
    return this._history;
  }

  public get messages(): ChatMessage[] {
    return Array.from(this.history.values()).sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
  }

  public unreadMessages(readAt: string): number {
    const readAtParsed = Date.parse(readAt);
    return Array.from(this.history.values()).reduce((acc, message) => {
      return acc + (readAtParsed - Date.parse(message.createdAt) >= 0 ? 0 : 1);
    }, 0);
  }

  /*
  |--------------------------------
  | Getters
  |--------------------------------
  */

  /**
   * Find a chat message by message id
   */
  public find(id: string): ChatMessage | null {
    return this.history.get(id) || null;
  }

  /*
  |--------------------------------
  | Setters
  |--------------------------------
  */

  /**
   * Performs a sync of the chat message history with given delta (added, update, delete)
   *
   * For the moment we assume, that chat messages could be out of sync
   */
  public sync(added: ChatMessage[], updated: ChatMessage[], deleted: string[]): this {
    return this.delete(...deleted)
      .upsert(...updated)
      .upsert(...added);
  }

  /**
   * Adds chat messages to the chat history if not exists
   */
  public add(...messages: ChatMessage[]): this {
    messages.forEach(message => !this.history.has(message.id) && this.history.set(message.id, message));
    return this;
  }

  /**
   * Updates chat messages of the chat history if already exists
   */
  public update(...messages: ChatMessage[]): this {
    messages.forEach(message => this.history.has(message.id) && this.history.set(message.id, message));
    return this;
  }

  /**
   * Upserts chat messages of the chat history
   */
  public upsert(...messages: ChatMessage[]): this {
    messages.forEach(message => this.history.set(message.id, message));
    return this;
  }

  /**
   * Deletes chat messages of the chat history
   */
  public delete(...ids: string[]): this {
    ids.forEach(id => this.history.delete(id));
    return this;
  }
}
