import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { UserService } from '@services/user.service';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Logger } from '@app/core/logger';
import { NavigationService } from '@services/navigation.service';

/**
 * Auth Guard
 *
 * Guards from not being authenticated
 *
 * AuthGuard requires PartyGuard to be set above in route tree!
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, CanActivate, CanLoad {
  constructor(protected user: UserService, protected navi: NavigationService, protected log: Logger) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLoginState();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLoginState();
  }

  /**
   * NOTE: An observable on the canLoad guard needs to complete!
   * @see https://github.com/angular/angular/issues/18991
   * @see https://github.com/angular/angular/issues/27656
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLoginState().pipe(take(1));
  }

  protected checkLoginState() {
    // Check Login Status
    return this.user.get().pipe(
      catchError(error => {
        // This is already handled by Http Auth Interceptor
        // if (error instanceof HttpErrorResponse && error.status === 401) {
        //   return this.navi.loginUrlTree;
        // }
        return throwError(error);
      }),
      map(() => {
        return true;
      })
    );
  }
}
