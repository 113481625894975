import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Logger } from '@core/logger';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { Config } from '@models/config';
import { Config as ConfigInterface } from '@interfaces/config';
import { BehaviourService } from '@interfaces/behaviour-service';
import { environment } from '@environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements BehaviourService<Config> {
  constructor(protected api: ApiService, protected log: Logger) {}

  protected config$: ReplaySubject<Config>;

  /**
   * We load the config with APP_INITIALIZER,
   * so we can require it anytime after bootstrap
   */
  protected _instant: Config;

  /**
   * Pipe for creating model from interface and set next config
   */
  protected setConfig = pipe(
    map<ConfigInterface, Config>(configInterface => new Config(configInterface)),
    tap<Config>(
      config => {
        this._instant = config;
        this.config$.next(config);
      },
      error => this.config$.error(error)
    )
  );

  /**
   * Unset actual config to force new fetch
   */
  public unset() {
    if (this.config$) {
      this.config$.complete();
    }
    this.config$ = null;
  }

  /**
   * Fetch the current config from API.
   */
  public fetch(): Observable<Config> {
    if (!this.config$) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.config$ = new ReplaySubject<Config>(1);
    }

    return this.api.commonGet<ConfigInterface>('/config').pipe(this.setConfig);
  }

  /**
   * Get the current config.
   *
   * @returns Gets the config info.
   */
  public get(): Observable<Config> {
    if (!this.config$) {
      this.fetch()
        .pipe(take(1))
        .subscribe();
    }
    return this.config$;
  }

  /**
   * Get a specific config via getter pipe
   *
   * @returns Gets specific config info.
   */
  public config<T>(getter: (config: Config) => T): Observable<T> {
    return this.get().pipe(map(getter));
  }

  public dashboardHeaderImage(): Observable<string> {
    return this.config(config => {
      if (config.dashboardHeaderImage) {
        return environment.api.baseUrl + '/' + config.dashboardHeaderImage;
      }
      return '';
    });
  }

  /**
   * Get the current config.
   *
   * @returns Gets the config info.
   */
  public get instant(): Config {
    return this._instant;
  }

  public get ngLocaleId(): string {
    const localeId = this.instant && this.instant.ngLocaleId;
    if (!localeId) {
      this.log.error('No ngLocaleId resolved from config, use default');
    }
    return localeId || 'en-GB';
  }

  public get trackingId(): string {
    const trackingId = this.instant && this.instant.trackingId;
    if (!trackingId) {
      this.log.info('No tracking ID resolved from config');
      return null;
    }
    return trackingId;
  }

  public get locale(): string {
    const locale = this.instant && this.instant.locale;
    if (!locale) {
      this.log.error('No locale resolved from config, use default');
    }
    return locale || 'en_GB';
  }

  public get language(): string {
    return this.locale.split('_')[0];
  }

  public get country(): string {
    return this.locale.split('_')[1];
  }

  public get userProfileHiddenAttributes(): string[] {
    const requirements = this.instant && this.instant.userProfileHiddenAttributes;
    if (!requirements) {
      this.log.error('No user profile hidden attributes resolved from config, use default');
    }
    return requirements || [];
  }

  public get userProfileRequirements(): string[] {
    const requirements = this.instant && this.instant.userProfileRequirements;
    if (!requirements) {
      this.log.error('No user profile requirements resolved from config, use default');
    }
    return requirements || ['salutation', 'firstName', 'lastName', 'email', 'confirmedNewsletter', 'confirmedContact'];
  }

  public get availableCountries(): string[] {
    const countries = this.instant && this.instant.availableCountries;
    if (!countries) {
      this.log.error('No countries for selection resolved from config, use default');
    }
    return countries || ['GB'];
  }

  public get availableLanguages(): string[] {
    const languages = this.instant && this.instant.availableLanguages;
    if (!languages) {
      this.log.error('No languages for selection resolved from config, use default');
    }
    return languages || ['en'];
  }

  public get dateFormat(): string {
    // moment locale is set at core module
    const dateFormat = moment.localeData().longDateFormat('L');
    if (!dateFormat) {
      this.log.error('No dateFormat resolved from config, use default');
    }
    return dateFormat || 'YYYY-MM-DD';
  }

  public get cookiebotId(): string {
    return environment.cookiebot.id;
  }
}
