import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, from } from 'rxjs';
import { distinctUntilChanged, finalize, map, switchMap, take} from 'rxjs/operators';
import { Logger } from '@core/logger';
import {ApiService, ConfigService, FormService, NavigationService, NotificationService, UserService} from '@services';
import { BaseComponent } from '@components';
import { UserCreate as CreateUserParameter, UserLogin as LoginUserParameter } from '@interfaces/user';
import { MustMatch, UserNamePattern } from '@validators';
import { accountCreateFormDefinition } from './account-create.form';

@Component({
  selector: 'idpo-account-create',
  host: {
    '[class.with-bg]': '!simpleForm'
  },
  templateUrl: './account-create.component.html',
  styleUrls: ['./account-create.component.scss']
})
export class AccountCreateComponent extends BaseComponent implements OnInit {
  @Input() simpleForm: boolean  = false;
  @Input() showSuccessMessage: boolean  = true;
  @Input() onRegister: CallableFunction;
  public form: FormGroup;
  public formDefinition = accountCreateFormDefinition;

  public isSubmitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected fb: FormBuilder,
    protected userService: UserService,
    protected notify: NotificationService,
    protected navi: NavigationService,
    protected formService: FormService,
    protected api: ApiService,
    protected log: Logger,
    protected config: ConfigService
  ) {
    super();

    this.createForm();
  }

  protected createForm() {
    this.form = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(UserNamePattern)]],
      lastName: ['', [Validators.required, Validators.pattern(UserNamePattern)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required, MustMatch('password')]],
      confirmedTermsConditions: [false, [Validators.requiredTrue]]
    });

    this.addSubscription(
      this.form.get('password').valueChanges.subscribe(() => {
        // This ensures re-validation of confirmation field if password is changing
        this.form.get('password_confirmation').updateValueAndValidity();
      })
    );
  }

  ngOnInit() {
    this.userService.get().subscribe((user) => {
      if (user) {
        if (user.isRegistered) {
          // disable register form for registered users
          this.isSubmitting$.next(true);
        } else {
          // pre-fill name in form for unregistered users
          this.form.get('firstName').setValue(user.firstName);
          this.form.get('lastName').setValue(user.lastName);
        }
      }
    });

    // Disable form while submitting
    this.addSubscription(
      this.isSubmitting$
        .pipe(distinctUntilChanged())
        .subscribe(isSubmitting => this.formService.isSubmittingHandler(isSubmitting, this.form))
    );
  }

  get isSubmittable(): boolean {
    return this.form.valid && this.form.dirty;
  }

  public submitAccountCreateForm(): void {
    if (this.isSubmittable) {
      const createUserParameter: CreateUserParameter = this.form.value;

      this.isSubmitting$.next(true);
      this.userService
        .create(createUserParameter)
        .pipe(
          take(1),
          finalize(() => this.isSubmitting$.next(false)),
          this.formService.handleApiValidationError(this.form),
          this.notify.handleGeneralError(),
          switchMap(() => {
            // registration success message with instructions to confirm mail address
            // users can also access DPO without confirmation, but need to confirm it
            if (this.showSuccessMessage) {
              this.notify.success({
                title: 'register.successNotification.header',
                content: 'register.successNotification.text',
                translate: true
              });
            }
            if (this.config.country == 'DE') {
              if (this.onRegister !== undefined) {
                return this.onRegister();
              } else {
                return this.navi.dashboard();
              }
            } else {
              return this.navi.login();
            }

            return null;
          })
        )
        .subscribe();
    }
  }

  public goBack(): void {
    this.navi.back();
  }

  openExternalTermsOfUse($event) {
    event.preventDefault();

    this.api.partyURL('/terms-of-use').subscribe(url => window.open(url));
  }
}
