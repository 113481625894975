import { Directive, Input, OnInit } from '@angular/core';
import { GalleryItem, Gallery, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';

export interface LightboxInput {
  gallery: GalleryItemInput[];
  index: number;
}

export interface GalleryItemInput {
  image: string;
  thumbnail?: string;
}

@Directive({
  selector: '[idpoLightbox]'
})
export class LightboxDirective implements OnInit {
  @Input('idpoLightbox') input: LightboxInput;
  items: GalleryItem[];

  constructor(protected gallery: Gallery) {}

  ngOnInit(): void {
    // 1. Create gallery items
    this.items = this.input.gallery.map(item => {
      if (item.hasOwnProperty('thumbnail')) {
        return new ImageItem({ src: item.image, thumb: item.thumbnail });
      }

      return new ImageItem({ src: item.image, thumb: item.image });
    });

    // Load items into the lightbox
    this.withCustomGalleryConfig();
  }

  withCustomGalleryConfig() {
    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref();

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Contain,
      counter: false,
      loadingMode: 'indeterminate',
      thumbPosition: ThumbnailsPosition.Left
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }
}
