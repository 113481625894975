import { Injectable } from '@angular/core';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { Party } from '@models/party';
import { Party as PartyInterface } from '@interfaces/party';
import { BehaviourService } from '@interfaces/behaviour-service';

@Injectable({
  providedIn: 'root'
})
export class PartyService implements BehaviourService<Party> {
  protected party$: ReplaySubject<Party>;

  /**
   * Pipe for creating model from interface and set next party
   */
  protected setParty = pipe(
    map<PartyInterface, Party>(partyInterface => new Party(partyInterface)),
    // Not set error to replay subject
    tap<Party>(party => this.party$.next(party))
  );

  constructor(protected api: ApiService) {}

  /**
   * Unset actual party to force new fetch
   */
  public unset() {
    if (this.party$) {
      this.party$.complete();
    }
    this.party$ = null;
  }

  /**
   * Fetch fresh party data
   */
  public fetch(): Observable<Party> {
    if (!this.party$) {
      // use ReplaySubject to simulate BehaviorSubject without initial value
      this.party$ = new ReplaySubject<Party>(1);
    }

    return this.api.get<PartyInterface>().pipe(this.setParty);
  }

  /**
   * Get Party
   */
  public get(): Observable<Party> {
    if (!this.party$) {
      this.fetch()
        .pipe(take(1))
        .subscribe();
    }
    return this.party$;
  }
}
