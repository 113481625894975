import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import { UserService } from '@services/user.service';
import { Logger } from '@app/core/logger';
import {catchError, finalize, switchMap} from 'rxjs/operators';
import { NavigationService } from '@services/navigation.service';
import {ConfigService, NotificationService, PartyIDService} from "@services";
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserPreparedForPartyGuard implements CanActivate, CanActivateChild {
  constructor(
      protected user: UserService,
      protected navi: NavigationService,
      protected log: Logger,
      protected config: ConfigService,
      protected partyIdService: PartyIDService,
      protected notify: NotificationService,
  ) {}

  protected isAccountReady(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.user.get().pipe(
      switchMap(user => {
        if (user.firstName === undefined && user.lastName === undefined) {
            return this.navi.join();
        }
        if (this.config.country != 'DE' && !user.confirmedAccount) {
          // Check if account is confirmed
          this.log.debug('Account not confirmed');
          return this.navi.accountUnconfirmedUrlTree;
        } else if (!user.confirmedTermsConditions) {
          // Check if TaC are confirmed
          this.log.debug('TaC not confirmed');
          return this.navi.userConfirmTaCUrlTree;
        } else if (!user.joinedParty) {
            if (environment.showJoinPartyPage) {
                // Check if user joined party
                this.log.debug('User not joined party');
                return this.navi.userJoinPartyUrlTree;
            } else {
                this.user
                    .join()
                    .pipe(
                        catchError(err => {
                            this.log.debug('Join party failed', err);
                            this.notify.error();
                            return EMPTY;
                        }),
                        switchMap(() => this.partyIdService.get()),
                        switchMap(() => this.navi.dashboard())
                    )
                    .subscribe();
                return;
            }
        }
        return of(true);
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAccountReady();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAccountReady();
  }
}
