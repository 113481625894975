import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, pipe, ReplaySubject } from 'rxjs';
import { exhaustMap, map, share, switchMap, take, tap } from 'rxjs/operators';
import * as UrlAssembler from 'url-assembler';
import { Logger } from '@core/logger';
import { ApiService } from './api.service';
import {
  ChatMessage,
  ChatMessagesResponse,
  CreateChatMessage,
  PartywallService as PartywallServiceInterface,
  UpdateChatMessage
} from '@interfaces';
import { Chat } from '@models/chat';

/**
 * Get the latest chat messages
 */

@Injectable({
  providedIn: 'root'
})
export class PartywallService implements PartywallServiceInterface {
  constructor(protected api: ApiService, protected log: Logger) {
    this.initUpdater();
  }

  protected get baseUrlSegment(): string {
    return '/chat';
  }
  /**
   * The timestamp from server of the last chat fetch
   */
  protected updatedAt: string = null;
  protected readAt: string = null;
  protected isReading = false;
  protected chat: Chat = new Chat([]);
  protected chat$: ReplaySubject<ChatMessage[]>;
  protected unread$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /**
   * Pipe for creating model from interface and set next chat
   */
  protected setChat = pipe(
    // Not set error on replay subject
    tap((data: ChatMessagesResponse | null) => {
      if (data === null) {
        return;
      }

      data = data as ChatMessagesResponse;

      // Remember last fetch time given from server
      this.updatedAt = data.time;
      this.readAt = data.readAt;
      // Sync chat history if data given
      if (data.created.length || data.updated.length || data.deleted.length) {
        this.chat.sync(data.created, data.updated, data.deleted);
        this.initChat();
        this.chat$.next(this.chat.messages);
      }
      this.unread$.next(this.chat.unreadMessages(this.readAt));
    }),
    map(() => this.chat.messages)
  );

  /**
   * Update interval for fetching messages
   */
  protected updater$: Observable<string>;

  private initChat() {
    if (!this.chat$) {
      // use ReplaySubject to simulate BehaviorSubject without initial value
      this.chat$ = new ReplaySubject<ChatMessage[]>(1);
    }
  }

  protected initUpdater() {
    this.updater$ = interval(10000).pipe(
      exhaustMap(() => this.fetch()),
      map(() => this.updatedAt),
      share()
    );
  }

  /**
   * Connect to chat messages.
   *
   * Chat messages automatically fetched via update interval
   * as long as at least one subscriber is connected.
   */
  public connect(): Observable<string> {
    this.get()
      .pipe(take(1))
      .subscribe();
    return this.updater$;
  }

  /**
   * Unset actual chat to force new fetch
   */
  public unset() {
    this.chat$ = null;
    this.unread$.next(0);
    this.updatedAt = null;
    this.readAt = null;
    this.chat = new Chat([]);
  }

  /**
   * Fetch new chat messages
   */
  public fetch(): Observable<ChatMessage[]> {
    this.initChat();

    const queryParams: { time?: string; isReading?: any } = {};

    if (this.updatedAt) {
      queryParams.time = this.updatedAt;
    }

    if (this.isReading) {
      queryParams.isReading = '';
    }

    return this.api
      .get<ChatMessagesResponse>((url: UrlAssembler) =>
        url
          .segment(this.baseUrlSegment)
          .segment('/messages')
          .query(queryParams)
      )
      .pipe(this.setChat);
  }

  public get(): Observable<ChatMessage[]> {
    if (!this.chat$) {
      this.fetch()
        .pipe(take(1))
        .subscribe();
    }
    return this.chat$;
  }

  /**
   * Get the last chat message
   */
  public getLast(): Observable<ChatMessage> {
    return this.get().pipe(map(chat => chat[chat.length - 1] || null));
  }

  public unread(): Observable<number> {
    return this.unread$;
  }

  public setIsReading(isReading: boolean) {
    this.isReading = isReading;
  }

  /**
   * Add a new chat message
   */
  public add(newMessage: string): Observable<ChatMessage[]> {
    const requestBody: CreateChatMessage = {
      message: newMessage
    };

    return this.api
      .post((url: UrlAssembler) => url.segment(this.baseUrlSegment).segment('/messages'), requestBody)
      .pipe(switchMap(() => this.fetch()));
  }

  /**
   * Edit an existing chat message
   */
  public edit(id: string, editedMessage: string): Observable<ChatMessage[]> {
    const requestBody: UpdateChatMessage = { message: editedMessage };
    return this.api
      .put(
        (url: UrlAssembler) =>
          url
            .segment(this.baseUrlSegment)
            .segment('/messages/:messageId')
            .param({ messageId: id }),
        requestBody
      )
      .pipe(switchMap(() => this.fetch()));
  }

  /**
   * Delete an existing chat message
   */
  public delete(id): Observable<ChatMessage[]> {
    return this.api
      .delete((url: UrlAssembler) =>
        url
          .segment(this.baseUrlSegment)
          .segment('/messages/:messageId')
          .param({ messageId: id })
      )
      .pipe(switchMap(() => this.fetch()));
  }
}
