import { HostessGiftOrder as HostessGiftOrderInterface } from '@interfaces';
import { OrderTypes, HostessGiftSelectionTypes } from '@enums';

export class HostessGiftOrder {
  protected _orderType: OrderTypes;

  protected _committed: boolean;
  protected _orderAmount: number;
  protected _currency: string;
  // protected _hostessGiftSelectionType: HostessGiftSelectionTypes;

  constructor(orderData: HostessGiftOrderInterface) {
    this.init(orderData);
  }

  init(orderData: HostessGiftOrderInterface): this {
    this._orderType = orderData.orderType as OrderTypes;
    this._committed = orderData.committed;
    this._orderAmount = orderData.orderAmount;
    this._currency = orderData.currency;
    // this._hostessGiftSelectionType = orderData.hostessGiftSelectionType;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */
  public get orderType(): OrderTypes {
    return this._orderType;
  }

  public get committed(): boolean {
    return this._committed;
  }

  public get orderAmount(): number {
    return this._orderAmount;
  }

  public get currency(): string {
    return this._currency;
  }

  // public get hostessGiftSelectionType(): HostessGiftSelectionTypes {
  //   return this._hostessGiftSelectionType;
  // }

  /*
  |--------------------------------
  | Calculated properties
  |--------------------------------
  */
}
