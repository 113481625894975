import { User as UserInterface, UserAsGuest } from '@interfaces/user';
import { Role } from '@app/shared/enums/role.enum';
import moment, { Moment } from 'moment';
import { Addressable } from '@interfaces/addressable';

export class User implements Addressable {
  protected _salutation: string;
  protected _firstName: string;
  protected _lastName: string;
  protected _street: string;
  protected _streetNo: string;
  protected _zip: string;
  protected _city: string;
  protected _countryCode: string;
  protected _email: string;
  protected _languageCode: string;
  protected _dateOfBirth: moment.Moment;
  protected _phone: string;
  protected _avatarImageName: string;
  protected _locale: string;
  protected _confirmedAccount: boolean;
  protected _confirmedContact: boolean;
  protected _confirmedTermsConditions: boolean;
  protected _confirmedNewsletter: boolean;
  protected _partyId: string;
  protected _joinedParty: boolean;
  protected _isHost: boolean;
  protected _orderedAmount: number;
  protected _asGuest: UserAsGuest;
  protected _isRegistered: boolean;

  constructor(userData: UserInterface) {
    this.init(userData);
  }

  init(userData: UserInterface): this {
    const momentOfBirth = moment(userData.dateOfBirth);
    const dateOfBirth = momentOfBirth.isValid() ? momentOfBirth : null;

    this._salutation = userData.salutation;
    this._firstName = userData.firstName;
    this._lastName = userData.lastName;
    this._street = userData.street;
    this._streetNo = userData.streetNo;
    this._zip = userData.zip;
    this._city = userData.city;
    this._countryCode = userData.countryCode;
    this._email = userData.email;
    this._languageCode = userData.languageCode;
    this._dateOfBirth = dateOfBirth;
    this._phone = userData.phone;
    this._avatarImageName = userData.avatarImageName;
    this._locale = userData.locale;
    this._confirmedAccount = userData.confirmedAccount;
    this._confirmedContact = userData.confirmedContact;
    this._confirmedTermsConditions = userData.confirmedTermsConditions;
    this._confirmedNewsletter = userData.confirmedNewsletter;
    this._partyId = userData.partyId;
    this._joinedParty = userData.joinedParty;
    this._isHost = userData.isHost;
    this._orderedAmount = userData.orderedAmount;
    this._asGuest = userData.asGuest;
    this._isRegistered = userData.isRegistered;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  get salutation(): string {
    return this._salutation;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }

  get street(): string {
    return this._street;
  }

  get streetNo(): string {
    return this._streetNo;
  }

  get zip(): string {
    return this._zip;
  }

  get city(): string {
    return this._city;
  }

  get countryCode(): string {
    return this._countryCode;
  }

  get email(): string {
    return this._email;
  }

  get languageCode(): string {
    return this._languageCode;
  }

  get dateOfBirth(): Moment {
    return this._dateOfBirth;
  }

  get phone(): string {
    return this._phone;
  }

  get avatarImageName(): string {
    return this._avatarImageName;
  }

  get locale(): string {
    return this._locale;
  }

  get confirmedAccount(): boolean {
    return this._confirmedAccount;
  }

  get confirmedContact(): boolean {
    return this._confirmedContact;
  }

  get confirmedTermsConditions(): boolean {
    return this._confirmedTermsConditions;
  }

  get confirmedNewsletter(): boolean {
    return this._confirmedNewsletter;
  }

  get partyId(): string {
    return this._partyId;
  }

  get joinedParty(): boolean {
    return this._joinedParty;
  }

  get isHost(): boolean {
    return this._isHost;
  }

  get orderedAmount(): number {
    return this._orderedAmount;
  }

  get asGuest(): UserAsGuest {
    return this._asGuest;
  }

  get isRegistered(): boolean {
    return this._isRegistered;
  }

  /*
  |--------------------------------
  | Calculated properties
  |--------------------------------
  */

  /**
   * Get role as enum
   */
  get role(): Role {
    return this._isHost ? Role.Host : Role.Guest;
  }

  /**
   * Get role name
   */
  get roleNameLowerCase(): 'host' | 'guest' | '' {
    const name = this.roleName.toLowerCase();
    return name === 'host' || name === 'guest' ? name : '';
  }

  /**
   * Get role name
   */
  get roleName(): 'Host' | 'Guest' {
    return this.role;
  }

  /**
   * Alias for avatarImageName
   */
  get avatar(): string {
    return this.avatarImageName;
  }
}
