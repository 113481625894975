import { APP_INITIALIZER, InjectionToken, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import moment from 'moment';
import { from, Observable } from 'rxjs';
import { take, switchMap, tap } from 'rxjs/operators';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { EmbedVideo } from 'ngx-embed-video';
import { environment } from '@environments/environment';
import {
  AvatarService,
  CartService,
  CatalogService,
  ConfigService,
  DealerAuthStorageService,
  DealerPartywallService,
  FormService,
  GuestsService,
  HostessGiftCartService,
  HostessGiftOrderService,
  HostessGiftProductService,
  HostessGiftProductSpecialsService,
  NotificationComponent,
  NavigationService,
  NotificationService,
  OrderService,
  OrderTimelineService,
  PartyIDService,
  PartyService,
  PartywallService,
  PaymentService,
  ProductDetailsService,
  ProductService,
  PromotionService,
  ScriptService,
  TranslationLoaderService,
  UserService,
  VideoService,
  WINDOW_PROVIDERS,
  WishlistService
} from '@services';
import {
  AuthGuard,
  DealerGuard,
  DipAlreadyAcceptedGuard,
  DoNotShowPopupOnReloadGuard,
  OrderCompletedGuard,
  OrderNotCompletedGuard,
  OrderPaymentGuard,
  OrderPeriodStartedAlreadyReadGuard,
  OrderShipmentGuard,
  OrderSummaryGuard,
  OrderPaymentHandlingGuard,
  PartyGuard,
  UserAlreadyConfirmedTacGuard,
  UserAlreadyJoinedPartyGuard,
  UserPreparedForPartyGuard
} from '@guards';
import {
  CartResolverService,
  GuestsResolverService,
  HostessGiftCartResolverService,
  HostessGiftOrderResolverService,
  HostessGiftProductsResolverService,
  HostessGiftProductsSpecialsResolverService,
  OrderResolverService,
  PartyResolverService,
  ProductsResolverService,
  UserResolverService,
  WishlistResolverService
} from '@resolvers';
import { FooterComponent } from '@core/footer';
import { NavbarComponent } from '@core/navbar';
import { Logger, LoggerModule } from '@core/logger';
import { throwIfAlreadyLoaded } from '@core/module-import-guard';
import { HttpModule } from '@app/http';
import { BehaviourService } from '@interfaces/behaviour-service';
import { SharedModule } from '@shared';
import {AccountModule} from "@app/account";

/**
 * Dynamically load locales
 * The magic webpack comments chunks the selected locales
 */
function localeInitializer(localeId: string): Observable<[any, any, any]> {
  const momentLocaleId = localeId.toLowerCase();
  return from<Promise<[any, any, any]>>(
    // prettier-ignore
    Promise.all([
      // For Windows devs: webpack doesn't create chunks from ng locale files with `\/` in webpackInclude & webpackExclude
      // as 'workaround' replace `\/` with `[\/\\]` in regEx - build will be complete again under Windows
      import(
        /* webpackInclude: /\/(en-GB|de|fr)\.js$/ */
        /* webpackExclude: /extra\// */
        /* webpackChunkName: "locales_[request]_" */
        `@angular/common/locales/${localeId}.js`
      ),
      import(
        /* webpackInclude: /\/(en-GB|de|fr)\.js$/ */
        /* webpackChunkName: "locales-extra_[request]_" */
        `@angular/common/locales/extra/${localeId}.js`
      ),
      import(
        /* webpackInclude: /\/(en-gb|de|fr)\.js$/ */
        /* webpackChunkName: "locales_moment_[request]_" */
        `moment/locale/${momentLocaleId}.js`
        )
    ])
  ).pipe(
    tap(([locale, extra, momentLocale]) => {
      registerLocaleData(locale.default, localeId, extra);
      moment.locale(momentLocaleId);
    })
  );
}

/**
 * Group all services that fetch and replay objects from API
 */
export const BehaviourServiceInterfaceToken = new InjectionToken<BehaviourService<any>>(
  'BehaviourServiceInterfaceToken'
);

@NgModule({
  declarations: [NotificationComponent, FooterComponent, NavbarComponent],
  exports: [NotificationComponent, TranslateModule, FooterComponent, NavbarComponent],
  imports: [
    CommonModule,
    LoggerModule.forRoot({level: environment.logger.level}),
    HttpModule,
    SimpleNotificationsModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoaderService
      }
    }),
    Angulartics2Module.forRoot(),
    EmbedVideo.forRoot(),
    SharedModule,
    AccountModule
  ],
  // Load config and locales dynamically before app starts
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configService => (): Promise<any> =>
        configService
          .fetch()
          .pipe(
            take(1),
            switchMap(() => localeInitializer(configService.ngLocaleId))
          )
          .toPromise(),
      deps: [ConfigService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: configService => configService.ngLocaleId,
      deps: [ConfigService]
    },
    WINDOW_PROVIDERS,
    TranslationLoaderService,
    Logger,
    PartyIDService,
    PartyService,
    UserService,
    GuestsService,
    NavigationService,
    FormService,
    NotificationService,
    AvatarService,
    ProductService,
    CartService,
    CatalogService,
    PromotionService,
    OrderTimelineService,
    OrderService,
    PaymentService,
    ConfigService,
    PartywallService,
    WishlistService,
    HostessGiftProductService,
    HostessGiftProductSpecialsService,
    HostessGiftCartService,
    HostessGiftOrderService,
    ProductDetailsService,
    ScriptService,
    VideoService,
    DealerAuthStorageService,
    DealerPartywallService,
    //
    { provide: BehaviourServiceInterfaceToken, useExisting: PartyService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: UserService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: GuestsService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: ProductService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: CartService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: OrderService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: PaymentService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: ConfigService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: PartywallService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: WishlistService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: CatalogService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: PromotionService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: HostessGiftProductService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: HostessGiftProductSpecialsService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: HostessGiftCartService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: HostessGiftOrderService, multi: true },
    { provide: BehaviourServiceInterfaceToken, useExisting: ProductDetailsService, multi: true },
    CartResolverService,
    GuestsResolverService,
    HostessGiftCartResolverService,
    HostessGiftOrderResolverService,
    HostessGiftProductsResolverService,
    HostessGiftProductsSpecialsResolverService,
    OrderResolverService,
    PartyResolverService,
    ProductsResolverService,
    UserResolverService,
    WishlistResolverService,
    AuthGuard,
    DealerGuard,
    DipAlreadyAcceptedGuard,
    DoNotShowPopupOnReloadGuard,
    PartyGuard,
    UserPreparedForPartyGuard,
    UserAlreadyJoinedPartyGuard,
    UserAlreadyConfirmedTacGuard,
    OrderShipmentGuard,
    OrderPaymentGuard,
    OrderPeriodStartedAlreadyReadGuard,
    OrderSummaryGuard,
    OrderPaymentHandlingGuard,
    OrderCompletedGuard,
    OrderNotCompletedGuard
  ]
})
/**
 * Core module collects single-use classes (single-use components, services, ...).
 * This core module is imported once when the app starts and is never import anywhere else.
 */
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
