import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, pipe, throwError } from 'rxjs';
import { Logger } from '../logger';
import { map, shareReplay, skipWhile, switchMap, take } from 'rxjs/operators';
import { OrderService } from '@services/order.service';
import { Order } from '@models/order';
import { CartService } from '@services/cart.service';
import { ConfigService } from '@services/config.service';
import { NotificationService } from '@services/notification.service';
import { NavigationService } from '@services/navigation.service';
import { AlreadyNotifiedError } from '@models';

export type DipType = 'first' | 'second';

@Injectable({
  providedIn: 'root'
})
export class DipService {
  protected hasDeclined$: BehaviorSubject<boolean>;
  protected needsDipConfirmation$: Observable<boolean>;

  protected mapToDip = map<Order, boolean>(order => order.confirmedDip);

  protected modalIsOpen = false;

  constructor(
    protected orderService: OrderService,
    protected cartService: CartService,
    protected configService: ConfigService,
    protected notify: NotificationService,
    protected navi: NavigationService,
    protected log: Logger
  ) {
    this.hasDeclined$ = new BehaviorSubject<boolean>(false);

    this.needsDipConfirmation$ = combineLatest(
      this.configService.config(config => config.isDipActive),
      this.orderService.get().pipe(this.mapToDip),
      this.hasDeclined$
    ).pipe(
      map(([isDipActive, hasConfirmed, hasDeclined]) => {
        if (isDipActive) {
          return hasDeclined || !hasConfirmed;
        }
        return false;
      }),
      shareReplay()
    );
  }

  /**
   * Confirm DIP
   */
  public confirm() {
    this.orderService
      .confirmDip()
      .pipe(
        take(1),
        this.mapToDip,
        this.notify.handleApiValidationError(),
        this.notify.handleGeneralError(),
        switchMap(success => this.closeDipModal(success))
      )
      .subscribe();
  }

  /**
   * Decline DIP
   */
  public decline() {
    this.hasDeclined$.next(true);

    this.navi
      .dashboard()
      .pipe(
        take(1),
        switchMap(success => this.closeDipModal(success))
      )
      .subscribe();
  }

  /**
   * Close DIP modal and pass value through
   */
  protected closeDipModal(passThrough) {
    return this.navi.closePopup().pipe(
      switchMap(() => {
        this.modalIsOpen = false;
        return of(passThrough);
      })
    );
  }

  /**
   * Open DIP modal and pass value through
   */
  protected openDipModal(passThrough) {
    if (this.modalIsOpen) {
      of(passThrough);
    }

    return this.navi.openDipPopup().pipe(
      switchMap(() => {
        this.modalIsOpen = true;
        return of(passThrough);
      })
    );
  }

  /**
   * Get pipe operator to check if cart could be filled due to DIP restrictions
   */
  public isCartAddable() {
    this.hasDeclined$.next(false);
    return pipe(
      switchMap(() =>
        combineLatest(
          this.configService.config(config => config.isDipActive),
          this.orderService.get().pipe(this.mapToDip),
          this.hasDeclined$
        ).pipe(
          switchMap(([isDipActive, hasConfirmed, hasDeclined]) => {
            if (hasDeclined) {
              return throwError(new AlreadyNotifiedError('DIP declined'));
            }

            if (isDipActive) {
              return of(!hasConfirmed);
            }

            return of(false);
          })
        )
      ),
      switchMap(needsDipConfirmation =>
        needsDipConfirmation ? this.openDipModal(needsDipConfirmation) : of(needsDipConfirmation)
      ),
      skipWhile(needsDipConfirmation => needsDipConfirmation),
      take(1)
    );
  }
}
