import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '@core/logger';
import { environment } from '@environments/environment';
import { NavigationService } from '@services/navigation.service';

@Injectable()
export class AuthUserInterceptor implements HttpInterceptor {
  constructor(protected log: Logger, protected navi: NavigationService) {}

  protected partyId: string | null;

  /**
   * Check if it is an API request that needs authentication
   */
  protected requestNeedsAuthentication(request: HttpRequest<any>): boolean {
    let url = request.url;

    if (!url.startsWith(environment.api.baseUrl)) {
      return false;
    }

    // remove base url
    url = url.replace(environment.api.baseUrl, '');

    // not starts with /party/{partyId} ?
    const partyURLRegEx: RegExp = /^\/?partys\/[^\/]+\/?/i;
    if (!partyURLRegEx.test(url)) {
      return false;
    }

    // Workaround for not having partyId at custom NavigationService
    const partyIdMatch = url.match(/^\/?partys\/([^\/]+)[\/\?]?/i);
    this.partyId = partyIdMatch.length >= 2 ? partyIdMatch[1] : null;

    // remove party url
    url = url.replace(partyURLRegEx, '');
    // remove get params
    url = url.replace(/\?+$/i, '');

    const nonAuthenticated = {
      GET: {
        getParty: /^$/i
      },
      POST: {
        createUser: /^user/i,
        login: /^user\/login/i,
        logout: /^user\/logout/i,
        forgotPassword: /^user\/password\/forgot/i
      },
      PUT: {
        resetPassword: /^user\/password\/reset/i,
        verifyUser: /^user\/verify/i
      }
    };

    if (nonAuthenticated.hasOwnProperty(request.method)) {
      const routes = nonAuthenticated[request.method];

      for (const routeName in routes) {
        if (routes.hasOwnProperty(routeName)) {
          const routeRegEx: RegExp = routes[routeName];
          if (routeRegEx.test(url)) {
            return false;
          }
        }
      }
    }

    return true;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestNeedsAuthentication = this.requestNeedsAuthentication(request);

    // Skip requests that need no authentication
    if (!requestNeedsAuthentication) {
      return next.handle(request.clone());
    }

    // Send request 401 catcher.
    return next.handle(request).pipe(
      catchError((error: any) => {
        // Just interested in HTTP errors
        if (error instanceof HttpErrorResponse) {
          // Just handle non authenticated
          switch (error.status) {
            case 401:
              return this.handleUnauthorized(request, next);
          }
        }
        return throwError(error);
      })
    );
  }

  /**
   * Handle unauthorized requests.
   */
  protected handleUnauthorized(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.partyId) {
      // If app entry is unauthorized, then ActivatedRoute is not yet set, so we need the window object/service
      const location = this.navi.windowLocation;
      const redirectTo = location.pathname + location.search;
      this.navi.join(this.partyId, redirectTo).subscribe();
    } else {
      this.log.warn('AuthUserInterceptor no partyID', request.url);
      this.navi.generalError().subscribe();
    }
    return EMPTY;
  }
}
