import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { OrderService, NavigationService } from '../services';
import { Logger } from '../logger';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DipAlreadyAcceptedGuard implements CanActivate {
  constructor(protected order: OrderService, protected navi: NavigationService, protected log: Logger) {}

  protected hasAlreadyAcceptedDip(): Observable<boolean | UrlTree> {
    return this.order.get().pipe(
      switchMap(order => {
        if (order.confirmedDip) {
          // Check if dip is confirmed
          this.log.debug('DIP already confirmed');
          return of(false);
        }
        return of(true);
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAlreadyAcceptedDip();
  }
}
