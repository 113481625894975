import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/user.service';
import { PasswordReset } from '@interfaces/user';
import formData from '@app/account/password/reset/password-reset.form';
import { FormService } from '@services/form.service';
import { NavigationService } from '@services/navigation.service';
import { Logger } from '@core/logger';
import { BaseComponent } from '@components/base.component';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { MustMatch } from '@validators/must-match.validator';

@Component({
  selector: 'idpo-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent extends BaseComponent implements OnInit {
  public passwordResetForm: FormGroup;
  public passwordResetFormDefinition = formData;
  public isSubmitting$ = new BehaviorSubject<boolean>(false);

  protected referenceToken;
  protected token;

  constructor(
    protected fb: FormBuilder,
    protected userService: UserService,
    protected formService: FormService,
    protected notify: NotificationService,
    protected navi: NavigationService,
    protected log: Logger,
    protected route: ActivatedRoute
  ) {
    super();

    this.createPasswordResetForm();
  }

  protected createPasswordResetForm() {
    this.passwordResetForm = this.fb.group({
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required, MustMatch('password')]]
    });

    this.addSubscription(
      this.passwordResetForm.get('password').valueChanges.subscribe(() => {
        // This ensures re-validation of confirmation field if password is changing
        this.passwordResetForm.get('password_confirmation').updateValueAndValidity();
      })
    );
  }

  public ngOnInit(): void {
    this.addSubscription(
      this.route.paramMap.subscribe(paramMap => {
        this.referenceToken = paramMap.get('referenceToken');
      }),
      this.route.queryParamMap.subscribe(queryParamMap => {
        this.token = queryParamMap.get('token');
      })
    );
    // Disable form while submitting
    this.addSubscription(
      this.isSubmitting$
        .pipe(distinctUntilChanged())
        .subscribe(isSubmitting => this.formService.isSubmittingHandler(isSubmitting, this.passwordResetForm))
    );
  }

  public submitPasswordResetForm(): void {
    if (this.isSubmittable) {
      const parameter: PasswordReset = {
        password: this.passwordResetForm.value.password,
        password_confirmation: this.passwordResetForm.value.password_confirmation,
        referenceToken: this.referenceToken,
        token: this.token
      };
      this.isSubmitting$.next(true);
      this.userService
        .resetPassword(parameter)
        .pipe(
          finalize(() => this.isSubmitting$.next(false)),
          this.formService.handleApiValidationError(this.passwordResetForm),
          this.notify.handleGeneralError(),
          switchMap(() => this.navi.thankYouPasswordReset())
        )
        .subscribe();
    }
  }

  get isSubmittable(): boolean {
    return this.passwordResetForm.valid && this.passwordResetForm.dirty;
  }

  public goBack(): void {
    this.navi.back();
  }
}
