import { LoginFormElement } from './login.form.interface';

// tslint:disable:max-line-length
export const loginFormDefinition: LoginFormElement[] = [
  {
    id: 'email',
    placeholder: 'forms.email',
    type: 'email',
    showIcon: {
      eye: false
    },
    errors: {
      required: 'forms.errors.emailRequired',
      email: 'forms.errors.emailInvalidFormat'
    }
  },
  {
    id: 'password',
    placeholder: 'forms.password',
    type: 'password',
    showIcon: {
      eye: true
    },
    errors: {
      required: 'forms.errors.passwordRequired'
    }
  }
];
