import { Component, OnInit } from '@angular/core';
import { PartyService, NavigationService, PartyIDService } from '@app/core/services';
import { switchMap, take } from 'rxjs/operators';
import { Logger } from '@app/core/logger';

@Component({
  selector: 'idpo-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  public isLoading = true;

  constructor(
    protected party: PartyService,
    protected partyIdService: PartyIDService,
    protected navi: NavigationService,
    protected log: Logger
  ) {}

  ngOnInit() {
    this.partyIdService
      .get(true)
      .pipe(
        switchMap(partyId => {
          this.log.debug('Default Component', partyId);
          if (partyId) {
            return this.navi.dashboard();
          } else {
            return this.navi.partyNotFound();
          }
        }),
        take(1)
      )
      .subscribe();
  }
}
