import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@core/logger';
import { map, tap } from 'rxjs/operators';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { AvatarService } from '@services';
import { PartywallBaseComponent } from '../partywall-base.component';
import { Dealer, Party, User } from '@models';
import { Avatar, ChatMessage, Guest, HeaderContent, MentionGuest } from '@interfaces';

@Component({
  selector: 'idpo-partywall-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent extends PartywallBaseComponent implements OnInit, OnDestroy {
  public headerContent: HeaderContent = {
    header: 'partywall.header',
    subTitle: 'partywall.subTitle'
  };

  protected guests$ = new ReplaySubject<Guest[]>(1);
  /**
   * The guests of the party
   */
  @Input()
  public set guests(guests: Guest[]) {
    this.guests$.next(guests);
  }

  protected party$ = new ReplaySubject<Party>(1);
  /**
   * The guests of the party
   */
  @Input()
  public set party(party: Party) {
    this.party$.next(party);
  }

  /**
   * The guests of the party
   */
  @Input()
  public user$: Observable<User | Dealer>;

  /**
   * The scrollable chat container
   */
  @ViewChild('scrollContainer')
  protected scrollContainer: ElementRef;

  /**
   * The chat message observable
   */
  public chatMessages$: Observable<ChatMessage[]>;

  public mentionItems$: Observable<MentionGuest[]>;

  constructor(
    protected log: Logger,
    protected route: ActivatedRoute,
    protected translate: TranslateService,
    protected avatarService: AvatarService
  ) {
    super();
  }

  public ngOnInit(): void {
    // Set chat message observable
    this.chatMessages$ = this.partywallService.get().pipe(
      tap(() => {
        this.scrollToBottomAfterContentWasRendered();
      })
    );

    // Set reading mode and fetch latest;
    this.partywallService.setIsReading(true);
    this.partywallService.fetch().subscribe();

    this.mentionItems$ = combineLatest(
      this.guests$.pipe(
        map(guests => {
          if (!guests) {
            return [];
          }
          return guests.map(guest => ({
            label: guest.firstName + ' ' + guest.lastName.charAt(0) + '.',
            mentionName: guest.firstName + guest.lastName.charAt(0)
          }));
        })
      ),
      this.party$.pipe(
        map(party => {
          if (!party) {
            return [];
          }
          const dealer = party.dealer;
          return [
            {
              label: dealer.firstName + ' ' + dealer.lastName.charAt(0) + '.',
              mentionName: dealer.firstName + dealer.lastName.charAt(0)
            }
          ];
        })
      )
    ).pipe(map(([guests, dealer]) => guests.concat(dealer)));
  }

  public ngOnDestroy() {
    // Leave reading mode
    this.partywallService.setIsReading(false);
    super.ngOnDestroy();
  }

  /**
   * Get scroll element via query selector and simplebar default class
   *
   * Using SimpleBar class/constructor crashes at IE11, because of Destructuring operator
   */
  protected scrollElement(): any {
    return this.scrollContainer ? this.scrollContainer.nativeElement.querySelector(`.simplebar-content`) : null;
  }

  /**
   * Get chat dealer avatar
   */
  public avatar(user: User | Dealer): Avatar {
    return this.avatarService.getAvatar(user.avatarImageName || 'avatar_blank_female.svg');
  }

  /**
   * Scroll chat container to bottom
   */
  private scrollToBottom() {
    const scrollElement = this.scrollElement();

    if (this.scrollContainer && scrollElement) {
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }

  /**
   * Scroll chat container to bottom after
   */
  private scrollToBottomAfterContentWasRendered() {
    const scrollElement = this.scrollElement();

    if (scrollElement) {
      const bottomScrollPosition = scrollElement.scrollHeight - scrollElement.offsetHeight;

      if (scrollElement.scrollTop !== bottomScrollPosition) {
        // not scroll down to new message if the user has scrolled up
        return;
      }
    }

    setTimeout(() => {
      this.scrollToBottom();
    }, 300);
  }
}
