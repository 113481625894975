import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigService, NavigationService } from '@services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookiesGuard implements CanActivate {
  constructor(protected config: ConfigService, protected navi: NavigationService) {}

  protected cookiebotAvailable(): boolean {
    return this.config.cookiebotId != null;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.cookiebotAvailable()) {
      this.navi.dashboard().subscribe();

      return false;
    }

    return true;
  }
}
