import { Observable } from 'rxjs';
import { BaseComponent } from '@components/base.component';
import { Party } from '@models/party';
import { PartyService } from '@services/party.service';

export abstract class PartyBaseComponent extends BaseComponent {
  public get party$(): Observable<Party> {
    return this.partyService.get();
  }

  protected constructor(protected partyService: PartyService) {
    super();
  }
}
