import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Logger } from '@core/logger';
import {interval, Observable, of, pipe, ReplaySubject, Subscription} from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { PaymentOptions, ShipmentOptions } from '@enums';
import { Order } from '@models';
import { Order as OrderInterface, OrderService as OrderServiceInterface } from '@interfaces';
import {NavigationService} from "@services/navigation.service";

@Injectable({
  providedIn: 'root'
})
export class OrderService implements OrderServiceInterface {
  constructor(protected api: ApiService,
              protected log: Logger,
              protected navi: NavigationService) {}
  protected order$: ReplaySubject<Order>;

  untilCompletedSubscription : Subscription;

  /**
   * Pipe for creating model from interface and set next order
   */
  protected setOrder = pipe(
    map<OrderInterface, Order>(orderInterface => new Order(orderInterface)),
    map(order => {
      if (order.committed && !order.completed && order.needsPayment && !this.untilCompletedSubscription) {
        // check if order is still not payed, else reload
        this.untilCompletedSubscription = interval(10000).subscribe(val => {
          this.fetch().subscribe(order => {
            if (!order.committed || order.completed) {
              this.navi.orderCompleted().subscribe(() => location.reload());
              this.untilCompletedSubscription.unsubscribe();
            }
          })
        })
      } else if (!order.needsPayment && this.untilCompletedSubscription) {
        // stop waiting for successful payment when user changes payment option after failed payment
        this.untilCompletedSubscription.unsubscribe();
      }
      return order;
    }),
    // Not set error to replay subject
    tap<Order>(order => this.order$.next(order))
  );

  /**
   * Pipe for creating model from interface and set next order
   */
  protected setOrderAndGetActual = pipe(
    this.setOrder,
    switchMap(() => this.get()),
    take(1)
  );

  /**
   * Unset actual order to force new fetch
   */
  public unset() {
    if (this.order$) {
      this.order$.complete();
    }

    this.order$ = null;
  }

  /**
   * Fetch the current order from API.
   */
  public fetch(): Observable<Order> {
    if (!this.order$) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.order$ = new ReplaySubject<Order>(1);
    }

    return this.api.get<OrderInterface>('/order').pipe(this.setOrder);
  }

  /**
   * Get the current order.
   *
   * @returns Gets the order info.
   */
  public get(): Observable<Order> {
    if (!this.order$) {
      this.fetch()
        .pipe(take(1))
        .subscribe();
    }
    return this.order$;
  }

  /**
   * Gets all orders.
   *
   * @returns Gets the order info.
   */
  public getAll(): Observable<Order[]> {
    return this.api.get<Order[]>('/order/all');
  }

  /**
   * Confirm first DIP and set actual order.
   *
   * @return The actual order state observable
   */
  public confirmDip(): Observable<Order> {
    return this.api.put<OrderInterface>('/order/dip/confirm', {}).pipe(this.setOrderAndGetActual);
  }

  /**
   * Set shipment options
   *
   * @return The actual order state observable
   */
  public setShipment(shipment: ShipmentOptions): Observable<Order> {
    const parameter = { shipment };
    return this.api.put<OrderInterface>('/order/shipment', parameter).pipe(this.setOrderAndGetActual);
  }

  /**
   * Set payment options
   *
   * @return The actual order state observable
   */
  public setPayment(payment: PaymentOptions): Observable<Order> {
    const parameter = { payment };
    return this.api.put<OrderInterface>('/order/payment', parameter).pipe(this.setOrderAndGetActual);
  }

  /**
   * Commits the order
   *
   * @return The actual order state observable
   */
  public commit(): Observable<Order> {
    return this.api.put<OrderInterface>('/order/commit', {}).pipe(this.setOrderAndGetActual);
  }

  /**
   * get shipment options
   *
   * @return The actual shipment option
   */
  public shipment(): Observable<ShipmentOptions | null> {
    return this.get().pipe(map(order => order.shipment));
  }

  /**
   * Get payment options
   *
   * @return The actual payment option
   */
  public payment(): Observable<PaymentOptions | null> {
    return this.get().pipe(map(order => order.payment));
  }

  /**
   * States if order has been committed
   *
   * @return States if order has been committed
   */
  public isCommitted(): Observable<boolean> {
    return this.get().pipe(map(order => order.committed));
  }

  /**
   * States if order has been payed
   *
   * @return States if order has been payed
   */
  public isPayed(): Observable<boolean> {
    return this.get().pipe(map(order => order.payed));
  }

  /**
   * States if order handles payment
   *
   * @return States if order handles payment
   */
  public handlesPayment(): Observable<boolean> {
    return this.get().pipe(map(order => order.handlesPayment));
  }

  /**
   * States if order needs payment
   *
   * @return States if order handles payment
   */
  public needsPayment(): Observable<boolean> {
    return this.get().pipe(map(order => order.needsPayment));
  }

  /**
   * States if order has been completed
   *
   * @return States if order has been completed
   */
  public isCompleted(): Observable<boolean> {
    return this.get().pipe(map(order => order.completed));
  }

  ngOnDestroy() {
    if (this.untilCompletedSubscription) {
      this.untilCompletedSubscription.unsubscribe();
    }
  }
}
