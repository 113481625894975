import { Injectable } from '@angular/core';
import { Logger } from '@core/logger';
import { ApiService } from './api.service';
import { CartService } from './cart.service';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root'
})
export class HostessGiftCartService extends CartService {
  protected get baseUrlSegment(): string {
    return '/hostess-gift/cart';
  }

  // TODO: We need HostessGiftOrderService here -> order service interface
  constructor(api: ApiService, log: Logger, order: OrderService) {
    super(api, log, order);
  }
}
