import { Injectable } from '@angular/core';
import { Logger } from '@core/logger';
import { FormGroup } from '@angular/forms';
import { ApiValidationError } from '@models/api-validation-error.model';
import { EMPTY, Observable, OperatorFunction, pipe, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(protected log: Logger) {}

  /**
   * Handles API Validation errors in pipe
   *
   * @param form The submitted form
   */
  public handleApiValidationError<T>(form: FormGroup): OperatorFunction<T, T | never> {
    return pipe(
      catchError<T, Observable<never>>(error => {
        if (error instanceof ApiValidationError) {
          // Just handle non empty parameter errors
          if (error.hasParameterErrors) {
            this.setApiValidationError(error, form);
            return EMPTY;
          }
        }
        // rethrow error
        return throwError(error);
      })
    );
  }

  /**
   * Sets the API errors to controls of form group
   *
   * @param error The error after submitting form
   * @param form The submitted form
   */
  public setApiValidationError(error: ApiValidationError, form: FormGroup) {
    // enable form if its not already enabled
    if (!form.enabled) {
      form.enable();
    }

    for (const field in error.parameterErrors) {
      if (error.parameterErrors[field]) {
        const control = form.get(field);
        if (control) {
          control.setErrors({ api: error.parameterErrors[field] });
        } else {
          this.log.debug(`[FormService::setApiValidationError]: No corresponding control with name: ${field}`);
        }
      }
    }
  }

  /**
   * Handles form behaviour while form is submitting.
   */
  public isSubmittingHandler(isSubmitting: boolean, form: FormGroup) {
    if (isSubmitting) {
      if (form.enabled) {
        form.disable({ onlySelf: true, emitEvent: false });
      }
    } else {
      if (!form.enabled) {
        form.enable({ onlySelf: true, emitEvent: false });
      }
    }
  }
}
