import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Logger } from '@core/logger';

@Injectable()
export class OptionsInterceptor implements HttpInterceptor {
  constructor(protected log: Logger) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestWithOptions = request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }),
      withCredentials: true
    });

    return next.handle(requestWithOptions);
  }
}
