import { Component, Input } from '@angular/core';
import { AvatarService } from '@services/avatar.service';
import { Role } from '@enums/role.enum';
import { Guest } from '@interfaces/guest';
import { Party } from '@models/party';
import { Logger } from '@core/logger';
import { User } from '@models/user';
import { Avatar } from '@interfaces/user';
import { NavigationService } from '@app/core/services';

@Component({
  selector: 'idpo-party-guests-card',
  templateUrl: './party-guests-card.component.html',
  styleUrls: ['./party-guests-card.component.scss']
})
export class PartyGuestsCardComponent {
  public header;

  @Input()
  public dashboard = false;
  @Input()
  public user: User;
  @Input()
  public party: Party;

  protected _guests: Guest[] = [];

  @Input()
  public set guests(guests: Guest[]) {
    if (guests) {
      // Sort so that hosts are on top
      this._guests = guests.sort((a, b) => {
        if (a.isHost === b.isHost) {
          return 0;
        } else {
          return a.isHost ? -1 : 1;
        }
      });
    } else {
      this._guests = [];
    }
  }

  public get guests(): Guest[] {
    return this._guests;
  }

  public get viewAsHost(): boolean {
    return this.user && this.user.role === Role.Host;
  }

  constructor(protected avatarService: AvatarService, protected log: Logger, protected navi: NavigationService) {}

  public guestAvatar(guest: Guest): Avatar {
    return this.avatarService.getAvatar(guest.avatarImageName);
  }

  public get totalGuestSales(): number {
    let total = 0;
    this.guests.forEach(guest => (total += guest.orderedAmount));
    return total;
  }

  public goToMyParty(): void {
    this.navi.myParty().subscribe();
  }
}
