import { Component, Input } from '@angular/core';
import { Product } from '@models';
import {TranslateService} from "@ngx-translate/core";
import {Label} from "@models/label";

export type HidableProductInfo = 'salesCode' | 'price' | 'description';
export type ProductInfoPrice = 'retailPrice' | 'starAmount';

@Component({
  selector: 'idpo-product-info-tile',
  templateUrl: './product-info-tile.component.html',
  styleUrls: ['./product-info-tile.component.scss']
})
export class ProductInfoTileComponent {
  @Input()
  public product: Product;

  @Input()
  public hideInfo: HidableProductInfo[] = [];

  @Input()
  public productPrice: ProductInfoPrice = 'retailPrice';

  @Input()
  public showOverlayImage: boolean;

  constructor(
      protected translate: TranslateService
  ) {}

  public shouldHideInfo(info): boolean {
    return this.hideInfo.indexOf(info) >= 0;
  }
}
