import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MentionModule } from 'angular-mentions';
// Directives
import {
  AttachVideoDirective,
  AutocloseMenuDirective,
  CalentimDirective,
  CopyToClipboardDirective,
  IconDirective,
  ImageDirective,
  LightboxDirective,
  OpenOnClickDirective,
  TogglePasswordViewDirective
} from '@directives';
// Pipes
import {
  AddressLinesPipe,
  ConvertToInitialPipe,
  Email2linkPipe,
  EuroCurrencyPipe,
  Nl2brPipe,
  Phone2linkPipe,
  ReplaceByTagsPipe,
  SafeUrlPipe,
  Url2linkPipe,
  HighlightMentionPipe
} from '@pipes';
// Components
import {
  // Default
  DefaultComponent,
  // DIP
  DipComponent,
  // Error
  ErrorComponent,
  ForbiddenErrorComponent,
  GeneralErrorComponent,
  MaintenanceErrorComponent,
  NotFoundErrorComponent,
  ServicePlatformErrorComponent,
  // Header
  HeaderComponent,
  // Layout
  BoxLayoutComponent,
  ConfirmBoxComponent,
  FloatingButtonComponent,
  ModalComponent,
  PopupComponent,
  DropdownComponent,
  SpinnerComponent,
  AddRemoveButtonComponent,
  // Order Period Started
  OrderPeriodStartedComponent,
  // Party
  CountdownComponent,
  InvitationOutdatedComponent,
  PartyContactCardComponent,
  PartyDetailsComponent,
  PartyGuestsCardComponent,
  PartyInfoCardComponent,
  PartyNotFoundComponent,
  // Partywall
  MessageComponent as PartywallMessageComponent,
  EditorComponent as PartywallEditorComponent,
  PanelComponent as PartywallPanelComponent,
  // Products
  ProductInfoTileComponent,
  ProductFlagsComponent
} from '@components';
import {ProfileFormFieldComponent} from "@shared/forms/profile-form-field/profile-form-field.component";

@NgModule({
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormsModule, SwiperModule, MentionModule],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MentionModule,
    // Components
    BoxLayoutComponent,
    CountdownComponent,
    DefaultComponent,
    ErrorComponent,
    ForbiddenErrorComponent,
    GeneralErrorComponent,
    HeaderComponent,
    InvitationOutdatedComponent,
    MaintenanceErrorComponent,
    ModalComponent,
    NotFoundErrorComponent,
    PartyDetailsComponent,
    PartyInfoCardComponent,
    PartyContactCardComponent,
    PartyGuestsCardComponent,
    PartyNotFoundComponent,
    PopupComponent,
    DropdownComponent,
    PartywallMessageComponent,
    PartywallEditorComponent,
    PartywallPanelComponent,
    ProductInfoTileComponent,
    ProductFlagsComponent,
    ServicePlatformErrorComponent,
    FloatingButtonComponent,
    SpinnerComponent,
    DipComponent,
    ConfirmBoxComponent,
    OrderPeriodStartedComponent,
    AddRemoveButtonComponent,
    // Directives
    AutocloseMenuDirective,
    ImageDirective,
    IconDirective,
    OpenOnClickDirective,
    TogglePasswordViewDirective,
    CopyToClipboardDirective,
    CalentimDirective,
    LightboxDirective,
    AttachVideoDirective,
    // Pipes
    EuroCurrencyPipe,
    ReplaceByTagsPipe,
    Nl2brPipe,
    Url2linkPipe,
    ConvertToInitialPipe,
    AddressLinesPipe,
    HighlightMentionPipe,
    SafeUrlPipe,
    Phone2linkPipe,
    Email2linkPipe,
    DropdownComponent,
    ProfileFormFieldComponent
  ],
  declarations: [
    // Components
    BoxLayoutComponent,
    CountdownComponent,
    DefaultComponent,
    ErrorComponent,
    ForbiddenErrorComponent,
    GeneralErrorComponent,
    HeaderComponent,
    InvitationOutdatedComponent,
    MaintenanceErrorComponent,
    ModalComponent,
    NotFoundErrorComponent,
    PartyDetailsComponent,
    PartyInfoCardComponent,
    PartyContactCardComponent,
    PartyGuestsCardComponent,
    PartyNotFoundComponent,
    PopupComponent,
    DropdownComponent,
    PartywallMessageComponent,
    PartywallEditorComponent,
    PartywallPanelComponent,
    ProductInfoTileComponent,
    ProductFlagsComponent,
    ServicePlatformErrorComponent,
    DipComponent,
    SpinnerComponent,
    FloatingButtonComponent,
    ConfirmBoxComponent,
    OrderPeriodStartedComponent,
    AddRemoveButtonComponent,
    // Directives
    AutocloseMenuDirective,
    ImageDirective,
    IconDirective,
    OpenOnClickDirective,
    TogglePasswordViewDirective,
    CopyToClipboardDirective,
    CalentimDirective,
    LightboxDirective,
    AttachVideoDirective,
    // Pipes
    EuroCurrencyPipe,
    ReplaceByTagsPipe,
    Nl2brPipe,
    Url2linkPipe,
    ConvertToInitialPipe,
    AddressLinesPipe,
    SafeUrlPipe,
    Phone2linkPipe,
    Email2linkPipe,
    HighlightMentionPipe,
    DropdownComponent,
    ProfileFormFieldComponent
  ]
})
/**
 * SharedModule holds the common components, directives, and pipes and share them with the modules.
 * It also re-exports commonly used modules.
 */
export class SharedModule {}
