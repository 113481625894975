import { Component } from '@angular/core';

@Component({
  template: `
    <idpo-error
      [title]="'general.error.server.title' | translate"
      [message]="'general.error.server.text' | translate"
      [showErrorStack]="true"
    ></idpo-error>
  `,
  styleUrls: ['./error.pages.scss']
})
export class GeneralErrorComponent {}

@Component({
  template: `
    <idpo-error
      [title]="'general.error.servicePlatform.title' | translate"
      [message]="'general.error.servicePlatform.text' | translate"
      [showErrorStack]="true"
    ></idpo-error>
  `,
  styleUrls: ['./error.pages.scss']
})
export class ServicePlatformErrorComponent {}

@Component({
  template: `
    <idpo-error
      [title]="'general.error.maintenance.title' | translate"
      [message]="'general.error.maintenance.text' | translate"
    ></idpo-error>
  `,
  styleUrls: ['./error.pages.scss']
})
export class MaintenanceErrorComponent {}

@Component({
  template: `
    <idpo-error
      [title]="'general.error.notFound.title' | translate"
      [message]="'general.error.notFound.text' | translate"
    ></idpo-error>
  `,
  styleUrls: ['./error.pages.scss']
})
export class NotFoundErrorComponent {}

@Component({
  template: `
    <idpo-error
      [title]="'general.error.forbidden.title' | translate"
      [message]="'general.error.forbidden.text' | translate"
    ></idpo-error>
  `,
  styleUrls: ['./error.pages.scss']
})
export class ForbiddenErrorComponent {}
