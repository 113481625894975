import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment, { Moment } from 'moment';
import { ConfigService } from '@services';
import { ProfileFormElement } from '../profile.form.interface';

@Component({
  selector: 'idpo-profile-form-field',
  templateUrl: './profile-form-field.component.html',
  styleUrls: ['./profile-form-field.component.scss']
})
export class ProfileFormFieldComponent {
  @Input() public userProfileForm: FormGroup;
  @Input() public field: ProfileFormElement;

  constructor(protected config: ConfigService) {}

  protected get control() {
    return this.userProfileForm.get(this.field.id);
  }

  public dateChanged(date) {
    this.setLocalizedDate(date);
  }

  // Resync input field and form object after calentim autoreformat (if neccessary)
  public checkDateValue(fieldId) {
    const input = (<HTMLInputElement>document.getElementById(fieldId)).value;

    if (this.userProfileForm.get(fieldId).value !== input) {
      const momentOfBirth = moment(input, this.config.dateFormat);
      this.setLocalizedDate(momentOfBirth);
    }
  }

  protected setLocalizedDate(date: Moment) {
    this.control.setValue(date.isValid() ? date.format(this.config.dateFormat) : null);
  }
}
