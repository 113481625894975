import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { BehaviorSubject, pipe } from 'rxjs';
import { Logger } from '@core/logger';
import { FormService, NotificationService } from '@services';
import { ChatMessage, MentionGuest } from '@interfaces';
import { PartywallBaseComponent } from '../partywall-base.component';

type EditorFormatterType = 'bold' | 'italic';
type EditorFormattersType = { [key in EditorFormatterType]: FormatterDetails };

interface FormatterDetails {
  symbol: string;
}

interface EditorSelect {
  start: number;
  end: number;
}

const EditorFormatters: EditorFormattersType = {
  bold: {
    symbol: '*'
  },
  italic: {
    symbol: '_'
  }
};

type EditorInputTpye = HTMLTextAreaElement | HTMLInputElement;

@Component({
  selector: 'idpo-partywall-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent extends PartywallBaseComponent implements OnInit {
  @Input()
  public mentionItems: MentionGuest[] = [];

  /**
   * Reference to the message's text area field.
   */
  @ViewChild('message')
  public textArea: ElementRef<EditorInputTpye>;

  /**
   * The form group with the message's text area field
   */
  public form: FormGroup;

  /**
   * The chat message to edit.
   * If none given, a new message is created
   */
  @Input()
  public message: ChatMessage = null;

  /**
   * Submitted is only firing on success with value true
   */
  @Output()
  public submitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Observable that states if editor is actually submitting.
   */
  public isSubmitting$ = new BehaviorSubject<boolean>(false);

  /**
   * Common pipe for submitting
   */
  protected readonly submitPipe = pipe(
    finalize(() => this.isSubmitting$.next(false)),
    this.formService.handleApiValidationError(this.form),
    this.notify.handleGeneralError(),
    tap(() => this.submitted.emit(true))
  );
  /**
   * Display state of mentioning context
   * Hide context if "word" in chat editor doesn't start with '@'
   */
  public enableMentioning = false;

  public mentionTrigger = '@';
  public mentionConfig = {
    triggerChar: this.mentionTrigger,
    labelKey: 'label',
    mentionSelect: selectedItem => '[~' + selectedItem.mentionName + ']'
  };

  constructor(
    protected log: Logger,
    protected fb: FormBuilder,
    protected formService: FormService,
    protected route: ActivatedRoute,
    protected notify: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    // Create editor input form
    this.createForm();

    // Disable form while submitting
    this.addSubscription(
      this.isSubmitting$
        .pipe(distinctUntilChanged())
        .subscribe(isSubmitting => this.formService.isSubmittingHandler(isSubmitting, this.form))
    );
  }

  /**
   * States if editor is in edit mode (or else in add/create mode)
   */
  public get isEditMode(): boolean {
    return !!this.message;
  }

  /**
   * Create message's form group
   */
  protected createForm() {
    this.form = this.fb.group({
      message: [this.isEditMode ? this.message.message : '', [Validators.required]]
    });
  }

  /**
   * The message control
   */
  public get messageControl(): AbstractControl {
    return this.form.get('message');
  }

  /**
   * States if form is submittable
   */
  public get isSubmittable(): boolean {
    return this.form.valid && this.form.dirty;
  }

  /**
   * Submits the form depending on mode
   */
  public submit(): void {
    this.isSubmitting$.next(true);
    const message = this.messageControl.value;

    switch (true) {
      case this.isEditMode:
        this.partywallService
          .edit(this.message.id, message)
          .pipe(this.submitPipe)
          .subscribe(() => {});
        break;
      case !this.isEditMode:
      default:
        this.partywallService
          .add(message)
          .pipe(this.submitPipe)
          .subscribe(() => {
            this.messageControl.setValue('');
          });
        break;
    }
  }

  public cancel() {
    if (this.isEditMode) {
      this.submitted.emit(true);
    }
  }

  /**
   * Formats the message text with given type
   */
  public formatText(type: EditorFormatterType): void {
    this.textArea.nativeElement = this.addFormatter(type, this.textArea.nativeElement);
    this.textArea.nativeElement.focus();
  }

  /**
   * Get the selection of the input element
   */
  private getSelection(element: EditorInputTpye): EditorSelect {
    try {
      return { start: element.selectionStart, end: element.selectionEnd };
    } catch (e) {
      this.log.error('Cant get selection text');
      return null;
    }
  }

  /**
   * Add the formatting text markup
   */
  private addFormatter(type: EditorFormatterType, element: EditorInputTpye): EditorInputTpye {
    const formatter = EditorFormatters[type];
    const selection: EditorSelect = this.getSelection(element);

    if (formatter && selection) {
      // Adding opening symbol
      element.value = element.value.slice(0, selection.start) + formatter.symbol + element.value.slice(selection.start);

      // Adding closing symbol
      element.value =
        element.value.slice(0, selection.end + 1) + formatter.symbol + element.value.slice(selection.end + 1);

      if (selection.start === selection.end) {
        // set cursor between formatter
        element.focus();
        element.selectionEnd = selection.start + 1;
      } else {
        // set cursor after formatter (after previous selection)
        element.focus();
        element.selectionEnd = selection.end + 2;
      }

      // Set this value to form to ensure that it up-to-date
      // If no further input occurs after formatters added,
      // the reactive form control is not up-to-dat
      this.messageControl.setValue(element.value);
    }

    return element;
  }

  /**
   * Checks if mentioning context is allowed to be displayed or not
   */
  public disableMentionMenu(): void {
    const element: EditorInputTpye = this.textArea.nativeElement;
    const selection: EditorSelect = this.getSelection(element);
    if (element && selection) {
      const text = element.value;
      const currentChar = text.charAt(selection.start - 1);
      const lastChar = text.charAt(selection.start - 2);

      if (currentChar === ' ') {
        this.enableMentioning = false;
      } else if (currentChar === this.mentionTrigger && (lastChar === ' ' || lastChar === '')) {
        this.enableMentioning = true;
      }
    }
  }
}
