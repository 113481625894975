import { Config as ConfigInterface, FormAppearance } from '@interfaces/config';
import { PaymentOptions } from '@enums/payment-options.enum';
import { ShipmentOptions } from '@enums/shipment-options.enum';
import { FeeTypes } from '@enums/fee-types.enum';

export class Config {
  protected _config: ConfigInterface;

  constructor(configData: ConfigInterface) {
    this.init(configData);
  }

  init(configData: ConfigInterface): this {
    this._config = configData;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get plain(): ConfigInterface {
    return this._config;
  }

  /*
  |--------------------------------
  | Calculated properties
  |--------------------------------
  */

  /**
   * Gets the country
   */
  public get country(): string {
    return this.plain.country;
  }

  /**
   * Gets the locale
   */
  public get locale(): string {
    return this.plain.locale;
  }

  /**
   * Gets the country specific API URL
   */
  public get apiUrl(): string {
    return this.plain.url.replace(/\/+$/, '');
  }

  /**
   * Gets the country specific APP URL
   */
  public get appUrl(): string {
    return this.plain.frontend_url.replace(/\/+$/, '');
  }

  /**
   * Gets the ng locale id
   */
  public get ngLocaleId(): string {
    return this.plain.ng.locale_id;
  }

  /**
   * Gets the google analytics tracking id
   */
  public get trackingId(): string {
    return this.plain.ng.gtm.tracking_id;
  }

  /**
   * Gets the google analytics tracking id
   */
  public get sumUpLocale(): string {
    return this.plain.ng.sum_up.locale;
  }

  /**
   * Gets the required user profile attributes
   */
  public get userProfileRequirements(): string[] {
    return this.plain.user.attribute_requirements;
  }

  /**
   * Gets the hidden user profile attributes
   */
  public get userProfileHiddenAttributes(): string[] {
    return this.plain.user.hidden_attributes;
  }

  /**
   * Gets the address format string
   */
  public get addressFormat(): string[] {
    if (this.plain.user.address.format) {
      return this.plain.user.address.format;
    }

    // Fallback format
    return ['{{street}} {{streetNo}}', '{{zip}} {{city}}, {{countryCode}}'];
  }

  /**
   * Gets the address form appearance info
   */
  public get addressForm(): FormAppearance[] {
    return this.plain.user.address.form;
  }

  /**
   * Gets the available countries for user profile selection
   */
  public get availableCountries(): string[] {
    return this.plain.user.country.available;
  }

  /**
   * Gets the available languages for user profile selection
   */
  public get availableLanguages(): string[] {
    return this.plain.user.language.available;
  }

  /**
   * Gets the currency
   */
  public get currency(): string {
    return this.plain.order.currency;
  }

  /**
   * Gets the available shipment options
   */
  public get shipmentOptions(): ShipmentOptions[] {
    return this.plain.order.shipment;
  }

  /**
   * Gets the available payment options
   */
  public get paymentOptions(): PaymentOptions[] {
    return this.plain.order.payment;
  }

  /**
   * Get the fee of type
   */
  public fee(feeType: FeeTypes): number {
    return this.plain.order.fees.hasOwnProperty(feeType) ? this.plain.order.fees[feeType] : null;
  }

  /**
   * States if DIP is active
   */
  public get isDipActive(): boolean {
    return this.plain.order.dip;
  }

  /**
   * Get Header Image URL for dashboard header
   */
  public get dashboardHeaderImage(): string {
    return this.plain.dashboard.headerUrl;
  }
}
