import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BaseComponent } from '@components/base.component';
import { filter } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Logger } from '@core/logger';
import { GlobalErrorService } from '@services/global-error.service';

@Component({
  selector: 'idpo-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends BaseComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public message: string;

  @Input()
  public showErrorStack = false;

  public showErrorStackEnv = false;

  public errorResponses: HttpErrorResponse[] = [];

  public constructor(protected log: Logger, protected globalErrors: GlobalErrorService) {
    super();
  }
  public ngOnInit(): void {
    this.addSubscription(
      this.globalErrors
        .get()
        .pipe(filter(error => error instanceof HttpErrorResponse))
        .subscribe(error => this.errorResponses.push(error))
    );

    this.showErrorStackEnv = environment.errorPages.debug;
  }
}
