import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { NavigationService } from '@services/navigation.service';
import { CartService } from '@services/cart.service';
import { NavBarHiding } from '@enums/nav-bar-hiding.enum';
import { map, switchMap } from 'rxjs/operators';
import { Logger } from '../logger';
import { Observable, of } from 'rxjs';
import {
  CatalogService,
  GlobalsService,
  HostessGiftCartService,
  PartyService,
  UserService,
  WishlistService
} from '@services';
import { PartyUserBaseComponent } from '@app/shared/components';
import { IPaperTypes } from '@enums';
import {NavigationEnd, Router} from "@angular/router";
import {NotificationService} from "@services";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'idpo-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends PartyUserBaseComponent implements AfterViewInit {
  @Input()
  public set visibility(nvb: NavBarHiding) {
    if (null !== nvb) {
      this.showComplete = nvb !== NavBarHiding.Complete;
      this.showContent = this.showComplete && nvb !== NavBarHiding.Content;
      this.showLogout = this.showContent && nvb !== NavBarHiding.Logout;
    }
  }

  public showComplete;
  public showContent;
  public showLogout;
  public showForm = 'login';

  public catalogAvailable$: Observable<boolean>;
  public monthlyOfferAvailable$: Observable<boolean>;

  public cartCount$: Observable<number>;
  public wishlistCount$: Observable<number>;
  public hostessGiftCartCount$: Observable<number>;
  public isRegistered$: Observable<boolean>;

  constructor(
    partyService: PartyService,
    userService: UserService,
    protected log: Logger,
    protected navi: NavigationService,
    protected cartService: CartService,
    protected wishlistService: WishlistService,
    protected catalogService: CatalogService,
    protected hostessGiftCartService: HostessGiftCartService,
    protected globals: GlobalsService,
    protected router: Router,
    protected notify: NotificationService,
    protected translate: TranslateService
  ) {
    super(partyService, userService);

    this.initValues();

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide sidepanel on each route change
        this.closeSidepanel();
      }
    });

    this.userService.userChanged$.subscribe(user => {
      this.initValues();
    })
  }

  initValues() {
    // At `cartService.count()` invoke a GET of cart will started if not fetched for the first time
    // We do not want get a possible 401 even if cart item is not visible
    // So we wrap it in a switch map to invoke `cartService.count()` just on subscription
    this.cartCount$ = of(null).pipe(switchMap(() => this.cartService.count()));
    this.wishlistCount$ = of(null).pipe(switchMap(() => this.wishlistService.count()));
    this.hostessGiftCartCount$ = of(null).pipe(switchMap(() => this.hostessGiftCartService.count()));
    this.isRegistered$ = this.user$.pipe(map(user => user.isRegistered));

    this.catalogAvailable$ = of(null).pipe(
        switchMap(() =>
            this.catalogService.get().pipe(
                map(catalogs => catalogs.find(catalog => catalog.type === IPaperTypes.Catalog)),
                map(catalog => !!catalog)
            )
        )
    );

    this.monthlyOfferAvailable$ = of(null).pipe(
        switchMap(() =>
            this.catalogService.get().pipe(
                map(catalogs => catalogs.find(catalog => catalog.type === IPaperTypes.Flyer)),
                map(catalog => !!catalog)
            )
        )
    );
  }

  ngAfterViewInit() {
    this.adjustNavSize();
  }

  public get tupperwareLogo() {
    return `${this.globals.CdnBaseUrl}/img/logo_tupperware.svg`;
  }

  public goToDashboard(): void {
    this.navi.dashboard().subscribe();
  }

  public goToProfile(): void {
    this.user$.subscribe(user => {
      if (user.isRegistered) {
        this.navi.userProfile().subscribe();
      } else {
        this.openSidepanel();
      }
    });
  }

  public goToProducts(): void {
    this.navi.products().subscribe();
  }

  public goToCatalog(): void {
    this.navi.ipaperCatalog().subscribe();
  }

  public goToMonthlyOffers(): void {
    this.navi.ipaperMonthlyOffers().subscribe();
  }

  public goToCart(): void {
    this.navi.orderCart().subscribe();
  }

  public goToPartyWall(): void {
    this.navi.partyWall().subscribe();
  }

  public goToWishlist(): void {
    this.navi.wishlist().subscribe();
  }

  public goToMyParty(): void {
    this.navi.myParty().subscribe();
  }

  public goToHostessGift(): void {
    this.navi.hostessGift().subscribe();
  }

  public goToHostessGiftCart(): void {
    this.navi.hostessGiftCart().subscribe();
  }

  public openSidepanel(page = 'login') {
    this.navi.openSidepanel(page);
  }

  public closeSidepanel() {
    this.navi.closeSidepanel();
  }

  public setSidepanelPage(page: string) {
    this.navi.setSidepanelPage(page);
  }

  public sidepanelStatus() : boolean {
    return this.navi.sidepanelStatus();
  }

  public sidepanelPage() : string {
    return this.navi.getSidepanelPage();
  }

  public sidepanelOnLogin() {
    window.location.reload();
  }

  public sidepanelOnRegister() {
    window.location.reload();
  }

  public sidepanelOnPasswordForgot() {
    this.closeSidepanel();
    this.translate.get('thankYou.passwordForgot').subscribe(message => {
      this.notify.success(message);
    });
  }

  public logout(): void {
    this.userService
      .logout()
      .pipe(switchMap(() => this.navi.join()))
      .subscribe();
  }

  public adjustNavSize() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.adjustNavSize();
  }
}
