import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductService } from '@services/product.service';
import { Logger } from '../logger';
import { Products } from '@models/products';

@Injectable({
  providedIn: 'root'
})
export class ProductsResolverService implements Resolve<Products> {
  constructor(protected products: ProductService, protected log: Logger) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Products> {
    return this.products.get().pipe(take(1));
  }
}
