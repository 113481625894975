import { User } from '@models/user';
import { PartyBaseComponent } from '@components/party-base.component';
import { Role } from '@enums/role.enum';
import { PartyService } from '@services/party.service';
import { UserService } from '@services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class PartyUserBaseComponent extends PartyBaseComponent {
  public get user$(): Observable<User> {
    return this.userService.get();
  }

  public get viewAs$(): Observable<Role> {
    return this.user$.pipe(map(user => user.role));
  }

  public get viewAsHost$(): Observable<boolean> {
    return this.viewAs$.pipe(map(role => role === Role.Host));
  }
  public get viewAsGuest$(): Observable<boolean> {
    return this.viewAs$.pipe(map(role => role === Role.Guest));
  }

  protected constructor(partyService: PartyService, protected userService: UserService) {
    super(partyService);
  }
}
