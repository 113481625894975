import { ProductDetails as ProductDetailsInterface, ProductDetailImage, ProductDetailVideo } from '@interfaces';
import { Product } from '@shared/models';

export class ProductDetails {
  protected _language: string;
  protected _country: string;
  protected _sku: string;
  protected _usi: string;
  protected _name: string;
  protected _headline: string;
  protected _salesCode: string;
  protected _description: string;
  protected _images: ProductDetailImage[];
  protected _videos: ProductDetailVideo[];
  protected _relatedItems: Product[];

  constructor(productData: ProductDetailsInterface) {
    this.init(productData);
  }

  init(productDetailsData: ProductDetailsInterface): this {
    this._language = productDetailsData.language;
    this._country = productDetailsData.country;
    this._sku = productDetailsData.sku;
    this._usi = productDetailsData.usi;
    this._name = productDetailsData.name;
    this._headline = productDetailsData.headline;
    this._salesCode = productDetailsData.salesCode;
    this._description = productDetailsData.description;
    this._images = productDetailsData.images;
    this._videos = productDetailsData.videos;
    this._relatedItems = productDetailsData.relatedItems;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get language(): string {
    return this._language;
  }

  public get country(): string {
    return this._country;
  }

  public get sku(): string {
    return this._sku;
  }

  public get usi(): string {
    return this._usi;
  }

  public get name(): string {
    return this._name;
  }

  public get headline(): string {
    return this._headline;
  }

  public get salesCode(): string {
    return this._salesCode;
  }

  public get description(): string {
    return this._description;
  }

  public get images(): ProductDetailImage[] {
    return this._images;
  }

  public get videos(): ProductDetailVideo[] {
    return this._videos;
  }

  public get relatedItems(): Product[] {
    return this._relatedItems;
  }
}
