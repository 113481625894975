import { Directive, HostListener } from '@angular/core';
import $ from 'jquery';

@Directive({
  selector: '[idpoAutocloseMenu]'
})
export class AutocloseMenuDirective {
  @HostListener('click')
  public onClick(): void {
    if ($('body').hasClass('menu-opened')) {
      $('body, main, footer, #head-navigation').removeClass('menu-opened');
    }
  }
}
