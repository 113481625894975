import { Injectable, Injector } from '@angular/core';
import { ApiService } from '@services/api.service';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Party } from '@models/party';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';
import { Logger } from '@core/logger';

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService extends TranslateLoader {
  protected api: ApiService;
  constructor(protected injector: Injector, protected log: Logger) {
    super();
    this.translations$ = {};
    this.api = <ApiService>injector.get(ApiService);
  }

  protected translations$: { [lang: string]: ReplaySubject<any> };

  /**
   * Fetch localizations
   */
  public fetch(lang: string) {
    if (!this.translations$[lang]) {
      // use ReplaySubject to simulate BehaviorSubject without initial value
      this.translations$[lang] = new ReplaySubject<Party>(1);
    }

    const appTranslations = this.api.http.get('/assets/i18n/en_GB.json');
    const apiTranslations = this.api
      .commonGet(baseURL => baseURL.segment('/localizations/:lang').param({ lang }))
      .pipe(
        catchError(err => {
          this.log.error(`Could not load API translations for ${lang}, try to fetch static en_GB.json from App`, err);
          return appTranslations;
        })
      );
    const translations = environment.api.useTranslations ? apiTranslations : appTranslations;

    translations.subscribe(
      data => {
        this.translations$[lang].next(data);
      },
      error => {
        this.translations$[lang].error(error);
      }
    );
  }

  /**
   * Get localizations
   */
  public get(lang: string): Observable<any> {
    if (!this.translations$[lang]) {
      this.fetch(lang);
    }
    return this.translations$[lang];
  }

  /**
   * Implements ngx-translate TranslateLoader abstract method.
   * @param lang The language parameter
   */
  getTranslation(lang: string): Observable<any> {
    return this.get(lang);
  }
}
