import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Logger } from '../logger';
import { HostessGiftOrderService } from '@services';
import { HostessGiftOrder } from '@models';

@Injectable({
  providedIn: 'root'
})
export class HostessGiftOrderResolverService implements Resolve<HostessGiftOrder> {
  constructor(protected hostessGiftOrderService: HostessGiftOrderService, protected log: Logger) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HostessGiftOrder> {
    return this.hostessGiftOrderService.get().pipe(take(1));
  }
}
