import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Logger } from '@app/core/logger';
import { take, switchMap } from 'rxjs/operators';
import { NavigationService } from '@services/navigation.service';
import { OrderService } from '@services/order.service';

@Injectable({
  providedIn: 'root'
})
export class OrderNotCompletedGuard implements CanActivate {
  constructor(protected log: Logger, protected navi: NavigationService, protected orderService: OrderService) {}

  public isNotCompleted(): Observable<boolean | UrlTree> {
    return this.orderService.get().pipe(
      take(1),
      switchMap(order => {
        if (order.completed) {
          // Order already completed
          this.log.info('Order already completed');
          // Go to completed
          return this.navi.orderCompletedUrlTree;
        }
        return of(true);
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isNotCompleted();
  }
}
