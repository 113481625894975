import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  private version = '1.8.29';

  readonly CdnBaseUrl: string = `https://cdn.tupperware.biz/mytupperware/${this.version}`;

  constructor() {}
}
