import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Logger } from '@app/core/logger';
import { PartyIDService } from '@services/party-id.service';
import { NavigationService } from '@services/navigation.service';
import { DealerAuthStorageService } from '@services/dealer-auth-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DealerGuard implements CanActivate, CanActivateChild {
  constructor(
    protected log: Logger,
    protected router: Router,
    protected partyID: PartyIDService,
    protected dealerAuthStorage: DealerAuthStorageService,
    protected navi: NavigationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPartyIdAndAccessToken(route);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPartyIdAndAccessToken(route);
  }

  protected checkPartyIdAndAccessToken(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    if (!route.params.partyId) {
      return this.navi.partyNotFoundUrlTree;
    }

    // For the moment this is the central place for setting the party scope!
    this.partyID.set(route.params.partyId);

    if (route.queryParams.accessToken) {
      this.dealerAuthStorage.storeAccessToken(route.queryParams.accessToken);
    }

    return of(true);
  }
}
