import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '@core/logger';
import { environment } from '@environments/environment';
import { NavigationService } from '@services/navigation.service';
import { DealerAuthStorageService } from '@services';

@Injectable()
export class AuthDealerInterceptor implements HttpInterceptor {
  constructor(
    protected log: Logger,
    protected navi: NavigationService,
    protected dealerAuthStorage: DealerAuthStorageService
  ) {}

  protected partyId: string | null;

  /**
   * Check if it is an API request that needs authentication
   */
  protected requestNeedsAuthentication(request: HttpRequest<any>): boolean {
    let url = request.url;

    if (!url.startsWith(environment.api.baseUrl)) {
      return false;
    }

    // remove base url
    url = url.replace(environment.api.baseUrl, '');

    // not starts with /tw/v1/chat/partys/{party}/ ?
    const partyURLRegEx: RegExp = /^\/?tw\/v\d+\/chat\/partys\/[^\/]+\/?/i;
    if (!partyURLRegEx.test(url)) {
      return false;
    }

    // Workaround for not having partyId at custom NavigationService
    const partyIdMatch = url.match(/^\/?tw\/v\d+\/chat\/partys\/([^\/]+)[\/\?]?/i);
    this.partyId = partyIdMatch.length >= 2 ? partyIdMatch[1] : null;

    return true;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestNeedsAuthentication = this.requestNeedsAuthentication(request);

    // Skip requests that need no authentication
    if (!requestNeedsAuthentication) {
      return next.handle(request.clone());
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.dealerAuthStorage.accessToken}`
      }
    });

    // Send request 401 catcher.
    return next.handle(request).pipe(
      catchError((error: any) => {
        // Just interested in HTTP errors
        if (error instanceof HttpErrorResponse) {
          // Just handle non authenticated
          switch (error.status) {
            case 401:
              return this.handleUnauthorized(request, next);
          }
        }
        return throwError(error);
      })
    );
  }

  /**
   * Handle unauthorized requests.
   */
  protected handleUnauthorized(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.navi.dealerUnauthenticatedError().subscribe();
    return EMPTY;
  }
}
