import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToInitial'
})
export class ConvertToInitialPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (!value) {
      return value;
    }

    return `${value.substring(0, 1)}.`;
  }
}
