import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavBarHiding } from '@enums';
import { AccountConfirmComponent } from './confirm';
import { AccountCreateComponent } from './create';
import { AccountDeletedComponent } from './deleted';
import { AccountUnconfirmedComponent } from './unconfirmed';
import { LoginComponent } from './login';
import { JoinComponent } from './join';
import { PasswordForgotComponent, PasswordResetComponent } from './password';
import { ThankYouMailComponent } from './thank-you-mail';
import { ThankYouPasswordComponent } from './thank-you-password';
import {UserAlreadyJoinedPartyGuard} from "@guards";

const routes: Routes = [
  {
    path: '',
    data: { navBarHiding: NavBarHiding.Content, hideFloatingButton: true },
    children: [
      { path: 'login', component: LoginComponent },
      {
        path: 'join', component: JoinComponent,
        canActivate: [UserAlreadyJoinedPartyGuard],
      },
      { path: 'create', component: AccountCreateComponent },
      { path: 'confirm/:referenceToken', component: AccountConfirmComponent },
      { path: 'unconfirmed', component: AccountUnconfirmedComponent },
      { path: 'deleted', component: AccountDeletedComponent },
      {
        path: 'password',
        children: [
          { path: 'forgot', component: PasswordForgotComponent },
          { path: 'reset/:referenceToken', component: PasswordResetComponent }
        ]
      },
      {
        path: 'thank-you',
        children: [
          { path: 'mailsent', component: ThankYouMailComponent },
          { path: 'password', component: ThankYouPasswordComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
