import { ApiValidationError as ApiValidationErrorResponse } from '@interfaces/api-validation-error.response';
import * as dot from 'dot-object';

export class ApiValidationError {
  protected _parameterErrors: { [key: string]: string[] };

  public get hasParameterErrors(): boolean {
    return Object.keys(this.parameterErrors).length !== 0;
  }

  public get parameterErrors() {
    return this._parameterErrors;
  }

  public get errors() {
    return this.validationErrorResponse.errors;
  }

  constructor(protected validationErrorResponse: ApiValidationErrorResponse, protected parameters: any) {
    this.setParameterErrors();
  }

  protected setParameterErrors() {
    const dotParameter: { [key: string]: any } = dot.dot(this.parameters);
    this._parameterErrors = {};
    for (const key of Object.keys(this.validationErrorResponse.errors)) {
      if (dotParameter.hasOwnProperty(key)) {
        this._parameterErrors[key] = this.validationErrorResponse.errors[key];
      }
    }
  }
}
