import { Component, Input } from '@angular/core';
import { Product } from '@models';
import {TranslateService} from "@ngx-translate/core";
import {Label} from "@models/label";

@Component({
  selector: 'idpo-product-flags',
  templateUrl: './product-flags.component.html',
  styleUrls: ['./product-flags.component.scss']
})
export class ProductFlagsComponent {
  @Input()
  public product: Product;

  public labels: Label[] = [];

  constructor(
      protected translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.product.startsInWeek) {
      this.translate.get('products.startsInWeek', { weekYear: this.product.startsInWeek })
          .subscribe(message => {
            this.labels.push(new Label(message, 'start'));
          });
    }
    if (!this.product.startsInWeek && this.product.isPromo) {
      this.translate.get('products.promotion')
          .subscribe(message => {
            //this.labels.push(new Label(message, 'promo'));
          });
    }
    if (this.labels.length < 2 && !this.product.startsInWeek && this.product.isHostOnly) {
      this.translate.get('products.hostOnly')
          .subscribe(message => {
            this.labels.push(new Label(message, 'host-only'));
          });
    }
    if (this.labels.length < 2 && this.product.labels) {
      let addLabels = this.product.labels.split(',');
      if (addLabels.length) {
        addLabels.map(label => {
          if (this.labels.length < 2) {
            this.labels.push(new Label(label, this.resolveColorClassFromText(label)));
          }
        })
      }
    }
    if (this.product.hasLowStock) {
      this.translate.get('products.lowStock')
          .subscribe(message => {
            this.labels.push(new Label(message, 'start'))
          });
    }
  }

  protected resolveColorClassFromText(text: string): string {
    switch (text.trim().toLowerCase()) {
      case 'neu': return 'new';
      case 'neue farbe': return 'new-color';
      case 'bye bye': return 'bye-bye';
      default: return 'promo';
    }
  }
}
