import { Input } from '@angular/core';
import { PartywallService } from '@interfaces';
import { BaseComponent } from '@components/base.component';

export abstract class PartywallBaseComponent extends BaseComponent {
  /**
   * The partywall service to use
   */
  @Input()
  public partywallService: PartywallService;

  public constructor() {
    super();
  }
}
