import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { Party } from '@models/party';
import { User } from '@models/user';
import { ConfigService } from '@services';

@Component({
  selector: 'idpo-party-info-card',
  templateUrl: './party-info-card.component.html',
  styleUrls: ['./party-info-card.component.scss']
})
export class PartyInfoCardComponent extends BaseComponent implements OnInit {
  @Input()
  public user: User;
  @Input()
  public party: Party;
  @Input()
  public static: boolean;

  public get viewAs() {
    return this.user.role;
  }

  public addressFormat: string[];

  constructor(protected config: ConfigService) {
    super();
  }

  ngOnInit(): void {
    this.addressFormat = this.config.instant.addressFormat;
  }
}
