import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone2link'
})
export class Phone2linkPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) return value;

    // NOTE: https://regex101.com/r/jJpzAu/8
    const regex = /(^|\s)(([(+0]{1,2}\d{2}\)?)?(\(0\))?[-.\/ ()]{0,2}([-.\/ ()]{0,2}\d){8,15}\d)\b/gm;

    value = value.replace(regex, match => {
      const matchNoChar = match.replace(/[\s\/\-.()]*/g, '');
      return ` <a href="tel:${matchNoChar}">${match.trim()}</a> `;
    });

    return value;
  }
}
