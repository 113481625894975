import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceByTags'
})
export class ReplaceByTagsPipe implements PipeTransform {
  transform(value: string, regexValue: string, replaceValue: string): any {
    const regex = new RegExp('\\' + regexValue + '(.*?)\\' + regexValue, 'g');
    return value.replace(regex, '<' + replaceValue + '>$1</' + replaceValue + '>');
  }
}
