import { Injectable } from '@angular/core';
import { Logger } from '../logger';
import { ApiService, ApiURLParameter } from '@services/api.service';
import { ProductService } from '@services/product.service';

@Injectable({
  providedIn: 'root'
})
export class HostessGiftProductService extends ProductService {
  protected get baseUrlSegment(): string {
    return '/hostess-gift/products';
  }

  constructor(api: ApiService, log: Logger) {
    super(api, log);
  }
}
