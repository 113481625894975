import { Order as OrderInterface } from '@interfaces/order';
import { ShipmentOptions } from '@enums/shipment-options.enum';
import { PaymentOptions } from '@enums/payment-options.enum';
import { FeeTypes } from '@enums/fee-types.enum';
import { OrderTypes } from '@enums/order-types.enum';

export class Order {
  protected _orderType: OrderTypes;
  protected _shipment: ShipmentOptions;
  protected _payment: PaymentOptions;
  protected _committed: boolean;
  protected _payed: boolean;
  protected _completed: boolean;
  protected _handlesPayment: boolean;
  protected _needsPayment: boolean;
  protected _confirmedDip: boolean;
  protected _fees: { [TKey in FeeTypes]: number };
  protected _feeAmount: number;
  protected _orderAmount: number;
  protected _totalAmount: number;
  protected _currency: string;

  // API response props
  public committed_at: string;

  constructor(orderData: OrderInterface) {
    this.init(orderData);
  }

  init(orderData: OrderInterface): this {
    this._orderType = orderData.orderType as OrderTypes;
    this._shipment = orderData.shipment as ShipmentOptions;
    this._payment = orderData.payment as PaymentOptions;
    this._committed = orderData.committed;
    this._payed = orderData.payed;
    this._completed = orderData.completed;
    this._handlesPayment = orderData.handlesPayment;
    this._needsPayment = orderData.needsPayment;
    this._confirmedDip = orderData.confirmedDip;
    this._fees = orderData.fees;
    this._feeAmount = orderData.feeAmount;
    this._orderAmount = orderData.orderAmount;
    this._totalAmount = orderData.totalAmount;
    this._currency = orderData.currency;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get orderType(): OrderTypes {
    return this._orderType;
  }

  public get shipment(): ShipmentOptions {
    return this._shipment;
  }

  public get payment(): PaymentOptions {
    return this._payment;
  }

  public get committed(): boolean {
    return this._committed;
  }

  public get payed(): boolean {
    return this._payed;
  }

  public get completed(): boolean {
    return this._completed;
  }

  public get handlesPayment(): boolean {
    return this._handlesPayment;
  }

  public get needsPayment(): boolean {
    return this._needsPayment;
  }

  public get confirmedDip(): boolean {
    return this._confirmedDip;
  }

  public get fees(): { [TKey in FeeTypes]: number } {
    return this._fees;
  }

  public get feeAmount(): number {
    return this._feeAmount;
  }

  public get orderAmount(): number {
    return this._orderAmount;
  }

  public get totalAmount(): number {
    return this._totalAmount;
  }

  public get currency(): string {
    return this._currency;
  }

  /*
  |--------------------------------
  | Calculated properties
  |--------------------------------
  */
}
