import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';

export interface PartyIdChanged {
  before: string;
  after: string;
}

@Injectable({
  providedIn: 'root'
})
export class PartyIDService {
  protected partyId$: BehaviorSubject<string | null>;
  protected changed$: Subject<PartyIdChanged>;

  constructor() {
    this.partyId$ = new BehaviorSubject<string | null>(null);
    this.changed$ = new Subject<PartyIdChanged>();
  }

  public set(partyID: string) {
    this.changed$.next({ before: this.partyId$.value, after: partyID });
    this.partyId$.next(partyID);
  }

  public get(withEmpty = false): Observable<string> {
    return this.partyId$.pipe(
      filter(partyID => withEmpty || !!partyID),
      distinctUntilChanged()
    );
  }

  public changed(): Observable<PartyIdChanged> {
    return this.changed$.pipe(
      skip(1),
      filter(changed => changed.after !== changed.before),
      distinctUntilChanged()
    );
  }
}
