import { AccountCreateFormElement } from './account-create.form.interface';

export const accountCreateFormDefinition: AccountCreateFormElement[] = [
  {
    id: 'firstName',
    placeholder: 'forms.firstName',
    type: 'text',
    showIcon: {
      eye: false
    },
    errors: {
      required: 'forms.errors.firstNameRequired',
      pattern: 'forms.errors.firstNameNoSpecialCharacter'
    }
  },
  {
    id: 'lastName',
    placeholder: 'forms.lastName',
    type: 'text',
    showIcon: {
      eye: false
    },
    errors: {
      required: 'forms.errors.lastNameRequired',
      pattern: 'forms.errors.lastNameNoSpecialCharacter'
    }
  },
  {
    id: 'email',
    placeholder: 'forms.email',
    type: 'email',
    showIcon: {
      eye: false
    },
    errors: {
      required: 'forms.errors.emailRequired',
      email: 'forms.errors.emailInvalidFormat'
    }
  },
  {
    id: 'password',
    placeholder: 'forms.password',
    type: 'password',
    showIcon: {
      eye: true
    },
    errors: {
      required: 'forms.errors.passwordRequired'
    }
  },
  {
    id: 'password_confirmation',
    placeholder: 'forms.confirmPassword',
    type: 'password',
    showIcon: {
      eye: true
    },
    errors: {
      required: 'forms.errors.confirmPasswordRequired',
      passwordMustMatch: 'forms.errors.passwordMustMatch'
    }
  }
];
