import { Injectable } from '@angular/core';
import { NotificationParameter, NotificationParameterOptional } from '@app/shared/interfaces/notification-parameter';
import { TranslateService } from '@ngx-translate/core';
import { ApiValidationError } from '@models/api-validation-error.model';
import { Logger } from '@core/logger';
import { EMPTY, Observable, OperatorFunction, pipe, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlreadyNotifiedError } from '@models/already-notified-error.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  types = ['alert', 'error', 'info', 'warn', 'success'];
  public messages: NotificationParameter[] = [];
  public showModal: boolean = false;

  constructor(
    protected translate: TranslateService,
    protected log: Logger
  ) {}

  protected translateParameter(params: NotificationParameter) {
    if (params.translate) {
      params.title = this.translate.instant(params.title);
      params.content = this.translate.instant(params.content);
      params.translate = false;
    }
  }

  public error(params: NotificationParameter = null) {
    if (null === params) {
      params = {
        class: "fa-exclamation-triangle text-danger",
        type: "error",
        title: 'general.notifications.error.title',
        content: 'general.notifications.error.text',
        translate: true
      };
    }

    this.translateParameter(params);
    this.createModal(params);
  }

  public success(params: NotificationParameter = null) {
    if (null === params) {
      params = {
        class: "fa-check-circle text-success",
        type: "success",
        title: 'general.notifications.success.title',
        content: 'general.notifications.success.text',
        translate: true
      };
    }
    this.translateParameter(params);
    this.createModal(params);
  }

  public alert(params: NotificationParameter) {
    this.translateParameter(params);
    params.type = 'alert';
    params.class = 'fa-info-circle text-info';
    this.createModal(params);
  }

  public info(params: NotificationParameter) {
    this.translateParameter(params);
    params.type = 'info';
    params.class = 'fa-info-circle text-info';
    this.createModal(params);
  }

  public createModal(params: NotificationParameter) {
    this.messages.push(params);
    this.showModal = true;
  }

  public dismissModal() {
    this.messages.pop();
    this.showModal = this.messages.length > 0;
  }

  public showApiValidationError(error: ApiValidationError, override: NotificationParameterOptional = {}) {
    const message = Object.values(error.errors)
      .map(err => err.join('\n'))
      .join('\n');
    const params: NotificationParameter = {
      title: this.translate.instant('general.notifications.info.title'),
      content: message,
      translate: false
    };

    return this.info({ ...params, ...override });
  }

  /**
   * Handles API Validation errors to notify in pipe
   */
  public handleApiValidationError<T>(override: NotificationParameterOptional = {}): OperatorFunction<T, T | never> {
    return pipe(
      catchError<T, Observable<never>>(error => {
        if (error instanceof ApiValidationError) {
          this.showApiValidationError(error, override);
          return EMPTY;
        }
        // rethrow error
        return throwError(error);
      })
    );
  }

  /**
   * Handles errors to notify in pipe
   */
  public handleGeneralError<T>(): OperatorFunction<T, T | never> {
    return pipe(
      catchError<T, Observable<never>>(error => {
        if (error instanceof AlreadyNotifiedError) {
          return EMPTY;
        }

        this.log.debug(error);
        this.error();
        return EMPTY;
      })
    );
  }
}
