import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@app/core/services/notification.service';
import { BaseComponent } from '@shared/components/base.component';
import { NavigationService } from '@app/core/services/navigation.service';
import { Logger } from '@core/logger';
import { switchMap, take } from 'rxjs/operators';
import { UserService } from '@services';

@Component({
  selector: 'idpo-order-period-started',
  templateUrl: './order-period-started.component.html',
  styleUrls: ['./order-period-started.component.scss']
})
export class OrderPeriodStartedComponent extends BaseComponent implements OnInit {
  constructor(
    protected log: Logger,
    protected notify: NotificationService,
    protected navi: NavigationService,
    protected userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userService
      .readOrderPeriodStarted()
      .pipe(take(1))
      .subscribe();
  }

  public toShop(): void {
    this.navi
      .closePopup()
      .pipe(switchMap(() => this.navi.products()))
      .subscribe();
  }

  public close(): void {
    this.navi.closePopup().subscribe();
  }
}
