import { Directive, HostListener } from '@angular/core';
import $ from 'jquery';

@Directive({
  selector: '[idpoOpenOnClick]'
})
export class OpenOnClickDirective {
  @HostListener('click')
  public onClick(): void {
    if (!$('body').hasClass('menu-opened')) {
      $('body, main, footer, #head-navigation').addClass('menu-opened');
    } else {
      $('body, main, footer, #head-navigation').removeClass('menu-opened');
    }
  }
}
