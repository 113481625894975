import { Injectable } from '@angular/core';
import { Logger } from '@core/logger';
import { EMPTY, Observable, of, pipe, ReplaySubject } from 'rxjs';
import { ApiService } from '@services/api.service';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { BehaviourService, ProductDetails as ProductDetailsInterface } from '@interfaces';
import * as UrlAssembler from 'url-assembler';
import { Product, ProductDetails } from '@models';
import { HttpErrorResponse } from '@angular/common/http';
import { DPOErrorTypes } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService implements BehaviourService<ProductDetails> {
  protected productDetails$ = new Map<string, ReplaySubject<ProductDetails>>();

  /**
   * Pipe for set next product details
   */
  protected setProductDetails = pipe(
    map((productDetailsInterface: ProductDetailsInterface) => new ProductDetails(productDetailsInterface)),
    // Not set error to replay subject
    tap((productDetails: ProductDetails) => this.productDetails$.get(productDetails.usi).next(productDetails))
  );

  constructor(protected api: ApiService, protected log: Logger) {}

  /**
   * Unset actual product details map to force new fetches
   */
  public unset() {
    this.productDetails$.forEach(details$ => details$.complete());

    this.productDetails$ = new Map<string, ReplaySubject<ProductDetails>>();
  }

  /**
   * Fetch available product details from API.
   */
  public fetch(product: Product): Observable<ProductDetails> {
    if (!this.productDetails$.has(product.usi)) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.productDetails$.set(product.usi, new ReplaySubject<ProductDetails>(1));
    }

    return this.api
      .get<ProductDetailsInterface>((url: UrlAssembler) =>
        url.segment('/products/:productId').param({ productId: product.id })
      )
      .pipe(
        catchError(err => {
          const productDetails$ = this.productDetails$.get(product.usi);
          if (
            err instanceof HttpErrorResponse &&
            err.error.dpo &&
            err.error.dpo === DPOErrorTypes.ProductDetailsNotFound
          ) {
            productDetails$.next(null);
            this.log.warn('Product details not found at Promise');
          } else {
            productDetails$.error(err);
          }
          return EMPTY;
        }),
        this.setProductDetails
      );
  }

  /**
   * Get available product details
   */
  public get(product: Product): Observable<ProductDetails> {
    if (!product.usi) {
      return of(null).pipe(shareReplay(1));
    }

    if (!this.productDetails$.has(product.usi)) {
      this.fetch(product).subscribe();
    }
    return this.productDetails$.get(product.usi);
  }
}
