import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { PartyService } from '@services/party.service';
import { switchMap } from 'rxjs/operators';
import { Logger } from '@app/core/logger';
import { PartyIDService } from '@services/party-id.service';
import * as moment from 'moment';
import { NavigationService } from '@services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class PartyGuard implements CanActivate, CanActivateChild {
  constructor(
    protected log: Logger,
    protected router: Router,
    protected partyID: PartyIDService,
    protected party: PartyService,
    protected navi: NavigationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkParty(route);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkParty(route);
  }

  protected checkParty(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    if (!route.params.partyId) {
      return this.navi.partyNotFoundUrlTree;
    }

    // this.log.debug('Set PartID - PartyGuard', route.params.partyId);
    // For the moment this is the central place for setting the party scope!
    this.partyID.set(route.params.partyId);
    return this.party.get().pipe(
      switchMap(party => {
        return of(true);
      })
    );
  }
}
