import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euroCurrency'
})
export class EuroCurrencyPipe implements PipeTransform {
  /**
   * The transform supports currently only EUR and USD
   * @param number current Price
   */
  transform(number: any): any {
    let price;

    price = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number);

    return price;
  }
}
