import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '@services/user.service';
import { Logger } from '@app/core/logger';
import { switchMap } from 'rxjs/operators';
import { NavigationService } from '@services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class UserAlreadyConfirmedTacGuard implements CanActivate {
  constructor(protected user: UserService, protected navi: NavigationService, protected log: Logger) {}

  protected hasAlreadyConfirmedTaC(): Observable<boolean | UrlTree> {
    return this.user.get().pipe(
      switchMap(user => {
        if (user.confirmedTermsConditions) {
          // Check if account is confirmed
          this.log.debug('TaC already confirmed');
          // Go to next step
          return this.navi.userJoinPartyUrlTree;
        }
        return of(true);
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAlreadyConfirmedTaC();
  }
}
