import { Component } from '@angular/core';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'idpo-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  // For all options and description see https://www.npmjs.com/package/angular2-notifications
  animationTypes = ['fromRight', 'fromLeft', 'scale', 'rotate'];
  public config = {
    position: ['top', 'right'],
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: this.animationTypes[0],
    theClass: 'idpo-notifications'
  };

  public constructor(
      protected notificationService: NotificationService
  ) {
  }

  public currentMessage() {
      return this.notificationService.messages[0];
  }

  public close() {
      this.notificationService.dismissModal();
  }

  public showModal(): boolean {
    return this.notificationService.showModal;
  }
}
