import { PasswordForgotForm } from './password-forgot.form.interface';

// tslint:disable:max-line-length
const formData: PasswordForgotForm[] = [
  {
    id: 'email',
    type: 'email',
    placeholder: 'forms.email',
    errors: {
      required: 'forms.errors.emailRequired',
      email: 'forms.errors.emailInvalidFormat'
    }
  }
];

export default formData;
