import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { Logger } from '@core/logger';
import { PaymentService, NavigationService } from '@services';

@Injectable({
  providedIn: 'root'
})
export class OrderPaymentHandlingGuard implements CanActivate {
  constructor(protected log: Logger, protected navi: NavigationService, protected paymentService: PaymentService) {}

  public hasPaymentParameter(): Observable<boolean | UrlTree> {
    return this.paymentService.paymentParameter().pipe(
      take(1),
      switchMap(paymentParameter => {
        if (!paymentParameter) {
          // Check if parameter are available
          this.log.info('Payment parameter are not available');
          // Go to summary
          return this.navi.orderSummaryUrlTree;
        }
        return of(true);
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasPaymentParameter();
  }
}
