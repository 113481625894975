import { Guest } from '@interfaces/guest';
import { PartyUserBaseComponent } from '@components/party-user-base.component';
import { Observable } from 'rxjs';
import { PartyService } from '@services/party.service';
import { UserService } from '@services/user.service';
import { GuestsService } from '@services/guests.service';

export abstract class PartyUserGuestsBaseComponent extends PartyUserBaseComponent {
  public get guests$(): Observable<Guest[]> {
    return this.guestsService.get();
  }

  protected constructor(partyService: PartyService, userService: UserService, protected guestsService: GuestsService) {
    super(partyService, userService);
  }
}
