import { PartyUserBaseComponent } from '@components/party-user-base.component';
import { Observable, zip } from 'rxjs';
import { PartyService } from '@services/party.service';
import { UserService } from '@services/user.service';
import { OrderService } from '@services/order.service';
import { Order } from '@models/order';
import { Cart } from '@models/cart';
import { CartService } from '@services/cart.service';
import { map } from 'rxjs/operators';
import { ShipmentOptions } from '@enums/shipment-options.enum';
import { PaymentOptions } from '@enums/payment-options.enum';
import { ConfigService } from '@services/config.service';
import { FeeTypes } from '@enums/fee-types.enum';

export interface Totals {
  cart: number;
  fee: number;
  total: number;
  currency: string;
  fees: { [TKey in FeeTypes]: number };
}

export abstract class PartyUserOrderBaseComponent extends PartyUserBaseComponent {
  public get order$(): Observable<Order> {
    return this.orderService.get();
  }

  public get cart$(): Observable<Cart> {
    return this.cartService.get();
  }

  protected constructor(
    partyService: PartyService,
    userService: UserService,
    protected configService: ConfigService,
    protected orderService: OrderService,
    protected cartService: CartService,
  ) {
    super(partyService, userService);
  }

  /**
   * Get the totals based on order and local cart amount calculation
   */
  public get cartTotals$(): Observable<Totals> {
    return zip(this.cart$, this.order$).pipe(
      map(([cart, order]) => ({
        cart: cart.total,
        fee: order.feeAmount,
        total: cart.total + order.feeAmount,
        currency: order.currency,
        fees: order.fees
      }))
    );
  }

  /**
   * Get the totals based on order
   */
  public get totals$(): Observable<Totals> {
    return this.order$.pipe(
      map(order => ({
        cart: order.orderAmount,
        fee: order.feeAmount,
        total: order.totalAmount,
        currency: order.currency,
        fees: order.fees
      }))
    );
  }

  public get orderShipmentOptions$(): Observable<ShipmentOptions[]> {
    return this.configService.config(config => config.shipmentOptions);
  }

  public get orderPaymentOptions$(): Observable<PaymentOptions[]> {
    return this.configService.config(config => config.paymentOptions);
  }
}
