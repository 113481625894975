import { Injectable } from '@angular/core';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { ApiService } from './api.service';
import { Logger } from '@core/logger';
import { Wishlist } from '@models';
import {
  Wishlist as WishlistInterface,
  WishlistItem as WishlistItemInterface,
  WishlistService as WishlistServiceInterface
} from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class WishlistService implements WishlistServiceInterface {
  protected wishlist$: ReplaySubject<Wishlist>;

  protected nextWishlist = pipe(
    // Not set error of replay subject
    tap<Wishlist>(wishlist => this.wishlist$.next(wishlist))
  );

  /**
   * Pipe for creating model from interface and set next wishlist
   */
  protected setWishlist = pipe(
    map<WishlistInterface, Wishlist>(wishlistInterface => new Wishlist(wishlistInterface)),
    this.nextWishlist
  );

  constructor(protected api: ApiService, protected log: Logger) {}

  /**
   * Unset actual user to force new fetch
   */
  public unset() {
    if (this.wishlist$) {
      this.wishlist$.complete();
    }
    this.wishlist$ = null;
  }

  /**
   * Fetch the current user from API.
   */
  public fetch(): Observable<Wishlist> {
    if (!this.wishlist$) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.wishlist$ = new ReplaySubject<Wishlist>(1);
    }

    return this.api.get<WishlistInterface>('/wishlist').pipe(this.setWishlist);
  }

  /**
   * Get the current wishlist.
   *
   * @returns Gets the wishlist info.
   */
  public get(): Observable<Wishlist> {
    if (!this.wishlist$) {
      this.fetch()
        .pipe(take(1))
        .subscribe();
    }
    return this.wishlist$;
  }

  /**
   * Add item to wishlist.
   *
   * Uses setItem internally.
   *
   * A convenience method for usage outside of order module.
   *
   * @returns Gets the wishlist info.
   */
  public addItem(productId: string): Observable<Wishlist> {
    return this.api.post<WishlistItemInterface>('/wishlist/items', { productId }).pipe(
      switchMap(() => this.fetch()),
      take(1)
    );
  }

  /**
   * Remove item from wishlist
   *
   * @returns Gets the wishlist info.
   */
  public removeItem(id: string): Observable<Wishlist> {
    return this.api
      .delete<{}>(url => url.segment('/wishlist/items/:id').param({ id }))
      .pipe(
        switchMap(() => this.fetch()),
        take(1)
      );
  }

  /**
   * States if wishlist contains the item with the given product id
   *
   * @returns Boolean that states if cart contains item,
   */
  public hasItem(productId: string): Observable<boolean> {
    return this.get().pipe(map(wishlist => wishlist.hasItem(productId)));
  }

  /**
   * Get the number of items on the wishlist
   *
   * @returns The number of items on the wishlist.
   */
  public count(): Observable<number> {
    return this.get().pipe(map(wishlist => wishlist.count));
  }

  /**
   * States if the wishlist is empty
   *
   * @returns States if the wishlist is empty.
   */
  public isEmpty(): Observable<boolean> {
    return this.get().pipe(map(wishlist => wishlist.empty));
  }
}
