import { ProductSaleRule as ProductSaleRuleInterface } from '@interfaces/product';

export class ProductSaleRule {
  protected _minTurnover: number;
  protected _minAverageTurnover: number;
  protected _minTurnoverInclThis: number;
  protected _minGuestTurnover: number;
  protected _maxQuantity: number;
  protected _maxQuantityPerGuest: number;
  protected _requiredProductsQuantity: number;
  protected _minBookings: number;
  protected _maxQuantityPerBookingWithin: number;
  protected _maxQuantityPerBooking: number;
  protected _numberOfWeeksAfterSourceParty: number;
  protected _minStars: number;
  protected _consumeAllStars: boolean;
  protected _minGuests: number;
  protected _autoAdd: boolean;

  constructor(productSaleRuleData: ProductSaleRuleInterface) {
    this.init(productSaleRuleData);
  }

  init(productSaleRuleData: ProductSaleRuleInterface): this {
    this._minTurnover = productSaleRuleData.minTurnover;
    this._minAverageTurnover = productSaleRuleData.minAverageTurnover;
    this._minTurnoverInclThis = productSaleRuleData.minTurnoverInclThis;
    this._minGuestTurnover = productSaleRuleData.minGuestTurnover;
    this._maxQuantity = productSaleRuleData.maxQuantity;
    this._maxQuantityPerGuest = productSaleRuleData.maxQuantityPerGuest;
    this._requiredProductsQuantity = productSaleRuleData.requiredProductsQuantity;
    this._minBookings = productSaleRuleData.minBookings;
    this._maxQuantityPerBookingWithin = productSaleRuleData.maxQuantityPerBookingWithin;
    this._maxQuantityPerBooking = productSaleRuleData.maxQuantityPerBooking;
    this._numberOfWeeksAfterSourceParty = productSaleRuleData.numberOfWeeksAfterSourceParty;
    this._minStars = productSaleRuleData.minStars;
    this._consumeAllStars = productSaleRuleData.consumeAllStars;
    this._minGuests = productSaleRuleData.minGuests;
    this._autoAdd = productSaleRuleData.autoAdd;

    return this;
  }

  /*
  |--------------------------------
  | Core properties
  |--------------------------------
  */

  public get minTurnover(): number {
    return this._minTurnover;
  }

  public get minAverageTurnover(): number {
    return this._minAverageTurnover;
  }

  public get minTurnoverInclThis(): number {
    return this._minTurnoverInclThis;
  }

  public get minGuestTurnover(): number {
    return this._minGuestTurnover;
  }

  public get maxQuantity(): number {
    return this._maxQuantity;
  }

  public get maxQuantityPerGuest(): number {
    return this._maxQuantityPerGuest;
  }

  public get requiredProductsQuantity(): number {
    return this._requiredProductsQuantity;
  }

  public get minBookings(): number {
    return this._minBookings;
  }

  public get maxQuantityPerBookingWithin(): number {
    return this._maxQuantityPerBookingWithin;
  }

  public get maxQuantityPerBooking(): number {
    return this._maxQuantityPerBooking;
  }

  public get numberOfWeeksAfterSourceParty(): number {
    return this._numberOfWeeksAfterSourceParty;
  }

  public get minStars(): number {
    return this._minStars;
  }

  public get consumeAllStars(): boolean {
    return this._consumeAllStars;
  }

  public get minGuests(): number {
    return this._minGuests;
  }

  public get autoAdd(): boolean {
    return this._autoAdd;
  }
}
