import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbedVideoService } from 'ngx-embed-video';
import merge from 'lodash/merge';
import { EmbeddedVideo } from '@interfaces';
import { EmbeddedVideoTypes } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class VideoService extends EmbedVideoService {
  constructor(http: HttpClient, sanitizer: DomSanitizer) {
    super(http, sanitizer);
  }

  public embeddedVideoHtml(video: EmbeddedVideo, options: any = {}, idx: number = 0) {
    merge(options, {
      attr: { style: 'border: 0; height: 100%; left: 0; position: absolute; top: 0; width: 100%;' }
    });
    switch (video.source) {
      case EmbeddedVideoTypes.YouTube:
        merge(options, {
          query: {
            autohide: 1,
            autoplay: 0,
            wmode: 'opaque',
            enablejsapi: 1,
            controls: 2,
            color: 'fff',
            modestbranding: 1,
            showinfo: 0,
            fs: 1,
            rel: 0,
            playsinline: 0,
            widgetid: idx
          }
        });
        break;
      case EmbeddedVideoTypes.Vimeo:
        merge(options, {
          query: {
            color: 'fff'
          }
        });
        break;
    }
    return this.embed(video.url, options);
  }

  public extractEmbeddedVideos(text: string): EmbeddedVideo[] {
    const matches: EmbeddedVideo[] = [];

    this.uniquify(this.extractYoutubeUrls(text)).forEach(link => {
      link = this.sanitzeUrl(link);

      matches.push({
        source: EmbeddedVideoTypes.YouTube,
        url: link,
        embeddableUrl: this.embed_youtube(link),
        name: 'extracted-youtube-video'
      });
    });

    this.uniquify(this.extractVimeoUrls(text)).forEach(link => {
      link = this.sanitzeUrl(link);

      matches.push({
        source: EmbeddedVideoTypes.Vimeo,
        url: link,
        embeddableUrl: this.embed_vimeo(link),
        name: 'extracted-vimeo-video'
      });
    });

    return matches;
  }

  public extractYoutubeUrls(text: string): Array<string> {
    // NOTE: regex scratch: https://regex101.com/r/TdpQm6/2
    const regexYoutube = /((http|https):\/\/)?(www.)?((youtube.com|youtu\.be)[^\s|\<|\"]*)/g;

    return text.match(regexYoutube);
  }

  public extractVimeoUrls(text: string): Array<string> {
    // NOTE: regex scratch: https://regex101.com/r/C278XA/1
    const regexVimeo = /((http|https):\/\/)?(www.)?(vimeo.com[^\s|\<|\"]*)/g;

    return text.match(regexVimeo);
  }

  protected uniquify(list: string[] | null): string[] {
    if (!list) {
      return [];
    }
    // Reduce found matches to unnique entries
    return list.reduce((unique, item) => {
      return unique.includes(item) ? unique : [...unique, item];
    }, []); // <- initial value is an empty array
  }

  protected sanitzeUrl(url: string): string {
    return url.replace(/^([^(http|https)].*)/, 'http://$1');
  }
}
