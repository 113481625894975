import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Logger } from '@app/core/logger';
import { take, switchMap } from 'rxjs/operators';
import { NavigationService } from '@services/navigation.service';
import { CartService } from '@services/cart.service';

@Injectable({
  providedIn: 'root'
})
export class OrderShipmentGuard implements CanActivate {
  constructor(protected log: Logger, protected navi: NavigationService, protected cartService: CartService) {}

  protected hasCartItems(): Observable<boolean | UrlTree> {
    return this.cartService.isEmpty().pipe(
      take(1),
      switchMap(isEmpty => {
        if (isEmpty) {
          // Check if cart is empty
          this.log.info('Cart is empty');
          // Go to shipment
          return this.navi.orderCartUrlTree;
        }
        return of(true);
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasCartItems();
  }
}
