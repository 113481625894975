export function isAddressable(address: any): address is Addressable {
  return (
    (<Addressable>address).street !== undefined &&
    (<Addressable>address).streetNo !== undefined &&
    (<Addressable>address).zip !== undefined &&
    (<Addressable>address).city !== undefined &&
    (<Addressable>address).countryCode !== undefined
  );
}

export function isAddressableEmpty(address: Addressable): boolean {
  return !(address.street || address.streetNo || address.zip || address.city || address.countryCode);
}

export interface Addressable {
  street: string;
  streetNo: string;
  zip: string;
  city: string;
  countryCode: string;
}
