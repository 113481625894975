import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'idpo-confirm-box',
  templateUrl: './confirm-box.component.html',
  styleUrls: ['./confirm-box.component.scss']
})
export class ConfirmBoxComponent {
  @Input() public show = false;
  @Input() public header = '';
  @Input() public question = '';
  @Input() public confirmText = '';
  @Input() public declineText = '';

  @Output() public confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  public confirm(): void {
    this.confirmed.emit(true);
  }

  public decline(): void {
    this.confirmed.emit(false);
  }
}
