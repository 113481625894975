import { Injectable } from '@angular/core';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Logger } from '@core/logger';
import { ApiService } from './api.service';
import { OrderService } from './order.service';
import { Cart, CartItem } from '@models';
import { Cart as CartInterface, CartService as CartServiceInterface } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class CartService implements CartServiceInterface {
  protected cart$: ReplaySubject<Cart>;

  constructor(protected api: ApiService, protected log: Logger, protected order: OrderService) {}

  protected fetchOrder = pipe(switchMap(input => this.order.fetch().pipe(map(() => input))));

  /**
   * Pipe for creating model from interface and set next cart
   */
  protected setCart = pipe(
    // Fetch order on each possible cart change (needed for dynamic order fees)
    this.fetchOrder,
    // Set Cart Model
    map<CartInterface, Cart>(cartInterface => new Cart(cartInterface)),
    // Not set error to replay subject
    tap<Cart>(cart => this.cart$.next(cart))
  );

  /**
   * Pipe for creating model from interface and set next cart and return get
   */
  protected setCartAndGetActual = pipe(
    this.setCart,
    switchMap(() => this.get())
  );

  /**
   * Pipe for creating model from interface and set next cart and return get
   */
  protected setCartAndGetItem(productId: string) {
    return pipe(
      this.setCart,
      switchMap(() => this.getItem(productId))
    );
  }

  /**
   * Unset actual cart to force new fetch
   */
  public unset() {
    if (this.cart$) {
      this.cart$.complete();
    }
    this.cart$ = null;
  }

  protected get baseUrlSegment(): string {
    return '/cart';
  }

  /**
   * Fetch the current cart from API.
   */
  public fetch(): Observable<Cart> {
    if (!this.cart$) {
      // Use ReplaySubject(1) to simulate BehaviorSubject without initial value
      this.cart$ = new ReplaySubject<Cart>(1);
    }

    return this.api.get<CartInterface>(this.baseUrlSegment).pipe(this.setCart);
  }

  /**
   * Get the current cart.
   *
   * @returns Gets the cart info.
   */
  public get(): Observable<Cart> {
    if (!this.cart$) {
      this.fetch()
        .pipe(take(1))
        .subscribe();
    }
    return this.cart$;
  }

  /**
   * Set item of cart
   *
   * @returns Gets the cart info.
   */
  public setItem(productId: string, amount: number): Observable<CartItem> {
    const parameter = { amount };
    return this.api
      .put<CartInterface>(
        url =>
          url
            .segment(this.baseUrlSegment)
            .segment('/items/:productId')
            .param({ productId }),
        parameter
      )
      .pipe(
        this.setCartAndGetItem(productId),
        take(1)
      );
  }

  /**
   * Add item to cart.
   *
   * Uses setItem internally.
   *
   * A convenience method for usage outside of order module.
   *
   * @returns Gets the cart info.
   */
  public addItem(productId: string): Observable<CartItem> {
    return this.getItem(productId).pipe(
      take(1),
      map(item => (item ? item.amount : 0) + 1),
      switchMap(amount => this.setItem(productId, amount))
    );
  }

  /**
   * Remove item from cart
   *
   * @returns Gets the cart info.
   */
  public removeItem(productId: string): Observable<Cart> {
    return this.api
      .delete<CartInterface>(url =>
        url
          .segment(this.baseUrlSegment)
          .segment('/items/:productId')
          .param({ productId })
      )
      .pipe(this.setCart);
  }

  /**
   * States if cart contains the item with the given product id
   *
   * @returns Boolean that states if cart contains item,
   */
  public hasItem(productId: string): Observable<boolean> {
    return this.get().pipe(map(cart => cart.hasItem(productId)));
  }

  /**
   * Get the current cart items.
   *
   * @returns Gets the cart items.
   */
  public getItems(): Observable<CartItem[]> {
    return this.get().pipe(map(cart => cart.items));
  }

  /**
   * Get the cart item with the given product id if available, otherwise null.
   *
   * @returns The cart item or null.
   */
  public getItem(productId: string): Observable<CartItem> {
    return this.get().pipe(map(cart => cart.getItem(productId)));
  }

  /**
   * Get the carts total without fees.
   *
   * @returns The cart's total.
   */
  public total(): Observable<number> {
    return this.get().pipe(map(cart => cart.total));
  }

  /**
   * Get the carts total without fees.
   *
   * @returns The cart's total stars.
   */
  public totalStars(): Observable<number> {
    return this.get().pipe(map(cart => cart.totalStars));
  }

  /**
   * Get the number of items in the cart
   *
   * @returns The number of items in the cart.
   */
  public count(): Observable<number> {
    return this.get().pipe(map(cart => cart.count));
  }

  /**
   * States if the cart is empty
   *
   * @returns States if the cart is empty.
   */
  public isEmpty(): Observable<boolean> {
    return this.get().pipe(map(cart => cart.empty));
  }
}
