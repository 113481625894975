export enum OrderSteps {
  Cart = 'cart',
  Offer = 'offer',
  Login = 'login',
  Shipment = 'shipment',
  Payment = 'payment',
  Summary = 'summary',
  PaymentHandling = 'paymentHandling',
  Completed = 'completed'
}
