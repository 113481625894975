import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[idpoIcon]'
})
export class IconDirective implements AfterViewInit, OnChanges {
  @Input() public idpoIcon: string;
  protected isViewInitialized: boolean;

  constructor(protected renderer: Renderer2, protected element: ElementRef) {}

  public ngAfterViewInit(): void {
    this.isViewInitialized = true;
    this.setIcon();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('idpoIcon')) {
      this.setIcon();
    }
  }

  private setIcon(): void {
    if (this.isViewInitialized) {
      const container = this.element.nativeElement;
      const className = 'idpo-icon';

      // Remove all idpo child's
      let child = container.lastElementChild;
      while (child) {
        if (child.classList.contains(className)) container.removeChild(child);
        child = container.lastElementChild;
      }

      // Create new child
      const span = this.renderer.createElement('span');
      span.setAttribute('class', className);

      let svg = null;
      switch (this.idpoIcon) {
        case 'gift':
          svg = this.getGiftSVG();
          break;
      }

      if (svg) {
        span.innerHTML = svg;
        this.renderer.appendChild(container, span);
      }
    }
  }

  private getGiftSVG(): string {
    return `
    <svg class="idpo-gift-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xml:space="preserve">
      <g>
        <path d="M15.3,3.8H12c0.1-0.3,0.2-0.7,0.2-1c0-1.4-1.1-2.5-2.5-2.5C9.2,0.3,8.6,0.6,8.1,1c-0.5-0.6-1.2-1-2-1 C4.7,0,3.5,1.2,3.5,2.6c0,0.4,0.1,0.8,0.3,1.2h-3C0.3,3.8,0,4.2,0,4.5v3.1C0,8,0.3,8.4,0.7,8.4H1v6.9C1,15.7,1.4,16,1.8,16h12.5 c0.4,0,0.7-0.3,0.7-0.7V8.4h0.3C15.7,8.4,16,8,16,7.7V4.5C16,4.2,15.7,3.8,15.3,3.8z M6,1.2c0.8,0,1.4,0.6,1.4,1.4v1.4H6	c-0.8,0-1.4-0.6-1.4-1.4S5.3,1.2,6,1.2z M7.4,8.4v6.6H2.3V8.4H7.4z M7.4,5v2.2H1.2V5H7.4z M8.6,15V8.4h5.2V15H8.6z M14.9,5v2.3H8.6 V5H14.9z M11,2.7c0,0.7-0.5,1.2-1.2,1.2H8.6V2.7c0-0.7,0.5-1.2,1.2-1.2S11,2.1,11,2.7z"/>
      </g>
    </svg>
    `;
  }
}
