import { Inject, Injectable } from '@angular/core';
import { Logger } from '@core/logger';
import { DOCUMENT } from '@angular/common';
import { Observable, ReplaySubject } from 'rxjs';
import { Md5 } from 'ts-md5';

export interface ScriptLoaded {
  id: string;
  src: string;
}
@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  protected loaded = new Map<string, ReplaySubject<ScriptLoaded>>();

  constructor(protected log: Logger, @Inject(DOCUMENT) protected document: Document) {}

  public load(src: string, force = false, options = {}): Observable<ScriptLoaded> {
    const id = <string>Md5.hashStr(src).slice(0, 8);
    if (!force && this.loaded.has(id)) {
      return this.loaded.get(id);
    }

    const loaded = new ReplaySubject<ScriptLoaded>(1);
    const script = this.createScriptTag(src, options);

    script.onload = () => {
      this.loaded.set(id, loaded);
      loaded.next({ id: id, src: src });
    };
    script.onerror = (error: any) => loaded.error(error);

    document.getElementsByTagName('head')[0].prepend(script);
    return loaded;
  }

  public loadIntoElement(src: string, containerClass: string, force = false, options = {}): Observable<ScriptLoaded> {
    const id = <string>Md5.hashStr(src).slice(0, 8);
    if (!force && this.loaded.has(id)) {
      return this.loaded.get(id);
    }

    const loaded = new ReplaySubject<ScriptLoaded>(1);
    const script = this.createScriptTag(src, options);

    script.onload = () => {
      this.loaded.set(id, loaded);
      loaded.next({ id: id, src: src });
    };
    script.onerror = (error: any) => loaded.error(error);

    document.querySelector(`.${containerClass}`).appendChild(script);
    return loaded;
  }

  protected createScriptTag(src: string, options = {}) {
    const script = document.createElement('script');
    script.async = true;
    script.src = src;

    if (Object.keys(options).length) {
      for (const [attribute, value] of Object.entries(options)) {
        script.setAttribute(attribute, `${value}`);
      }
    }

    return script;
  }
}
