import { Pipe, PipeTransform } from '@angular/core';
import { Addressable, isAddressable } from '@interfaces';

@Pipe({
  name: 'addressLines'
})
export class AddressLinesPipe implements PipeTransform {
  transform(value: Addressable, args?: string[]): string[] {
    if (!isAddressable(value) || !args) {
      return [];
    }

    const replacements = {
      '{{street}}': 'street',
      '{{streetNo}}': 'streetNo',
      '{{zip}}': 'zip',
      '{{city}}': 'city',
      '{{countryCode}}': 'countryCode'
    };
    const regExp = new RegExp(Object.keys(replacements).join('|'), 'gi');

    return args.map(formatLine => {
      return formatLine.replace(regExp, matched => value[replacements[matched]] || '');
    });
  }
}
