import { Component, OnInit } from '@angular/core';
import { PartyIDService } from '@services/party-id.service';
import { NavigationService } from '@services/navigation.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'idpo-party-not-found',
  templateUrl: './party-not-found.component.html',
  styleUrls: ['./party-not-found.component.scss']
})
export class PartyNotFoundComponent implements OnInit {
  constructor(protected navi: NavigationService, public partyIDService: PartyIDService) {}

  public partyId: string;

  public ngOnInit(): void {
    this.partyIDService
      .get()
      .pipe(take(1))
      .subscribe(partyId => (this.partyId = partyId));
  }

  public goBack(): void {
    this.navi.back();
  }
}
