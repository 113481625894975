import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
  PRIMARY_OUTLET
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { NavigationService } from '@services';
import { Logger } from '@core/logger';

@Injectable({
  providedIn: 'root'
})
export class DoNotShowPopupOnReloadGuard implements CanActivate {
  constructor(protected router: Router, protected navi: NavigationService, protected log: Logger) {}

  private isPageRefresh(): boolean {
    return !this.router.navigated;
  }

  private getUrlWithoutPopup(routerStateSnapshot: RouterStateSnapshot): UrlTree {
    const urlTree = this.router.parseUrl(routerStateSnapshot.url);
    let segment = urlTree.root;

    while (segment && segment.children) {
      delete segment.children.popup;
      segment = segment.children[PRIMARY_OUTLET];
    }

    return urlTree;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isPageRefresh()) {
      this.log.debug('DNSPORGuard: No Popup cause of page refresh');
      this.router.navigateByUrl(this.getUrlWithoutPopup(state));
      return of(false);
    }

    return of(true);
  }
}
