import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Logger } from '@core/logger';
import { GuestsService } from '@services/guests.service';
import { Guest } from '@interfaces/guest';

@Injectable({
  providedIn: 'root'
})
export class GuestsResolverService implements Resolve<Guest[]> {
  constructor(protected guests: GuestsService, protected log: Logger) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Guest[]> {
    return this.guests.get().pipe(take(1));
  }
}
